import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, Container, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Util } from '../../state/utility';


export default function CookieConsent() {

    const [accepted, setAccepted] = React.useState(Util.getCookieConsent());


    const accept = React.useCallback(() => {
        Util.setCookeConsent(true);
        setAccepted(true);
    }, [setAccepted]);

    if (accepted) {
        return null;
    }

    return <Box sx={{

        position: 'fixed',
        bottom: 0,
        background: 'linear-gradient(rgb(82, 79, 79), rgb(74, 42, 66))',
        color: 'white',
        width: '100%'
    }}>
        <Container maxWidth="md" sx={{
            display: 'flex',
        }}>
            <Box sx={{ flexGrow: 1, m: 1 }}>
                Ologz uses cookies to enable the Ologz service and to improve your experience with us.
                To learn more, check out our&nbsp;<Link sx={{ color: '#a9c77b' }} component={RouterLink} to="/cookies-policy">cookie policy</Link>.
                By clicking OK or continuing to use our site, you agree that we can place these cookies.
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button variant='contained' sx={{ color: 'white' }} onClick={accept}>Ok</Button>
            </Box>
        </Container>

    </Box>

}