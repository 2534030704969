import React, { useEffect, useState } from 'react';
// import logo from './logo.svg';
import './App.css';
import { Routes, Route, BrowserRouter, useLocation, Location } from "react-router-dom";

import Layout from './app/shared/layout/Layout';
import { createTheme, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux'
import { store } from './app/state/store'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useInitUser } from './app/state/hooks';
import { ModalProvider } from './app/context/modal-context';

// Routes
import Home from './app/routes/home/Home';
import About from './app/routes/about/About';
import MyTopics from './app/routes/my-topics/MyTopics';
import SearchView from './app/routes/search/Search';
import TopicView from './app/routes/topic-view/TopicView';
import LoginPage from './app/routes/login/Login';
import Profile from './app/routes/profile/Profile';
import CookiePolicy from './app/routes/legal/CookiePolicy';
import TermsOfUse from './app/routes/legal/TermsOfUse';
import PrivacyNotice from './app/routes/legal/PrivacyNotice';
import Billing from './app/routes/billing/Billing';
import { theme } from './theme';


function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <ModalProvider>
            <InnerApp />
          </ModalProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}

function InnerApp() {

  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);

  const [transitionStage, setTransistionStage] = useState("fadeIn");
  useInitUser();

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [location, displayLocation]);



  const createTransition = (component: React.ReactNode) => {
    return <ComponentWrapper
      transitionStage={transitionStage}
      location={location}
      setDisplayLocation={setDisplayLocation}
      setTransistionStage={setTransistionStage}>
      {component}
    </ComponentWrapper>
  }
  return <>

    <Routes location={displayLocation}>
      <Route path="" element={<Layout />}>
        <Route path="" element={createTransition(<Home />)}>
        </Route>
        <Route path="my" element={createTransition(<MyTopics />)} />
        <Route path="search" element={createTransition(<SearchView />)} />
        <Route path="about" element={createTransition(<About />)} />
        <Route path="profile" element={createTransition(<Profile />)} />
        <Route path="billing" element={createTransition(<Billing />)} />
        <Route path="cookies-policy" element={createTransition(<CookiePolicy />)} />
        <Route path="privacy-notice" element={createTransition(<PrivacyNotice />)} />
        <Route path="terms-of-use" element={createTransition(<TermsOfUse />)} />
        <Route path="topic/:topicId" element={createTransition(<TopicView />)} >
          <Route path=":itemId" element={createTransition(<TopicView />)} />
        </Route>
        <Route path="login" element={createTransition(<LoginPage />)} />
        <Route path="*" element={<>Noooo Routeeeee!</>} />
      </Route>
    </Routes>
    <ToastContainer
      position="bottom-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      theme={'colored'}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </>
}


interface ComponentWrapperMyRouteProps {
  location: Location;
  transitionStage: string;
  setTransistionStage: (x: string) => void;
  setDisplayLocation: (x: Location) => void;
  children: React.ReactNode;
}
const ComponentWrapper: React.FC<ComponentWrapperMyRouteProps> = (props) => {
  const { setTransistionStage, setDisplayLocation, transitionStage, location, children } = props;
  return <div
    className={`${transitionStage}`}
    onAnimationEnd={() => {
      if (transitionStage === "fadeOut") {
        setTransistionStage("fadeIn");
        setDisplayLocation(location);
      }
    }}

  >
    {children}
  </div>
}

export default App;
