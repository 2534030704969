import React from 'react';
import { Box } from "@mui/material"
import { TopicCategory } from '../../state/services/type';
import { svgFn, svgDerivative, svgVector, svgOn } from './svgs';

export const CardSvg = ({ my, category }: { my?: boolean, category: TopicCategory }) => {

    const background = my ? 'linear-gradient(#833e3e, #3a1919)' : 'linear-gradient(rgb(10, 110, 122), rgb(42, 44, 74))'

    let svgSrc = svgFn;
    if (category === TopicCategory.Calculus) {
        svgSrc = svgDerivative;
    } else if (category === TopicCategory.LinearAlgebra) {
        svgSrc = svgVector;
    } else if (category === TopicCategory.ComputerScience) {
        svgSrc = svgOn;
    }
    return <Box sx={{
        background,
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        height: { xs: '250px;', md: '280px' }
    }}>

        <div style={{ padding: getPadding(category) }} dangerouslySetInnerHTML={{ __html: svgSrc }}>
        </div>
    </Box>
}

/**
 * Padding depeds on img aspect rate!!!
 * @param category 
 * @returns 
 */
function getPadding(category: TopicCategory) {
    if (category === TopicCategory.ComputerScience) {
        return '25%'; // if width >> height!!
    }
    return '25%'
}

