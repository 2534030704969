import React from 'react';
import { Box, Button, Container } from '@mui/material';
import { useState } from 'react';
import { Footer } from '../../shared/footer/Footer';
import { MyText } from './MyText';
export default function CookiePolicy() {

  const [moreBrowserCookies, setMBC] = useState(false);
  return (
    <Container sx={{}}>
      <Box
        sx={{
          // bgcolor: 'background.paper',
          pt: 8,
          pb: 6,
        }}
      >
        <MyText t="t2">
          Cookie Policy
        </MyText>

        <MyText>
          Updated May 23, 2022
        </MyText>

        <MyText t="t3">
          What Are Cookies?
        </MyText>

        <MyText>
          Cookies are small data files that a website you visit may save on your hard drive. They usually include an
          anonymous unique identifier. A cookie cannot retrieve any other data from your hard drive or pass on
          computer viruses.
        </MyText>
        <MyText t="t3">
          What Cookies and Similar Technologies Does Ologz Use?
        </MyText>
        <MyText >
          Ologz uses cookies and similar technologies to assist with functions such as user authentication,
          remembering user preferences, understanding how users use the Ologz Service, and managing and tracking
          the effectiveness of our marketing efforts.
        </MyText>
        <MyText>
          We describe in this document the cookies and similar technologies found on the Ologz Service, which may
          change over time. Please check back here periodically, as we may update this information from time to time.
        </MyText>
        <MyText t="t3">Functional Cookies</MyText>
        <MyText >
          Functional cookies are necessary to enable portions of the Ologz Service and its features to work as
          intended. For example, we use cookies to help recognize and remember you when you are logged into the
          Ologz Service so we can maintain your settings and preferences, such as your language and region or your
          logged-in state. These cookies do not track your browsing activity on non-Ologz
          websites.
        </MyText>
        <MyText>
          You may disable any of these functional cookies as described below.
        </MyText>

        <MyText t="t3">
          Analytics
        </MyText>

        <MyText>
          We use cookies and similar tracking technologies to help us understand how you use our website, our mobile
          applications. By providing us with information about how you interact with the Ologz Service,
          these tools help us learn how to make the Ologz experience even better and customize our communications
          with you. On our website, for example, these technologies can tell us things like how you arrived at the
          site, if you have visited the site before, how long you stay on the site, and which pages you visit. They
          also provide us with general information about where in the world you may be located.The following analytics
          technologies are in use:
        </MyText>
        <ul>
          <li><span>Google Analytics.</span> <br />
            To learn more about Google Analytics and your privacy, visit the “How
            Google uses data when you use our partners' sites or apps” page at <a
              href="http://www.google.com/policies/privacy/partners/">www.google.com/policies/privacy/partners/.</a>
            To opt out of being tracked by Google Analytics when using our website, visit <a
              href="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout.</a></li>
        </ul>

        <MyText t="t3">
          Social Media Features
        </MyText>

        <MyText>
          Our website uses social media features (“Social Features”) provided by companies and services such as
          Facebook, Twitter, Google+, YouTube, LinkedIn and others (depending on what country you’re in) to enable you
          to share information with these channels. The Social Features are identified on our website by our display
          of the companies’ logos. These cookies are owned and controlled by third parties, and therefore the third
          parties – and not Ologz – are responsible for their use. As such, we can't guarantee that we have listed
          every cookie you might get from a third party since a third party could change these cookies at any time as
          their needs change.
        </MyText>


        <MyText>
          When you visit a page on the Ologz website that contains Social Features, your browser establishes a
          direct connection to the applicable social network’s servers. The Social Features inform the social networks
          what pages you have accessed on Ologz’s site, and, if you are logged into the social network, your visit
          may be logged to your account on that service. For example, if you click the Facebook “Like” button, that
          information is transmitted from your browser directly to Facebook and stored by them. Even if you are not
          logged into the social network, and do not click on a Social Feature on the Ologz site, there is still a
          possibility that a Social Feature can transmit your IP address and cookie information to the operator of the
          social network. We will not transmit information regarding your account contents to such social networks.
        </MyText>

        <MyText>
          For information on the purpose and scope of data processing by a social network, as well as your options for
          protecting your privacy, please review the appropriate social network's privacy policy. For your
          convenience, below are links to the most commonly used Social Features you’ll see on the Ologz site.
        </MyText>

        <ul>
          <li>Facebook: <a href="https://www.facebook.com/about/privacy">https://www.facebook.com/about/privacy</a>
          </li>
          <li>Google+ and YouTube: <a
            href="http://www.google.com/policies/privacy">http://www.google.com/policies/privacy</a></li>
          {/* <li>Twitter: <a href="http://twitter.com/privacy">http://twitter.com/privacy</a></li>
          <li>LinkedIn: <a
            href="http://www.linkedin.com/static?key=privacy_policy">http://www.linkedin.com/static?key=privacy_policy</a>
          </li> */}
        </ul>

        {/* <MyText>
          An additional Social Feature on our Russian-language site is <a href="http://www.vk.com">VK</a>, and an
          additional Social Feature on our Japanese-language site is <a href="https://www.hatena.com">Hatena</a>.
        </MyText> */}


        <MyText t="t3">
          How Do I Turn Off Cookies or Similar Technologies?
        </MyText>

        <MyText>
          You can configure your browser to accept cookies, reject cookies, or notify you when a cookie is being
          used.
        </MyText>


        <Button onClick={() => setMBC((x) => !x)}>{!moreBrowserCookies ? 'Learn More' : 'Show Less'}</Button>
        <MyText>
          <span className="section-toggle"><span className="learn-more"></span><span className="show-less"></span></span>
        </MyText>

        {moreBrowserCookies && <BrowserCookiesLearn />}

        <MyText>
          However, if you configure your browser to reject cookies, you may not be able to use Ologz products or
          services that require you to sign in or take advantage of all the features of the Ologz Service. In
          addition, information at the links we’ve provided in this document can help you disable specific cookies
          related to website performance and marketing.
        </MyText>

        {/* <MyText>
          You can configure your mobile operating system to limit or prevent the use or tracking of mobile identifiers
          such as IDFA and AID by toggling the relevant settings on your mobile device’s settings menu. Mobile
          identifiers are commonly used to recognize mobile devices in order to enhance user experiences, so disabling
          mobile identifier tracking may cause unintended errors during your use of Ologz mobile applications.
        </MyText> */}
      </Box>
      <Footer />
    </Container >
  );
}

function BrowserCookiesLearn() {
  return <>
    <MyText >
      You can find up to date information about blocking and deleting cookies on your browser at the following
      links:
    </MyText>
    <ul>
      <li dir="ltr">Chrome: <a
        href="https://support.google.com/chrome/answer/95647" target="_blank" rel="noreferrer">https://support.google.com/chrome/answer/95647</a>
      </li>
      <li dir="ltr">Firefox: <a
        href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop" target="_blank" rel="noreferrer">https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop</a>
      </li>
      <li dir="ltr">Opera: <a
        href="https://help.opera.com/en/latest/security-and-privacy/" target="_blank" rel="noreferrer">https://help.opera.com/en/latest/security-and-privacy/</a>
      </li>
      <li dir="ltr">Internet Explorer: <a
        href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noreferrer">https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies</a>
      </li>
      <li dir="ltr">Safari: <a
        href="https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target="_blank" rel="noreferrer">https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a>
      </li>
      <li dir="ltr">Edge: <a
        href="https://support.microsoft.com/en-gb/help/4468242/microsoft-edge-browsing-data-and-privacy" target="_blank" rel="noreferrer">https://support.microsoft.com/en-gb/help/4468242/microsoft-edge-browsing-data-and-privacy</a>
      </li>
    </ul>
  </>
}
