import { TextField } from "@mui/material";
import React, { useEffect } from "react";
import { debounceWithFn } from "../../../helpers";



interface MyltyInputProps {
    name: string,
    label: string[];
    value: number[];
    onChange: (x: number[], name: string) => void;
    validator?: (x: any) => boolean;
}
var isNumber = function isNumber(value: any) {
    return typeof value === 'number' && isFinite(value);

}
const isValidNumber = (x: number[]) => !x.map(isNumber).includes(false);

export const MultyInput = (props: MyltyInputProps) => {

    const { value, label, name, onChange, validator } = props;
    const [innerValue, setInnerValue] = React.useState<number[]>(value);


    // eslint-disable-next-line
    const debouncedCustom = React.useCallback(debounceWithFn(200), []);

    const isValid = validator || isValidNumber;

    const onValueChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {

        const val = Number.parseFloat(event.target.value);
        //
        let newstate = innerValue.map(x => x);

        newstate[Number.parseFloat(event.target.name)] = val;

        setInnerValue(newstate);

        if (isValid(newstate)) {
            debouncedCustom((v, n) => onChange(v, n), [newstate, name]);
        }
    }, [innerValue, name, setInnerValue, isValid, debouncedCustom, onChange]);

    useEffect(() => {
        setInnerValue(value);
    }, [value, setInnerValue])



    const valid = !isValid(innerValue);
    return <>
        {label.map((label, i) => (
            <TextField
                key={`${i}`}
                name={`${i}`}
                sx={{ m: '5px', width: '80px' }}
                type="number"
                InputProps={{
                    inputProps: {
                        max: 1000, min: -1000
                    }
                }}
                label={label} value={isNumber(innerValue[i]) ? innerValue[i] : ''} onChange={onValueChange} variant="outlined" error={valid} />
        ))}

    </>
}