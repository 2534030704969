
export const ELEMENT_TAGS: { [key: string]: any } = {
    A: (el?: HTMLElement) => ({ type: 'link', url: el && el.getAttribute('href') }), // SKIP errors with applying <i><a>...</a></i> not text node!! FIX THIS!
    BLOCKQUOTE: () => ({ type: 'block-quote' }),
    H1: () => ({ type: 'heading-one' }),
    H2: () => ({ type: 'heading-two' }),
    H3: () => ({ type: 'heading-three' }),
    H4: () => ({ type: 'heading-four' }),
    H5: () => ({ type: 'heading-five' }),
    H6: () => ({ type: 'heading-six' }),
    IMG: (el?: HTMLElement) => ({ type: 'image', url: el && el.getAttribute('src') }),
    MATHB: (el?: HTMLElement) => ({ type: 'mathblock', tex: el && el.textContent }),
    MI: (el?: HTMLElement) => ({ type: 'mathinline', tex: el && el.textContent }),
    FCHART: (el: HTMLElement) => ({ type: 'chart', data: el && el.textContent }),
    LI: () => ({ type: 'list-item' }),
    OL: () => ({ type: 'numbered-list' }),
    P: () => ({ type: 'paragraph' }),
    PRE: () => ({ type: 'pre' }),
    UL: () => ({ type: 'bulleted-list' }),
    // need for copy paste , exclude form stringify!!
    math: (el: HTMLElement) => ({ type: 'mathinline', tex: el && (el.querySelector('annotation')?.textContent || 'Null') })
}


// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
export const TEXT_TAGS: { [key: string]: any } = {
    CODE: () => ({ code: true }),
    DEL: () => ({ strikethrough: true }),
    EM: () => ({ italic: true }),
    I: () => ({ italic: true }),
    S: () => ({ strikethrough: true }),
    STRONG: () => ({ bold: true }),
    U: () => ({ underline: true })
}
