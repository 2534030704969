import * as React from 'react';
import { useGetTopicQuery } from '../../../state/services/mathapi';
import { Alert } from '@mui/material';
import { Loading } from '../../loading/Loading';
import { ContentList } from '../../content-list/ContentList';


interface TableOfContentsProps {
    topicId: string;
}

export function TableOfContents(props: TableOfContentsProps) {
    let { topicId } = props;
    const { data, error, isLoading } = useGetTopicQuery(topicId, { skip: !topicId });

    if (isLoading) {
        return <Loading />;
    }
    else if (error) {
        return <Alert severity="error" elevation={6} variant="filled" >{(error as any).data || 'Server Error!'}</Alert>;
    }
    else if (!data) {
        return <Alert severity="warning" elevation={6} variant="filled" >No Data</Alert>;
    }
    else {
        return (
            <ContentList topic={data}></ContentList>
        );
    }
}
