import * as React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMyNavigation } from './hooks';

export default React.memo(function NavBtns() {
    const nav = useNavigate();

    const navItems = useMyNavigation();
    const handleHomeNav = React.useCallback((route: string) => {
        nav(route);
    }, [nav]);

    return (
        <nav>
            {navItems.map(x => (<Button key={x.key} color='inherit' sx={{ fontWeight: 'bold' }} onClick={() => { handleHomeNav(x.route) }} >{x.name}</Button>))}
        </nav>
    );
})
