import React from "react";
import { Button, Dialog, DialogContent, DialogTitle, DialogActions } from "@mui/material";
import { Transition } from "../topic-item/EditDialogML";
import { CustomElement, DialogDoneData, InputDialogData } from "./slate.inteface";
import MathElementEdit from "./math/MathElementEdit";
import ChartElementEdit from "./chart/ChartElementEdit";


interface EditialogProps {
    inputData: InputDialogData | null;
    done: (data: DialogDoneData) => void;
}

export default function EditDialog(props: EditialogProps) {


    const { inputData, done } = props;
    const [editElement, setEditElement] = React.useState<CustomElement | null>(null);
    // tmp edited element!
    const [innerElement, setInnerEditElement] = React.useState<CustomElement | null>(null);

    const handleClose = React.useCallback(() => {
        if (inputData) {
            done({
                inputData,
                op: 'cancel'
            });
        }
    }, [done, inputData]);

    const handleDone = React.useCallback(() => {
        if (inputData && editElement && innerElement) {
            done({
                data: innerElement,
                inputData,
                op: inputData.op
            });
        }
    }, [done, editElement, innerElement, inputData]);

    React.useEffect(() => {
        setEditElement(inputData?.element || null);
        setInnerEditElement(inputData?.element || null);
    }, [inputData, setEditElement, setInnerEditElement]);

    let isValid = true;
    if (innerElement?.type === 'mathblock' ||
        innerElement?.type === 'mathinline') {
        isValid = !!innerElement.tex;
    }
    return (
        <Dialog
            open={!!inputData}
            onClose={handleClose}
            TransitionComponent={Transition}
            fullWidth={inputData?.element?.type === 'chart'}
            maxWidth={inputData?.element?.type === 'chart' ? false : 'xl'}
        >
            <DialogTitle>
                {"Edit Content"}
            </DialogTitle>
            <DialogContent>
                <MathElementEdit action={inputData?.op || 'insert'} editElement={editElement} onChange={setInnerEditElement}></MathElementEdit>
                <ChartElementEdit action={inputData?.op || 'insert'} editElement={editElement} onChange={setInnerEditElement}></ChartElementEdit>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="error" >Discharge changes</Button>
                <Button onClick={handleDone} color="success" disabled={!isValid} autoFocus>
                    Confirm changes
                </Button>
            </DialogActions>
        </Dialog >
    );
}
