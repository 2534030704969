
export interface MyResponse<T> {
    msg: string;
    data: T
}

export interface PaginationData<T> {
    items: T;
    count: number;
    take: number;
    skip: number;
}

export interface TopicsReqParams extends PaginationReq {
    my: boolean
}
export interface PaginationReq {
    take?: number;
    skip?: number;
}
export interface LoginData {
    token: string;
    user: User;
}

export interface LoginRequest {
    email: string;
    password: string;
}

export interface LoginGoogleRequest {
    credential: string;
}

export interface OrderData {
    id: string;
    items: Pick<ContentItem, 'id' | 'parent_id' | 'order'>[];
}

export interface CopyPasteReq {
    src_topic_id: String;
    src_item_id?: string;
    dest_topic_id: string;
    dest_item_id?: string;
}
export interface CopyPasteResp {
    topic_id: string;
}

export interface SearchResult {
    topic_id: string;
    topic_title: string;
    item_title?: string;
    item_id?: string;
    category: TopicCategory;
}

export enum TopicCategory {
    Foundations = 1,
    Analysis = 2,
    Algebra = 3,
    NumberTheory = 4,
    Combinatorics = 5,
    Calculus = 6,
    ProbabilityAndStatistics = 7,
    Geometry = 8,
    Topology = 9,
    LinearAlgebra = 10,
    Other = 11,

    //
    ComputerScience = 41,
}


export interface Topic {
    id: string;
    user_id: string;
    title: string;
    sub_title: string;
    description: string;
    content: string;
    svg: string;
    svg_logo: string;
    children: ContentItem[]
    items: ContentItem[];
    public: boolean;
    category: TopicCategory
}
export interface ContentItem {
    id: string;
    parent_id?: string;
    topic_id: string;
    order: number;
    title: string;
    content: string;
    svg: string;
    children: ContentItem[];
}


export interface User {
    id: string;
    name: string;
    email: string;
    picture?: string;
    role: Role,

    tier_name: string,
    tier_topics: number,
    tier_chapters: number,
    tier_chapter_size: number,
    tier_apply: string,
}

export type Role = 'ADMINISTRATOR' | 'STANDARD';

export function getTopicCategoryName(c: TopicCategory) {
    switch (c) {
        case TopicCategory.NumberTheory:
            return 'Number Theory';
        case TopicCategory.ProbabilityAndStatistics:
            return 'Probability And Statistics';
        case TopicCategory.LinearAlgebra:
            return 'Linear Algebra';
        case TopicCategory.ComputerScience:
            return 'Computer Science';
        default:
            return TopicCategory[c];
    }
}