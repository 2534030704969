import { Box, Container, Link, Table, TableBody, TableHead } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Footer } from '../../shared/footer/Footer';
import { MyText, MyUl } from './MyText';
export default function PrivacyNotice() {

  return (
    <Container sx={{}}>
      <Box
        sx={{
          pt: 8,
          pb: 6,
        }}
      >
        <MyText t="t1">
          OLOGZ PRIVACY NOTICE
        </MyText>

        <MyText t="t2">
          INTRODUCTION
        </MyText>
        <MyText >
          Welcome to Ologz's privacy notice. This privacy notice was last updated on May 23, 2022.
        </MyText>

        {/* <MyText>Capitalised terms used in this privacy notice are defined in section 12 of this privacy notice below.</MyText> */}

        <MyText t="t2">
          1. IMPORTANT INFORMATION AND WHO WE ARE
        </MyText>
        <MyText >
          We are Ologs d.o.o. Our company number is 08782152342 and our registered address is Sokolgradska 76, 10000
          Zagreb, Croatia, EU.
        </MyText>

        <MyText>
          When we mention "Ologz", "Ologz Service", "we", "us" or "our", we are referring to Ologs d.o.o. responsible for
          processing your data.
        </MyText>
        <MyText>
          Ologz respects your privacy and is committed to protecting your information. This privacy notice will
          inform you as to how we look after your information when you (a) sign-up and create a profile on our
          website or information about our products and services or (b) visit and/or use our platforms (desktop websites
          (including www.ologz.com), mobile websites and native mobile applications (collectively, our “Platforms”)). These are collectively
          referred to as our “Services” in this privacy notice.
        </MyText>
        <MyText>
          This privacy notice applies to your use of our Platforms and Services (regardless of where you visit from
          or otherwise access our Services) and tells you about your privacy rights and how Data Protection Laws
          protect you.
        </MyText>

        {/* <MyText>
          Ologz is the Data Controller of your Personal Data.
        </MyText> */}
        <MyText t="t3">
          PURPOSE OF THIS PRIVACY NOTICE
        </MyText>
        <MyText >
          This privacy notice aims to give you information on how Ologz collects, uses, discloses, transfers,
          stores and processes your information when you use our Services, including any data you may provide
          through your use of our Services.
        </MyText>

        <MyText>
          It is important that you read this privacy notice together with any other privacy notice or fair
          processing notice we may provide on specific occasions when we are collecting or processing information
          about you so that you are fully aware of how and why we are using your data. This privacy notice
          supplements the other policies and is not intended to override them.
        </MyText>

        <MyText t="t3">
          CONTACT DETAILS
        </MyText>
        <MyText >
          Our address is Ologs d.o.o., Sokolgradska 76, 10000 Zagreb, Croatia, EU. If you have any questions about this privacy notice please contact us by mail at: <a href="mailto:ologs.group@gmail.com">ologs.group@gmail.com</a>
        </MyText>

        {/* <MyText t="t3">
          THIRD-PARTY LINKS
        </MyText>
        <MyText >
          Our Platforms may include links to third-party websites, plug-ins and applications. Clicking on those
          links or enabling those connections may allow third parties to collect or share data about you. We do
          not control these third-party websites and are not responsible for their privacy statements. When you
          leave our Platforms, we encourage you to read the privacy notice of every website you visit or
          third-party mobile application you may use.
        </MyText> */}

        <MyText t="t2">
          2. WHAT INFORMATION DOES OLOGZ COLLECT?
        </MyText>

        <MyText>
          Ologz allows you to input, upload, or store in the Ologz Service any text, images,
          and other data that you choose—we refer to that information as “Content”. When you use
          Ologz Service, we may collect Personal Data about you. We also collect
          and receive the following types of information:
        </MyText>
        <MyUl>
          <li><strong>Basic subscriber information</strong>. To open your account and process payments, we
            collect and receive identifiers like your name, email address and, depending on how you purchase a
            subscription to a Paid Service, your billing address and other payment
            information.&nbsp;&nbsp;</li>
          <li><b>Technical Data</b>, including internet protocol (IP) address, browser type and version,
            browsing actions and patterns, time zone setting and location, browser plug-in types and
            versions, information about operating system, platform and other technologies on the devices
            you use to access our Services.</li>
          <li><b>Usage Data</b>, including information about how you use our Platforms, products and
            services.</li>
          <li><b>Device Data</b>: we collect information from the device you use to access our Platforms.
            This includes IP address, language setting, device model, device operating system version,
            Ologz-installed application version.</li>
          <li><b>Profile Data</b>, such as your interests and preferences based on our profiling activity,
            and your responses to surveys and request for feedback. This information may include
            Personal Data we hold about you.</li>
          <li><b>Social Media Profile</b>: we collect your social media profile details including your
            social media username, your name, profile photo and any other information you choose to make
            available to us when you connect with or contact us through your social media account or use
            our products and services through your social media account.</li>

          <li><b>Email communications</b>: we collect and store all copies of emails you send to us and
            our replies to your emails.</li>
        </MyUl>


        <MyText>
          You are under no obligation to provide Personal Data to us. However, certain Personal Data is essential
          for the provision of and the quality of the products and services we offer, and you might not be able to
          obtain certain services if you do not provide the information required for those services.
        </MyText>


        <MyText>
          If you use our native mobile applications, we will ask if you want to receive push notifications from us.
          Push notifications may include alerts, sounds, icon badges and other information in relation to the use
          of the mobile application itself. You can choose to allow or reject push notifications being sent to
          your device. If you do not allow us to send you push notifications, you will be able to use the mobile
          application, but you may not get the full benefit of its features. You can control your preferences to
          receive push notifications via your device settings.
        </MyText>
        <MyText t="t2">
          3. HOW IS YOUR INFORMATION COLLECTED?
        </MyText>
        <MyText>
          We use different methods to collect information from and about you and / or your device including
          through:
        </MyText>
        <MyUl>
          <li><b>Automated technologies or interactions.</b> As you interact with our Services, we may
            automatically collect Technical Data about your device, browsing actions and patterns. We collect
            this information by using cookies and other similar technologies. We may also receive Technical Data
            about you if you visit other websites employing our cookies or those of our advertising partners
            (see below). Please see our cookie policy for further details.</li>
          <li><b>Third parties or publicly available sources.</b> Third parties who provide advertising and
            related services may give us your Personal Data (including Technical Data). These third parties
            include analytics providers based within and outside the EU (such as Google) and ad networks.</li>
          <li><b>Third parties where you have provided your consent or for co-branded services.</b> Third parties
            may give us your Identity Data, Contact Data and/or Profile Data where you have provided your
            consent to that third party to share your information with us.</li>
          <li><b>Information collected from you.</b> You may give us your Identity Data, Contact Data and/or
            Profile Data by contacting us via our Platforms.</li>
        </MyUl>

        <MyText t="t2">
          4. HOW DO WE USE YOUR INFORMATION
        </MyText>
        <MyText>
          We will only use your information as and when the law allows us to. Most commonly, we will use your
          Personal Data in the following circumstances:
        </MyText>
        <MyUl>
          <li><strong>Performing our Services.</strong> We use a number of technologies to help you get
            the most out of the Ologz Service. Our systems automatically analyze your data to power
            Ologz features and to continually improve the Ologz Service for you in a way that does
            not require anyone to look at your Content. This may include, for example:</li>
          <ul>
            <li>Making sure you find what you’re looking for when you search your account.</li>
            <li>Showing you information most relevant to how you are or could be using the Ologz
              Service at a specific time or location.</li>
            <li>Suggesting actions for you to take based on information you’ve stored.</li>
          </ul>
        </MyUl>
        <MyUl>
          <li><strong>Maintaining our Services.&nbsp;</strong>To provide troubleshooting and customer
            support, our Customer Support team may need to access your information, such as your account
            email address and information about the Ologz application you are using, subject to the
            protections described below. &nbsp;</li>
          <li><strong>Protecting against security threats, abuse, and illegal activity.&nbsp;</strong>As
            part of our efforts to protect your account and the functionality of the Ologz Service,
            our systems may analyze the emails you send to and from your Ologz account and the notes
            you share to detect spam, malware, or other potential security concerns.
          </li>
          <li>Communicating with you.&nbsp;In accordance with your communication preferences, we’ll
            occasionally contact you to announce new products and features we build for you or share tips
            for using Ologz Service.</li>
        </MyUl>

        <MyText t="t2">
          REASONS FOR WHICH WE WILL USE YOUR INFORMATION
        </MyText>
        <MyText>
          We have set out below in a table format a description of all of the ways we plan to use your Personal
          Data, and which of the lawful bases we rely on to do so. We have also identified what our Legitimate
          Interests are where appropriate.
        </MyText>

        <Table sx={{ '& tr td, & tr th': { border: '1px solid #afafaf', padding: 2 } }}>
          <TableHead>
            <tr>
              <th>Activity</th>
              <th>Type of data</th>
              <th>Lawful basis for using your information</th>
            </tr>
          </TableHead>
          <TableBody>
            <tr>
              <td>To improve our Platforms and Services, customer relationships and experiences.</td>
              <td>
                <ol type="1">
                  <li>Usage Data</li>
                  <li>Technical</li>
                  <li>Device</li>
                  <li>Profile</li>
                </ol>
              </td>
              <td>
                <ol type="1">
                  <li>Necessary for our Legitimate Interests (to define categories of users of our
                    Products and Services, to keep our Platforms updated and relevant, and to develop
                    our business and to inform our marketing and trading strategy).</li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>To administer and protect our business, our Platforms (including troubleshooting, data
                analysis, testing, system maintenance, support, reporting and hosting of data)</td>
              <td>
                <ol type="1">
                  <li>Usage</li>
                  <li>Technical</li>
                  <li>Device</li>
                </ol>
              </td>
              <td>
                <ol type="1">
                  <li>Necessary for our Legitimate Interests (for running our business, provision of
                    administration and IT services, network security, to prevent fraud and in the
                    context of a business reorganisation or group restructuring exercise).</li>
                  <li>Necessary to comply with a legal obligation.</li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>To deliver relevant Service content to you and measure or understand the effectiveness of
                our services to you</td>
              <td>
                <ol type="1">
                  <li>Usage</li>
                  <li>Technical</li>
                  <li>Device</li>
                </ol>
              </td>
              <td>
                <ol type="1">
                  <li>(a) Necessary for our Legitimate Interests (to study how customers use our
                    products/services, to develop them and to grow our business).</li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>To communicate our terms of use, privacy notice and cookie policy, and notify you of changes
                to these and/or any changes to our products and services</td>
              <td>
                <ol type="1">
                  <li>Usage</li>
                  <li>Technical</li>
                  <li>Device</li>
                  <li>Profile</li>
                  <li>Marketing</li>
                </ol>
              </td>
              <td>
                <ol type="1">
                  <li>Necessary to comply with a legal obligation.</li>
                  <li>Necessary for our Legitimate Interest (to keep our records updated and to study how
                    customers use our products and services).</li>
                </ol>
              </td>
            </tr>
          </TableBody>
        </Table>
        <MyText t="t3">
          COOKIES
        </MyText>
        <MyText>
          You can set your browser to refuse all or some browser cookies, or to alert you when websites set or
          access cookies. If you disable or refuse cookies, please note that some parts of our Platforms may
          become inaccessible or not function properly. For more information about the cookies we use and how to
          manage your preferences, please see our&nbsp;<Link component={RouterLink} to="/cookies-policy">cookie policy</Link>.
        </MyText>

        <MyText t="t2">
          5. DISCLOSURES OF YOUR INFORMATION
        </MyText>
        <MyText>
          Your information will only be disclosed to companies within the Ologz group of companies and third
          parties outside those groups in accordance with the terms of this privacy notice.
        </MyText>

        <MyText>
          Ologz may transfer your information to another company if it restructures its business. Ologz may
          transfer your information to a third party if the operator of the App changes, or if there is a sale of
          all or any part of our business or its assets. In the event of any of these transfers occurring, the
          party who acquires the data will assume the rights and obligations described in this privacy notice.
        </MyText>

        <MyText>
          Ologz may engage the services of third party service providers and suppliers for certain activities
          including, but not limited to, processing your information and providing technical support to our
          Services. For example, we engage service providers to host our Platforms, store data, send marketing
          communications, carry out data cleansing to suppress our marketing lists, carry out marketing data
          segmentation, and perform data analytics to measure and understand our audience, tailor our marketing
          and trading services, and improve your experience of our Platforms. Ologz will take steps to ensure
          that arrangements with third party service providers protect your privacy by ensuring that these third
          parties comply strictly with instructions when they are processing your information. Ologz may share
          your Personal Data with third parties who process your Personal Data on our behalf when they provide
          services to us, for example data analytics, ad networks, financial services and for the purposes of
          recruitment.
        </MyText>

        <MyText>
          If we offer co-branded services such as promotions, competitions or events, together with or in
          association with a third party, Ologz may share the information you provide with that third party.
          These co-branded services may be hosted by us or by the third party. The third party’s use of your
          information will be governed by that third party’s privacy notice, which you should always read. This
          may also provide information about how that third party’s service providers may process your Personal
          Data. We require all third parties to respect the security of your information and to treat it in
          accordance with the law. We do not allow our third party service providers to use your information for
          their own purposes and only permit them to process your information for specified purposes and in
          accordance with our instructions.
        </MyText>

        <MyText t="t2">
          6. DATA SECURITY
        </MyText>
        <MyText>
          We have put in place appropriate security measures to prevent your information from being accidentally
          lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to
          your information to those employees, agents, contractors and other third parties who have a business
          need-to-know. They will only process your information on our instructions and they are subject to a duty
          of confidentiality.
        </MyText>

        <MyText>
          We have put in place procedures to deal with any suspected data breach and will notify you and any
          applicable regulator of a breach where we are legally required to do so.
        </MyText>
        <MyText t="t2">
          7. HOW CAN I MNAGE MY INFORMATION STORED IN OLOGZ?
        </MyText>

        <MyText>In most cases, you can manage your information simply by logging into your account and editing your
          information directly within the Ologz Service.</MyText>
        <MyText>If you prefer, you can contact us to  correct, update,
          or delete your personal information.</MyText>

        <MyText t="t3">
          How long does Ologz store my information?
        </MyText>
        <MyText>
          We make the Content you store in Ologz readily accessible to you, until you make the decision to delete it.
        </MyText>

        <MyText t="t3">
          What happens if Ologz closes my account?
        </MyText>
        <MyText>
          If Ologz deactivates your account due to a violation of our Terms of Service,
          then you may contact us to request deletion of your Content, and we will evaluate such requests on a case by case basis, pursuant to our legal obligations.
        </MyText>


        <MyText>
          Please note, however, that we may be subject to legal and regulatory requirements to keep Personal Data
          for a longer period, in particular pursuant to any applicable statutory limitation period.
        </MyText>
        <MyText t="t2">
          8. YOUR RIGHTS
        </MyText>
        <MyText>
          Under certain circumstances, you have rights under Data Protection Laws in relation to your Personal
          Data, including the right to:
        </MyText>

        <MyUl>
          <li>request access to your Personal Data;</li>
          <li>request correction of your Personal Data;</li>
          <li>request erasure of your Personal Data;</li>
          <li>object to processing of your Personal Data;</li>
          <li>request restriction of processing your Personal Data;</li>
          <li>request transfer of your Personal Data;</li>
          <li>withdraw consent;</li>
          <li>object (where our lawful basis for processing your Personal Data is based on our Legitimate
            Interests); and</li>
          <li>complain to the regulator (if you are unhappy with our response to your query) by visiting
            &nbsp;<a href="https://azop.hr/" target="_blank" rel="noopener noreferrer">https://azop.hr/</a>
          </li>
        </MyUl>

        <MyText t="t2">
          9. QUESTIONS
        </MyText>
        <MyText>
          If you have any questions about this privacy notice or wish to otherwise exercise your rights (such as to
          withdraw your consent from receiving marketing communications), please contact us: <a href="mailto:ologs.group@gmail.com">ologs.group@gmail.com</a>
        </MyText>

        <MyText t="t2">
          10. CHANGES TO THIS PRIVACY NOTICE
        </MyText>
        <MyText>
          We may, from time to time, change or update this privacy notice. All changes to this privacy notice will
          be published on this page of our App. Each change will become effective upon publication. We recommend
          that you revisit and read this privacy notice regularly to ensure that you are up-to-date with the
          current terms.
        </MyText>

        <MyText t="t2">
          11. GLOSSARY
        </MyText>

        <Table sx={{ '& tr td, & tr th': { border: '1px solid #afafaf', padding: 2 } }}>

          <thead>
            <tr>
              <th>Term</th>
              <th>Meaning</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ologz (or sometimes “we” or “us”)  </td>
              <td>Ologs d.o.o. company that also act as data controllers </td>
            </tr>
            <tr>
              <td>Ologz Service </td>
              <td>Ologz Software and other products, services and websites
                hosted or made available by Ologz.</td>
            </tr>

            <tr>
              <td>Ologz Software </td>
              <td>
                Software hosted on Ologz’s servers.
              </td>
            </tr>
            <tr>
              <td>Account Holder </td>
              <td>Person or entity who has contracted with Ologz as either an Account user or as a Customer. </td>
            </tr>
            <tr>
              <td>Content </td>
              <td>Text, images, and other data you choose to input, upload, and store in Ologz. </td>
            </tr>

            <tr>
              <td>Paid Service </td>
              <td>Any offering for which we collect payment. </td>
            </tr>




            <tr>
              <td>Anonymised Data</td>
              <td>Data sets that cannot be used to identify an individual.</td>
            </tr>
            <tr>
              <td>Data Controller</td>
              <td>A natural or legal person who (either alone or jointly or in common with other persons)
                determines the purposes for which and the manner in which any Personal Data are to be
                processed.</td>
            </tr>
            <tr>
              <td>Data Processor</td>
              <td>A natural or legal person which processes Personal Data on behalf of the Data Controller.
              </td>
            </tr>
            <tr>
              <td>Data Protection Laws</td>
              <td>Data Protection Act 2018 and the EU General Data Protection Regulation 2018 (GDPR), and any
                other applicable laws or regulations relating to the protection of Personal Data.</td>
            </tr>
            <tr>
              <td>Legitimate Interests</td>
              <td>Processing Personal Data for our business interest, activities and needs including providing
                you with the best service and experience we can offer. We balance our interests against any
                possible impact on you (both positive and negative), your rights and your freedom. Where our
                business interest and needs are overridden by your interests, rights or freedom, we will not
                process your Personal Data (unless you have provided us with your consent or unless required
                by any applicable laws or regulations).</td>
            </tr>
            <tr>
              <td>Personal Data</td>
              <td>Information relating to an identifiable person, who can be directly or indirectly identified
                in particular by reference to an identifier.</td>
            </tr>
            {/* <tr>
              <td>Special Category of Personal Data</td>
              <td>Personal Data revealing details about your race or ethnicity, religious or philosophical
                beliefs, sexual orientation, sex life, political opinions, trade union membership, and
                information about your health, genetic and biometric data.</td>
            </tr> */}
          </tbody>
        </Table>
      </Box>
      <Footer />
    </Container >
  );
}

