import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TocIcon from '@mui/icons-material/Toc';
import MyDrawer from './drawler/MyDrawer';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import './Layout.css';
import UserIcon from './parts/UserIcon';
import NavBtns from './parts/NavBtns';
import AppLogo from './parts/AppLogo';
import { SearchBtn } from './parts/SearchInput';
import { Button, GlobalStyles, useMediaQuery, useScrollTrigger, useTheme } from '@mui/material';
import CookieConsent from './CookieConsent';

export const drawerWidth = 400;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    // padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    // paddingLeft: theme.spacing(15),
    // paddingRight: theme.spacing(15),

    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor:
        theme.palette.mode === 'light'
            ? theme.palette.grey[300]
            : theme.palette.grey[900],
    minHeight: '100vh',
    [theme.breakpoints.up('md')]: {
        width: '100vw',
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
            width: `calc(100vw - ${drawerWidth}px)`,
        }),
    },
    [theme.breakpoints.down('md')]: {
        width: '100vw',
        ...(open && {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            width: 0,
        }),
    },

}));

/**
 * Need this for scroll bar below App Bar
 */
const BoxContent = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    paddingLeft: '3%',
    paddingRight: '3%',
    [theme.breakpoints.up('md')]: {
        paddingLeft: '15%',
        paddingRight: '15%',
        ...(open && {
            transition: theme.transitions.create('padding', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            paddingLeft: '10%',
            paddingRight: '10%',
        }),
    },
    [theme.breakpoints.down('xs')]: {

    }

}));



interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    background: theme.palette.mode === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[900],
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
        ...(open && {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}px`,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }
}));

const AppToolbar = styled(Toolbar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    // boxShadow: '0 4px 6px -4px #222',
    marginLeft: '3%',
    marginRight: '3%',
    paddingLeft: '0px',
    paddingRight: '0px',
    [theme.breakpoints.up('md')]: {
        paddingLeft: '0px',
        paddingRight: '0px',
        marginLeft: '15%',
        marginRight: '15%',
        ...(open && {
            marginLeft: '10%',
            marginRight: '10%',
        }),
    }
}));


const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

interface ElevationScrollProps {
    children: React.ReactElement;
}

function ElevationScroll(props: ElevationScrollProps) {
    const { children } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window,
    });

    const theme = useTheme();
    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
        sx: trigger ? { background: theme.palette.common.white } : null,
    });
}


export default function Layout() {
    const [open, setOpen] = React.useState(true);
    let { topicId, itemId } = useParams();
    const theme = useTheme();
    const isMD = useMediaQuery(theme.breakpoints.up('md'));
    const { pathname } = useLocation();




    const handleDrawerOpen = React.useCallback(() => {
        setOpen(true);
    }, [setOpen]);


    React.useEffect(() => {
        if (!topicId) {
            setOpen(false);
        } else {
            if (!isMD) {
                setOpen(false)
            } else {
                setOpen(true);
            }
        }
    }, [topicId, itemId, isMD, setOpen])


    // scroll always to top!!
    React.useEffect(() => {
        setTimeout(() => {
            window.scroll(0, 0);
        }, 200);
    }, [pathname]);

    return (
        <Box sx={{ display: 'flex' }}>
            <GlobalStyles styles={{ ul: {} }} />
            <CssBaseline />
            <ElevationScroll>
                <AppBar position='fixed' open={open}
                    color="primary"
                    elevation={0}
                    sx={{}}
                >
                    <AppToolbar open={open} elevation={5}>
                        <Button
                            variant="outlined"
                            aria-label="open drawer"
                            color="inherit"
                            size='large'
                            onClick={handleDrawerOpen}
                            sx={{ mr: 2, ...((!topicId || open) && { display: 'none' }) }}
                        >
                            <TocIcon fontSize={'large'} />
                        </Button>

                        <Button component={Link} color="inherit" to="/">
                            <Typography
                                variant="h5"
                                noWrap
                                component="div"
                                color="inherit"
                                sx={{ display: open ? 'none' : 'flex', alignItems: 'center', cursor: 'pointer' }}
                            >
                                <AppLogo height="40px" />
                            </Typography></Button>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end', alignItems: 'center' }}>
                            <SearchBtn />
                            <UserIcon />
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end', alignItems: 'center' }}>
                            <SearchBtn />
                            <NavBtns />
                            <UserIcon />
                        </Box>

                    </AppToolbar>
                </AppBar>
            </ElevationScroll>
            <MyDrawer open={open} setOpen={setOpen} ></MyDrawer>
            <Main open={open}>
                <Offset />
                <BoxContent open={open}>
                    <Outlet />
                </BoxContent>
            </Main>

            <CookieConsent></CookieConsent>
        </Box >
    );
}
