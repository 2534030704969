import * as React from 'react';

const styleSvg = {
    border: '1px solid #9d9d9d',
    borderRadius: '10px'
}
interface MySvgProps {
    children?: React.ReactNode;
    width: string;
    viewBox: string;
    preserveAspectRatio: string;
}
export const MySvg = (props: MySvgProps) => {
    const { width, viewBox, preserveAspectRatio, children } = props;
    return <svg style={styleSvg} width={width} height={width} viewBox={viewBox} preserveAspectRatio={preserveAspectRatio}>{children}</svg>
}

interface MyTextSvgProps {
    x?: number;
    y?: number;
    dy?: number | string;
    fill: string;
    transform?: string;
    text: string;
    textAnchor?: 'start' | 'middle' | 'end';
    fontFamily?: string;
}
export const MyTextSvg = (props: MyTextSvgProps) => {
    const { x, y, dy, transform, fill, text, textAnchor, fontFamily } = props;
    return <text x={x} y={y} fill={fill} dy={dy} fontSize={8} textAnchor={textAnchor || 'middle'} fontStyle="italic" fontFamily={fontFamily} transform={transform}>{text}</text>
}



interface MyGroupSvgProps {
    transform?: string;
    children?: React.ReactNode;
}
export const MyGroupSvg = (props: MyGroupSvgProps) => {
    const { children, transform } = props;
    return <g transform={transform}>
        {children}
    </g>
}


interface MyPathSvgProps {
    stroke: string;
    strokeDasharray?: string;
    fill?: string;
    d: string;
    fillOpacity?: number;
}
export const MyPathSvg = (props: MyPathSvgProps) => {
    const { stroke, d, fillOpacity, strokeDasharray, fill } = props;
    return <path stroke={stroke} d={d} fillOpacity={fillOpacity} fill={fill} strokeDasharray={strokeDasharray} />
}


interface MyLineSvgProps {
    stroke: string;
    x2?: number;
    y2?: number;
}
export const MyLineSvg = (props: MyLineSvgProps) => {
    const { stroke, x2, y2 } = props;
    return <line stroke={stroke} x2={x2} y2={y2}></line>
}


interface MyCircleSvgProps {
    stroke: string;
    fill?: string;
    cy: number;
    cx: number;
    r: number;
}
export const MyCircleSvg = (props: MyCircleSvgProps) => {
    const { stroke, r, cx, cy, fill } = props;
    return <circle cx={cx} cy={cy} r={r} stroke={stroke} fill={fill} />
}


