import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import { Link as LinkRouter } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Paper } from '@mui/material';
// import GoogleLogInBtn from '../../shared/google/GoogleLoginBtn';
import AppLogo from '../../shared/layout/parts/AppLogo';
import { Footer } from '../../shared/footer/Footer';
import PwdLogin from './PwdLogin';


function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'© '}
      <Link color="inherit" component={LinkRouter} to="/terms-of-use">
        Ologz
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function LogIn() {

  return (
    <Container maxWidth="lg">
      <Container maxWidth="xs">
        <CssBaseline />
        <Paper
          elevation={3}
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 3
          }}
        >
          <AppLogo height="40px" />
          {/* <Typography variant="h6" sx={{ mt: 6 }}>SIGN IN(Limited)</Typography> */}
          {/* <Typography variant="caption" sx={{ mb: 4 }}>Create new account or log in</Typography> */}
          {/* <Typography variant="caption" sx={{ mb: 4, color: "#8b053d" }}>*Currently App have limited number of accounts</Typography>*/}
          {/* <GoogleLogInBtn></GoogleLogInBtn> */}
          <PwdLogin></PwdLogin>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Paper>
        <Footer></Footer>
      </Container>
    </Container>
  );
}