import { Button, useMediaQuery, useTheme } from "@mui/material";
import React, { memo } from "react";
import { Transforms } from "slate";
import { useSelected, ReactEditor, useFocused, useSlateStatic } from "slate-react"
import DeleteIcon from '@mui/icons-material/Delete';

export const ImgComponent = memo(({ attributes, children, element, readOnly }: any) => {
    const selected = useSelected()
    const editor = useSlateStatic()

    const path = ReactEditor.findPath(editor, element)
    const focused = useFocused()
    //
    const theme = useTheme();
    const isMD = useMediaQuery(theme.breakpoints.up('md'));
    return (
        <div {...attributes}>
            {children}
            <div
                contentEditable={false}
                style={{ position: 'relative' }}
            >
                <img
                    src={element.url}
                    alt="Inserted File"
                    style={{
                        display: 'block',
                        width: isMD ? '50%' : '95%',
                        height: 'auto',
                        margin: '10px auto',
                        boxShadow: selected && focused ? '0 0 0 3px #B4D5FF' : 'none'
                    }}
                />
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        Transforms.removeNodes(editor, { at: path })
                    }}
                    color="error"
                    size="medium"
                    style={{
                        display: selected && focused ? 'inline' : 'none',
                        position: 'absolute',
                        top: '3%',
                        left: isMD ? '26%' : '4%',
                        backgroundColor: 'whhite'
                    }}
                >
                    <DeleteIcon fontSize="medium" />
                </Button>
            </div>
        </div>
    )
})
