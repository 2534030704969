import { ContentItem, Topic } from "./services/type";


const STORE_KEY = 'auth-math';
const COOKIE_CONSENT_KEY = 'cookie-consent';

// export type TopicBreadscrumbs = Pick<Topic, 'id' | 'title' | 'items'>;

export interface BreadcrumbsData {
    title: string;
    link: string;
    item: ContentItem | undefined;
    topic: Topic;
}
export const Util = {

    getPrefixDisplay: (topic: Topic, item: ContentItem | undefined) => {
        const bcrumbs = Util.getBreadcrumbs(topic, item);
        return bcrumbs.map(x => x.item ? `${x.item.order}.` : '').join('');
    },

    getBreadcrumbs: (topic: Topic, item: ContentItem | undefined) => {
        const findParent = (items: ContentItem[], parentId: string | undefined) => items.find(x => parentId && x.id === parentId);

        const tmpData: BreadcrumbsData[] = [];
        tmpData.push({ title: topic.title, link: `/topic/${topic.id}`, item: undefined, topic });

        if (item) {
            const tmpDataChildren: BreadcrumbsData[] = [];
            let tmpItem: ContentItem | undefined = item;
            while (!!tmpItem) {
                tmpDataChildren.push({ title: tmpItem.title, link: `/topic/${topic.id}/${tmpItem.id}`, item: tmpItem, topic });
                tmpItem = findParent(topic.items, tmpItem?.parent_id);
            }
            tmpData.push(...tmpDataChildren.reverse());
        }
        return tmpData;
    },

    deepCopy: (object: any) => {
        return JSON.parse(JSON.stringify(object))
    },

    getTopicChildren: (items: ContentItem[]) => {
        // reset all children!!
        items.forEach(x => x.children = []);
        // find children!!
        let children: ContentItem[] = [];
        if (items.length) {
            // root elements
            children = items.filter(x => !x.parent_id);
            children.sort((x, y) => x.order - y.order);
            children.forEach(x => fillChildren(x, items));
        }
        // return root
        return children;
    },

    getAuthToken: () => {
        return localStorage.getItem(STORE_KEY);
    },
    setAuthToken: (token: string | null) => {
        if (token)
            localStorage.setItem(STORE_KEY, token);
        else
            localStorage.removeItem(STORE_KEY);

    },

    getCookieConsent: () => {
        return localStorage.getItem(COOKIE_CONSENT_KEY) === 'true';
    },
    setCookeConsent: (value: boolean) => {
        localStorage.setItem(COOKIE_CONSENT_KEY, `${value}`);
    },

}
function fillChildren(item: ContentItem, all_items: ContentItem[]) {
    item.children = all_items.filter(x => x.parent_id && x.parent_id === item.id).map(x => {
        fillChildren(x, all_items);
        return x;
    });
    item.children.sort((x, y) => x.order - y.order);
}