import { Alert, Box, Card, CardActionArea, CardContent, Container, Grid, Typography, Divider } from '@mui/material';
import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Footer } from '../../shared/footer/Footer';
import { CardSvg } from '../../shared/imgs/CardSvg';
import { Loading } from '../../shared/loading/Loading';
import { useSearchTopicQuery } from '../../state/services/mathapi';
import { getTopicCategoryName, SearchResult } from '../../state/services/type';
//

function Search() {
    const { search } = useLocation();
    const qParams = new URLSearchParams(search);
    const searchQuery = qParams.get('q') || '';

    const nav = useNavigate();
    //
    const {
        data,
        error,
        isLoading
    } = useSearchTopicQuery(searchQuery, {
        skip: searchQuery.length < 1,
    });

    const goToTopic = useCallback((searchRes: SearchResult) => {
        nav(`/topic/${searchRes.topic_id}${searchRes.item_id && `/${searchRes.item_id}`}`);
    }, [nav])

    const loader = isLoading ? <Loading />: null;
    const errorShow = error ? <Alert severity="error">This is an error message!</Alert> : null;
    const noResult = !data?.length && !isLoading && !error ? <Alert variant="outlined" severity="info">
        No Results{searchQuery && ` for "${searchQuery}"`}!
    </Alert> : null

    const subTitle = searchQuery.length ? `Results for "${searchQuery}"` : '';
    return <Container sx={{}}>
        {/* End hero unit */}
        <Box
            sx={{
                // bgcolor: 'background.paper',
                pt: 8,
                pb: 6,
            }}
        >
            <Container maxWidth="sm">
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    Search Topics
                </Typography>
                <Typography variant="h5" align="center" color="text.secondary" paragraph>
                    {subTitle}
                </Typography>

            </Container>
            <Box>
                {loader}
                {errorShow}
                {noResult}
            </Box>
        </Box>

        <Grid container spacing={4}>

            {data?.map((topic, i) => (
                <Grid item key={i} xs={12} sm={6} md={4}>
                    <Card
                        style={{ animation: `0.3s fadeIn forwards`, animationDelay: `${i / 10}s`, opacity: 0 }}
                        sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                    >
                        <CardActionArea sx={{
                            flexGrow: 1, justifyContent: 'flex-start',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'normal'
                        }}

                            onClick={() => { goToTopic(topic) }} >
                            <CardSvg category={topic.category}/>
                            <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {topic.item_title || topic.topic_title}
                                    </Typography>
                                    <Divider variant='fullWidth'></Divider>

                                </Box>
                                <Box sx={{
                                    justifyContent: 'space-between',
                                    display: 'flex',
                                }}>
                                    <Typography variant="caption" sx={{ textAlign: 'right', mt: '10px' }}>
                                        {topic.topic_title}
                                    </Typography>
                                    <Typography variant="caption" sx={{ textAlign: 'right', mt: '10px' }}>
                                        {`${getTopicCategoryName(topic.category)}`}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            ))}
        </Grid>
        <Footer />
    </Container >
}

export default Search;
