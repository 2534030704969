import * as React from 'react';
import { ChartText, FnPoint, LinearScaleFn, TickItem, SVG_CONSTS, scaleLinear, ticksCalc, ChartMainProps, COLOR_BLACK, COLOR_GRAY } from './base/chart.interface';
import { FnPath } from './base/Functions';
import { MySvg, MyTextSvg, MyPathSvg, MyGroupSvg, MyLineSvg, MyCircleSvg } from './ChartSvg';

// W,H
// const ChartW = SVG_CONSTS.WIDTH - SVG_CONSTS.PL - SVG_CONSTS.PR;
// const ChartH = SVG_CONSTS.HEIGHT - SVG_CONSTS.PB - SVG_CONSTS.PT;
// points
const TopLeft = [SVG_CONSTS.PL, SVG_CONSTS.PT];
// const TopRight = [SVG_CONSTS.WIDTH - SVG_CONSTS.PR, SVG_CONSTS.PT];
const BottomLeft = [SVG_CONSTS.PL, SVG_CONSTS.HEIGHT - SVG_CONSTS.PB];
const BottomRight = [SVG_CONSTS.WIDTH - SVG_CONSTS.PR, SVG_CONSTS.HEIGHT - SVG_CONSTS.PB];


interface ChartProps extends ChartMainProps {
    width: string;

}
export function Chart(props: ChartProps) {

    const { width, fns, points, codomain, domain, text } = props;

    //
    const scaleY = scaleLinear(codomain, [BottomLeft[1], TopLeft[1]]);
    const scaleX = scaleLinear(domain, [BottomLeft[0], BottomRight[0]]);

    return <MySvg width={width} viewBox={`0 0 ${SVG_CONSTS.WIDTH} ${SVG_CONSTS.HEIGHT}`} preserveAspectRatio="xMinYMin meet">
        <Axis codomain={codomain} domain={domain} xScale={scaleX} yScale={scaleY}></Axis>
        {fns.map((fn, i) => (<FnPath key={i} domain={domain} codomain={codomain} func={fn} xScale={scaleX} yScale={scaleY}></FnPath>))}

        {points?.map((p, i) => {
            return <ChartPoint key={i} point={p} xScale={scaleX} yScale={scaleY} ></ChartPoint>
        })}
        {text?.map((t, i) => {
            return <ChartTextSVG key={i} text={t} xScale={scaleX} yScale={scaleY} ></ChartTextSVG>
        })}
        <ChartPoint point={{
            position: [0, 0],
            color: COLOR_GRAY,
        }} xScale={scaleX} yScale={scaleY}></ChartPoint>
    </MySvg>;
}



interface ChartTextSVGProps {
    text: ChartText,
    xScale: LinearScaleFn;
    yScale: LinearScaleFn;
}
export function ChartTextSVG(props: ChartTextSVGProps) {
    const { text: chartText, xScale, yScale } = props;
    const { text, position, transform, color: colorIn, fontFamily } = chartText;
    //
    const svgPoint = [xScale(position[0]), yScale(position[1])];
    const color = colorIn || COLOR_BLACK;
    return <MyTextSvg x={svgPoint[0]} y={svgPoint[1]} fill={color} transform={transform} text={text}  fontFamily={fontFamily}/>
}



interface ChartPointProps {
    point: FnPoint,
    xScale: LinearScaleFn;
    yScale: LinearScaleFn;
}
export function ChartPoint(props: ChartPointProps) {
    const { point, xScale, yScale } = props;
    const { position, color: colorIn, } = point;
    const r = SVG_CONSTS.WIDTH / 150;

    const svgPoint = [xScale(position[0]), yScale(position[1])];
    const color = colorIn || COLOR_BLACK;

    return <MyPointSvg cx={svgPoint[0]} cy={svgPoint[1]} r={r} color={color} />
}




interface AxisProps {
    domain: number[];
    codomain: number[];
    xScale: LinearScaleFn;
    yScale: LinearScaleFn;
}
export function Axis(props: AxisProps) {
    const { domain, codomain, xScale, yScale } = props;
    const ticksX = ticksCalc(domain);
    const ticksY = ticksCalc(codomain);
    const zeroPointSvg = [xScale(0), yScale(0)];
    const axisYStartPoint = [zeroPointSvg[0], BottomLeft[1]];
    const axisXStartPoint = [BottomLeft[0], zeroPointSvg[1]];
    //
    return <MyGroupSvg >
        <MyPathSvg stroke="#a3a3a3" d={`M ${axisYStartPoint.join(',')} V ${TopLeft[1]}`}></MyPathSvg>
        <Ticks axis='Y' ticks={ticksY} scale={yScale} zeroPoint={zeroPointSvg}></Ticks>
        <MyPathSvg stroke="#a3a3a3" d={`M ${axisXStartPoint.join(',')} H ${BottomRight[0]}`}></MyPathSvg>
        <Ticks axis="X" ticks={ticksX} scale={xScale} zeroPoint={zeroPointSvg} ></Ticks>
    </MyGroupSvg>
}



interface TicksProps {
    ticks: TickItem[],
    scale: LinearScaleFn,
    axis: 'X' | 'Y';
    zeroPoint: number[]
}
function Ticks(props: TicksProps) {

    const { ticks, scale, axis, zeroPoint } = props;


    const tickW = -SVG_CONSTS.TICK_W;

    const ticksX = zeroPoint[0];
    const ticksY = zeroPoint[1];

    const ticksGroups = ticks.map((t, i) => {

        const point = axis === 'Y' ? [ticksX, scale(t.value)] : [scale(t.value), ticksY];

        const lineX2 = axis === 'Y' ? tickW : undefined;
        const lineY2 = axis === 'X' ? -tickW : undefined;
        //
        const textX = axis === 'Y' ? tickW - 20 : undefined;
        const textY = axis === 'X' ? tickW + 15 : undefined;

        return <MyGroupSvg key={i} transform={`translate(${point.join(',')})`}>
            <MyLineSvg stroke={COLOR_BLACK} x2={lineX2} y2={lineY2} />
            <MyTextSvg fill={COLOR_BLACK} x={textX} y={textY} dy="0.32em" textAnchor="middle" text={t.label} />
        </MyGroupSvg>
    })

    return <>
        {ticksGroups}
    </>
}


interface MyPointSvgProps {
    cx: number;
    cy: number;
    r: number;
    color: string;
}
export const MyPointSvg = (props: MyPointSvgProps) => {
    const { cx, cy, r, color } = props;
    return <MyGroupSvg>
        <MyCircleSvg cx={cx} cy={cy} r={r} fill={color} stroke={color} />
    </MyGroupSvg>
}