import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import { logout, selectUser } from '../../../state/features/auth/authSlice';
import { Avatar, Button, Divider, ListItemIcon, PaperProps, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { MyNavigationItem, useMyNavigation, useUserNavigation, nav as navTypes } from './hooks';


export default function UserIcon() {
    const user = useAppSelector(selectUser);
    const nav = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const isMD = useMediaQuery(theme.breakpoints.up('md'));
    const navItems = useMyNavigation();
    const navUserItems = useUserNavigation();
    //
    const handleMenu = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, [setAnchorEl]);

    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    const handleNav = React.useCallback((route: string) => {
        nav(route);
        setAnchorEl(null);
    }, [nav]);

    const handleUserAction = React.useCallback((x: MyNavigationItem) => {
        switch (x.key) {
            case 'logout':
                nav('/');
                if (user) {
                    dispatch(logout());
                }
                break;
            case 'login':
                nav('/login');
                if (user) {
                    dispatch(logout());
                }
                break;
            case 'profile':
                nav('/profile');
                break;
            default:
                break;
        }

        setAnchorEl(null);
    }, [nav, user, dispatch]);

    const navigationMenu = React.useMemo(() => !isMD ? navItems.map(x => (
        <MenuItem key={x.key} onClick={() => handleNav(x.route)}>
            <ListItemIcon>
                <x.icon fontSize="small" />
            </ListItemIcon>
            {x.name}
        </MenuItem>
    )) : [], [navItems, handleNav, isMD]);

    let userMenu = React.useMemo(() => navUserItems.map(x => {
        return (
            <MenuItem key={x.name} onClick={() => handleUserAction(x)}>
                <ListItemIcon>
                    <x.icon fontSize="small" />
                </ListItemIcon>
                {x.name}
            </MenuItem>
        )
    }), [navUserItems, handleUserAction]);

    if (!user && isMD) {
        return <Button color='inherit' variant="outlined" sx={{ my: 1, mx: 1.5, fontWeight: 'bold'  }} onClick={() => { handleUserAction(navTypes.login) }} >{navTypes.login.name}</Button>;
    }

    return (
        <div>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            >
                {user ? <Avatar imgProps={{ referrerPolicy: 'no-referrer' }} sx={{ width: 32, height: 32 }} alt={user.name} src={user.picture||''} >{!user.picture && user.name?.slice(0, 1).toUpperCase()}</Avatar> : <MenuIcon></MenuIcon>}
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                PaperProps={PaperPropsConst}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                keepMounted
                open={!!anchorEl}
                onClose={handleClose}
            >
                {navigationMenu}
                {navigationMenu.length ? <Divider></Divider> : null}

                {userMenu}
            </Menu>
        </div >
    );
}


const PaperPropsConst: PaperProps = {
    elevation: 0,
    sx: {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 1.5,
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
        },
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 23,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        },
    },
}
