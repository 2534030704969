
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    BaseEditor,
    createEditor,
    Descendant,
    Editor,
    Transforms,

} from 'slate';
import { withHistory } from 'slate-history';
import { Slate, ReactEditor, Editable, withReact, RenderElementProps, RenderLeafProps } from 'slate-react';

import { Element } from './core/Element';
import { Leaf } from './core/Leaf';
import { CustomElement, CustomText, DialogDoneData, InputDialogData } from './slate.inteface';
import { SlateMToolbar } from './SlateToolbar';
import EditDialog from './EditDialog';
import { Box, Divider, Paper } from '@mui/material';
import { decorate } from './code/CodeHighlightElement';
import { withMyEditor } from './plugins/withMyEditor';
import { getSlateVal } from './core/parse/deserialize';
import { stringifyAll } from './core/parse/stringify';
import { useKeyDown } from './core/useKeyDown';


declare module 'slate' {
    interface CustomTypes {
        Editor: BaseEditor & ReactEditor
        Element: CustomElement
        Text: CustomText
    }
}

interface SladeMEditorProps {
    inputValue: string;
    onChange?: (x: string) => void;
    readOnly?: true;
}

export const SladeMEditor = (pops: SladeMEditorProps) => {

    const { inputValue, onChange, readOnly } = pops;
    const [dialogEditata, setDialogEditData] = useState<InputDialogData | null>(null)

    const editor = useMemo(() => withMyEditor(withHistory(withReact(createEditor())), setDialogEditData), []);

    const [value, setValue] = useState<Descendant[]>([])

    const keyHandler = useKeyDown(editor, readOnly);

    const renderElement = useCallback((props: RenderElementProps) => <Element {...props} readOnly={readOnly} />, [readOnly])
    const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} readOnly={readOnly} />, [readOnly])

    const doneMathEdit = React.useCallback(({ data, inputData, op }: DialogDoneData) => {
        if (op === 'cancel') {
            // nothing
        } else if (op === 'remove') {
            Transforms.removeNodes(editor, { at: inputData.path })
        } else {
            if (op === 'update' && data && inputData.path) {
                Transforms.removeNodes(editor, { at: inputData.path })
                Transforms.insertNodes(editor, data)
            } else if (op === 'insert' && data) {
                Transforms.insertNodes(editor, data)
            }
        }
        setDialogEditData(null);
    }, [setDialogEditData, editor]);

    const onEditorValueChange = React.useCallback((newValue: Descendant[]) => {

        if (!readOnly) {
            const val = stringifyAll(newValue)
            setValue(newValue)
            // console.log('onChange');
            // console.log(newValue)
            // console.log("Stringify")
            // console.log(val)
            if (onChange)
                onChange(val)
        }
    }, [onChange, setValue, readOnly])

    useEffect(() => {
        const fragment: Descendant[] = getSlateVal(inputValue || '<p></p>', true);
        editor.children = fragment;
        Editor.normalize(editor, { force: true })
        setValue(fragment);
    }, [inputValue, editor, setValue])


    const editorElement = <Editable
        style={{ minHeight: readOnly ? undefined : '700px', overflow: 'auto' }}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        readOnly={readOnly}
        decorate={decorate}
        spellCheck
        autoFocus={false}
        onKeyDown={keyHandler}
    />
    if (readOnly) {
        return <Slate
            editor={editor}
            value={value}
            onChange={onEditorValueChange}

        >
            {editorElement}
        </Slate>
    }
    return (
        <>
            <Slate
                editor={editor}
                value={value}
                onChange={onEditorValueChange}

            >
                <Box sx={{ p: '10px 15px', position: 'sticky', overflow: 'auto', top: 0, background: '#eeeeee', zIndex: 1 }}>
                    <SlateMToolbar></SlateMToolbar>
                    <Divider sx={{ pt: 1 }}></Divider>
                </Box>
                <Box sx={{ p: '20px 0px' }}>
                    <Paper elevation={3} sx={{ p: '25px 3%', minHeight: '800px' }} >
                        {editorElement}
                    </Paper>
                </Box>
            </Slate>
            <EditDialog inputData={dialogEditata} done={doneMathEdit} ></EditDialog>
        </>
    )
}