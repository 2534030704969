import React, { useEffect, useState } from 'react';
import { Box, Pagination as PaginationMat } from '@mui/material';
import { Topic } from '../../state/services/type';
import { useGetTopicsQuery } from '../../state/services/mathapi';

interface PaginationProps {
    onLoad: (data: Topic[], error?: any, isLoading?: boolean) => void;
    my: boolean;
}

const PAGE_SIZE = 20;
export const Pagination = (props: PaginationProps) => {

    const { onLoad, my } = props;
    const [skip, setSkip] = useState(0);
    const [topics, setTopics] = useState<Topic[]>([]);

    const take = PAGE_SIZE;
    const { data, error, isLoading } = useGetTopicsQuery({ my: my || false, skip, take });

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        const page = value;
        setSkip(PAGE_SIZE * (page - 1));
        console.log(`Tmp Page:${value}`)
    };

    useEffect(() => {
        if (data?.data.items) {
            setTopics((prevState: Topic[]) => ([...data.data.items]))
        }
    }, [data, error, isLoading, setTopics])

    useEffect(() => {
        onLoad(topics, error, isLoading);
    }, [topics, error, isLoading, onLoad])

    const count = data?.data.count;

    if (count && (skip + take) < count) {
        const totalPages = Math.ceil(count / take);

        return <Box sx={{ m: 4, display: 'flex', justifyContent: 'center' }}>

            <PaginationMat count={totalPages} variant="outlined" color="primary" onChange={handleChange} />
        </Box>
    }
    return null;

}