import { useEffect } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
// import { initGoogle } from '../shared/google/google';
import { logout, selectToken, setUser } from './features/auth/authSlice';
import { useGetUserQuery } from './services/mathapi';
import type { RootState, AppDispatch } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;



const voidVar = (() => { })();

export const useInitUser = () => {
    const dispatch = useAppDispatch();
    const token = useAppSelector(selectToken);
    const { data, isLoading, error } = useGetUserQuery(voidVar, { skip: !token });

    useEffect(() => {
        if (!isLoading) {
            if (error) {
                dispatch(logout());
            } else {
                dispatch(setUser(data));
            }
        }
    }, [data, isLoading, error, dispatch]);

    // useEffect(() => {
    //     initGoogle(async (responseGoogle: any) => {
    //         const { credential } = responseGoogle;
    //         console.log('Xcredential')
    //         console.log(credential)
    //         //
    //         dispatch(setGoogle(credential));
    //     })

    //     dispatch(initGoogleDone());

    // }, [dispatch])
}
