import { Box, IconButton, SelectChangeEvent, TextField } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { ChartText, COLOR_BLACK } from "../../../../chart/base/chart.interface";
import { MultyInput } from "../../edit/MultyInput";
import DeleteIcon from '@mui/icons-material/Delete';
import { debounce } from "../../../../helpers";


interface ChartTextItemInputProps {
    value: ChartText;
    onChange: (data: ChartText) => void;
    onRemove: () => void;
}


const ChartTextItemInput = memo((props: ChartTextItemInputProps) => {
    const { value, onChange, onRemove } = props;

    const [innerVal, setInnerVal] = useState(value)

    useEffect(() => {
        setInnerVal(value)
    }, [setInnerVal, value])

    const debouncedChange = React.useRef(debounce(nextValue => onChange(nextValue), 200))
        .current;

    const changePointValue = React.useCallback((point: number[], name: any) => {
        const newstateText = Object.assign({}, innerVal)
        newstateText.position = point;
        setInnerVal(newstateText)
        onChange(newstateText);
    }, [innerVal, setInnerVal, onChange])



    const handleChangeEvent = (event: SelectChangeEvent | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newInnerValue = Object.assign<any, any>({}, innerVal);
        const name = event.target.name;
        newInnerValue[name] = event.target.value;
        setInnerVal(newInnerValue);
        debouncedChange(newInnerValue);
    };
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <MultyInput name="position" label={['x', 'y']} value={innerVal.position} onChange={changePointValue}></MultyInput>
            <TextField
                name="color"
                type="color"
                InputProps={{
                    inputProps: {
                        max: 100, min: -100
                    }
                }}
                sx={{ width: '60px', m: '5px' }}
                label={"Color"} value={innerVal.color || COLOR_BLACK} onChange={handleChangeEvent} variant="outlined" />
            <TextField
                name="text"
                type="text"
                sx={{ width: '100px', m: '5px' }}
                label={"Text"} value={innerVal.text || ''} onChange={handleChangeEvent} variant="outlined" />
            <IconButton aria-label="delete"
                onClick={onRemove}>
                <DeleteIcon color="error" />
            </IconButton>
        </Box>

    );
})

export default ChartTextItemInput;