import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { drawerWidth } from '../Layout';
import { useParams } from 'react-router-dom';
import { Box, useMediaQuery } from '@mui/material';
import AppLogo from '../parts/AppLogo';
import { TableOfContents } from './TableOfContents';



// const topic = testTopicData[0];
interface MyDrawerProps {
  open: boolean;
  setOpen: (x: boolean) => void;
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function MyDrawer(props: MyDrawerProps) {
  const { open, setOpen } = props;
  let { topicId } = useParams();
  const theme = useTheme();
  const isCreate = topicId === 'new';
  const isMD = useMediaQuery(theme.breakpoints.up('md'));

  const handleDrawerClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Drawer
      sx={{
        width: { md: drawerWidth, xs: '100%' },
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: { md: drawerWidth, xs: '100%' },
          boxSizing: 'border-box',
        },
      }}
      variant={isMD ? 'persistent' : 'temporary'}
      anchor="left"
      open={open}
    >

      <DrawerHeader>
        <Box sx={{ flexGrow: 1 }}>
          <AppLogo height="40px" />
        </Box>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <Box sx={{ overflow: 'auto' }}>
        {!isCreate && topicId && <TableOfContents topicId={topicId}></TableOfContents>}
      </Box>
      <Divider />
    </Drawer>
  );
}
