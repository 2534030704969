import React from 'react';
import { ChartElement } from '../chart/ChartElement';
import { CodehHighlightElement } from '../code/CodeHighlightElement';
import { ImgComponent } from '../img/ImgComponent';
import { LinkComponent } from '../link/LinkComponent';
// import { CodeElement } from './code/CodeElement';
// import { CodePrims } from './code/CodePrims';
import { MathElement } from '../math/MathElement';

export const Element = (props: any) => {
    const { attributes, children, element } = props;
    const style = { textAlign: element.align }
    switch (element.type) {
        case 'link':
            return (
                <LinkComponent {...props}>
                </LinkComponent>
            )
        case 'block-quote':
            return (
                <blockquote style={style} {...attributes}>
                    {children}
                </blockquote>
            )
        case 'bulleted-list':
            return (
                <ul style={style} {...attributes}>
                    {children}
                </ul>
            )
        case 'heading-one':
            return (
                <h1 style={style} {...attributes}>
                    {children}
                </h1>
            )
        case 'heading-two':
            return (
                <h2 style={style} {...attributes}>
                    {children}
                </h2>
            )
        case 'list-item':
            return (
                <li style={style} {...attributes}>
                    {children}
                </li>
            )
        case 'numbered-list':
            return (
                <ol style={style} {...attributes}>
                    {children}
                </ol>
            )
        case 'mathblock':
        case 'mathinline':
            return (
                <MathElement {...props}></MathElement>
            )

        case 'paragraph':
            return (
                <p style={style} {...attributes}>
                    {children}
                </p>
            )
        case 'chart':
            return (
                <ChartElement {...props}></ChartElement>
            )
        case 'pre':
            return (
                <CodehHighlightElement {...props}>
                </CodehHighlightElement>
            )
        case 'image':
            return (
                <ImgComponent {...props}>
                </ImgComponent>
            )
        default:
            return (
                <p style={style} {...attributes}>
                    {children}
                </p>
            )
    }
}
