import React from "react"

export function debounce<Params extends any[]>(
    func: (...args: Params) => any,
    timeout: number,
): (...args: Params) => void {
    let timer: NodeJS.Timeout
    return (...args: Params) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            func(...args)
        }, timeout)
    }
}


/**
 * Custom debounce => pass func on each call with params!
 * @param timeout 
 * @returns 
 */
export function debounceWithFn<Params extends any[]>(
    timeout: number
) {
    let timer: NodeJS.Timeout
    return (func: (...args: Params) => any, args: Params) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            func(...args)
        }, timeout)
    }
}

export const appLinkStyles: React.CSSProperties = {
    color: '#1252a5',
    fontFamily: 'math'
}

export function encodeHTMLEntities(text: string) {
    var entities = [
        ['amp', '&'],
        ['apos', '\''],
        ['#x27', '\''],
        ['#x2F', '/'],
        ['#39', '\''],
        ['#47', '/'],
        ['lt', '<'],
        ['gt', '>'],
        ['nbsp', ' '],
        ['quot', '"'],
        ['rsquo', `'`],
        ['ldquo', `'`]
    ];

    for (var i = 0, max = entities.length; i < max; ++i)
        text = text.replace(new RegExp(entities[i][1], 'g'), '&' + entities[i][0] + ';');

    return text;
}
