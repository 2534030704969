import React from 'react';
import { Box, Container } from '@mui/material';
import { Footer } from '../../shared/footer/Footer';
import UserProfile from '../../shared/layout/user-profile/UserProfile';

export default function Profile() {


  return (
    <Container sx={{}}>
      <Box
        sx={{
          pt: 8,
          pb: 6,
        }}
      >
        <Container maxWidth="xs">
          <UserProfile></UserProfile>
        </Container>
      </Box>
      <Footer />
    </Container >
  );
}
