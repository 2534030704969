import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ListAltIcon from '@mui/icons-material/ListAlt';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PublicOffIcon from '@mui/icons-material/PublicOff';
import PublicIcon from '@mui/icons-material/Public';
import AddIcon from '@mui/icons-material/Add';
import { useAppSelector } from '../../state/hooks';
import { selectUser } from '../../state/features/auth/authSlice';
import { ContentItem, Topic } from '../../state/services/type';
import { copyPaste, CopyPasteData, getCopyPasted, removeCopyPaste } from '../copyPaste';
import { useCopyPasteMutation } from '../../state/services/mathapi';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));



export type EditBtnType = 'edit' | 'order' | 'delete' | 'copy' | 'public' | 'new_chapter';
interface EditBtnProps {
    topic: Topic;
    item?: ContentItem;
    onChange: (x: EditBtnType) => void
}
export default function EditBtn(props: EditBtnProps) {

    const { onChange, topic, item } = props;
    const user = useAppSelector(selectUser);
    const isEditable = user?.id === topic.user_id;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [copyPasteRequest, { isLoading: isCopyPasteLoading, }] = useCopyPasteMutation();
    const open = !!anchorEl;
    //
    const handleClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, [setAnchorEl]);
    //
    const handleClose = React.useCallback((e: any, value: EditBtnType | null = null) => {
        setAnchorEl(null);
        //
        if (value) {
            onChange(value);
        }
    }, [onChange, setAnchorEl]);
    //
    const handlePaste = React.useCallback((e: any, value: CopyPasteData) => {
        setAnchorEl(null);
        //
        if (value && !isCopyPasteLoading) {
            copyPasteRequest({
                src_topic_id: value.src_topic_id,
                src_item_id: value.src_item_id,
                dest_topic_id: topic.id,
                dest_item_id: item?.id,
            })
        }
        removeCopyPaste(value);
        toast.info(`Saving "${value.name}"`);
    }, [setAnchorEl, isCopyPasteLoading, topic, item, copyPasteRequest]);
    //
    const handleCopy = React.useCallback(() => {
        setAnchorEl(null);
        const name = item ? item.title : topic.title;
        copyPaste(name, topic.id, item?.id);
        toast.info(`Copied "${topic.title}"`)
    }, [item, topic, setAnchorEl]);
    //
    const editableMenuItems = [
        <MenuItem key="edit" onClick={(event) => handleClose(event, 'edit')} disableRipple>
            <EditIcon />
            Edit
        </MenuItem>,
        <MenuItem key="order" disabled={topic.items.length < 2} onClick={(event) => handleClose(event, 'order')} disableRipple>
            <ListAltIcon />
            Edit Order
        </MenuItem>,
        <MenuItem key="new_chapter" onClick={(event) => handleClose(event, 'new_chapter')} disableRipple>
            <AddIcon />
            New Chapter
        </MenuItem>,
        <MenuItem key="delete" onClick={(event) => handleClose(event, 'delete')} disableRipple>
            <ArchiveIcon />
            Delete
        </MenuItem>,
        <Divider key="divide1" sx={{ my: 0.5 }} />
    ]
    const copyPasteData = getCopyPasted()

    const isThisItem = (x: CopyPasteData) => (x.src_topic_id === topic.id) && ((!x.src_item_id && !item) || x.src_item_id === item?.id)
    const pasteData = copyPasteData.filter(x => !(isThisItem(x)));
    const hasCopy = pasteData.length !== copyPasteData.length;

    if (!user) {
        return null;
    } else if (isCopyPasteLoading) {
        return <CircularProgress />
    }

    return (
        <div>
            <Button
                aria-controls={open ? 'menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="outlined"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                <MoreVertIcon />
            </Button>
            <StyledMenu
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={(event) => handleClose(event, null)}
            >
                {isEditable && (editableMenuItems)}
                {isEditable && (<MenuItem key="delete" onClick={(event) => handleClose(event, 'public')} disableRipple>

                    {topic.public ? <PublicOffIcon /> : <PublicIcon />}
                    {topic.public ? 'Make Private' : 'Make Public Visible'}
                </MenuItem>)}

                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={handleCopy} disableRipple disabled={hasCopy}>
                    <ContentCopyIcon />
                    Copy
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                {isEditable && pasteData.map((x, i) => (
                    <MenuItem key={i} onClick={(event) => handlePaste(event, x)} disableRipple>
                        <ContentPasteIcon />
                        {`Paste "${x.name}"`}
                    </MenuItem>
                ))}
            </StyledMenu>
        </div>
    );
}
