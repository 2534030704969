import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
// import ExpandMoreIcon from '@mui/icons-material/ExpandLess';
import { Typography, Box, Link, AccordionActions, Button, Divider } from '@mui/material';
import { MathMLTitle } from '../math-ml-content/math-ml-content';
import { ContentItem as ItemData, Topic } from '../../state/services/type';
import { Util } from '../../state/utility';
import { appLinkStyles } from '../helpers';
import { MyExpandBtn } from '../btns/ExpandBtn';
import { useAppSelector } from '../../state/hooks';
import { selectUser } from '../../state/features/auth/authSlice';
import { SladeMEditor } from '../SlateMEditor/SlateMEditor';


interface ContentItemProps {
    item: ItemData;
    topic: Topic;
    onClickView: () => void;
}
export default function ContentItem(props: ContentItemProps) {

    const { item, topic, onClickView } = props;
    const user = useAppSelector(selectUser);
    const isEditable = user?.id === topic.user_id;

    const onView: React.MouseEventHandler = React.useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        onClickView();
        return false;
    }, [onClickView])

    const prefix = Util.getPrefixDisplay(topic, item);
    return (
        <Accordion TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
                expandIcon={<MyExpandBtn />}
                aria-controls="panel1a-content"
                id="panel1a-header"

            >
                <Box sx={{
                    display: 'flex',
                    justifyContent: "space-between",
                    width: '100%'
                }}

                >
                    <Link onClick={onView} underline="none">
                        <Typography variant="h6" sx={{ ...appLinkStyles }}>
                            {prefix} &nbsp;
                            <MathMLTitle content={item.title}></MathMLTitle>
                        </Typography>
                    </Link>

                </Box>
            </AccordionSummary>
            <Divider variant="fullWidth" />

            <AccordionDetails>
                {/* <MathMLContent content={item.content}></MathMLContent> */}
                <SladeMEditor inputValue={item.content} readOnly={true}></SladeMEditor>
            </AccordionDetails>
            {(!!item.children.length || isEditable) && <>
                <Divider variant="fullWidth" />
                <AccordionActions >
                    <Button sx={{ ...appLinkStyles }} variant="text" onClick={onView}>
                        {isEditable ? 'View/Edit' : 'View Chapters'}
                    </Button>
                </AccordionActions>
            </>}
        </Accordion >
    );
}
