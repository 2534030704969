import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { User } from '../../services/type';
import { Util } from '../../utility';


export interface AppuserState {
  user: User | null;
  token: string | null;
}

export interface AuthState {
  user: User | null;
  token: string | null;
  googleCredential: string | null;
  initGoogle: boolean;
}

const initialState: AuthState = {
  user: null,
  token: Util.getAuthToken(),
  initGoogle: false,
  googleCredential: null,
};


export const topicSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<AppuserState>) => {
      state.user = action.payload.user
      state.token = action.payload.token
      Util.setAuthToken(action.payload.token);
    },
    logout: (state) => {
      state.user = null
      state.token = null;
      state.googleCredential = null;
      Util.setAuthToken(null);
    },
    setUser: (state, action: PayloadAction<User | undefined>) => {
      state.user = action.payload || null;
    },
    initGoogleDone(state) {
      state.initGoogle = true;
    },
    setGoogle(state, action: PayloadAction<string>) {
      state.googleCredential = action.payload;
    },
  },
});

export const { login, logout, setUser, initGoogleDone, setGoogle } = topicSlice.actions;

export const selectUser = (state: RootState) => state.auth.user;
export const selectToken = (state: RootState) => state.auth.token;
export const selectGoogleCredential = (state: RootState) => state.auth.googleCredential;
export const selectGoogleInit = (state: RootState) => state.auth.initGoogle;

export default topicSlice.reducer;
