import React, { useCallback, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

interface ModalContextVal {
    openModal: (x: ModalData) => void;
    clearAll: () => void;
}

interface ModalData {
    message: any;
    title: string;
    onClose: (v?: any) => void;
}

const ModalContext = React.createContext<ModalContextVal>({
    openModal: (x) => { },
    clearAll: () => { }
})


interface AppModalProps {
    data: ModalData;
    close: () => void
}

const AppModal = (props: AppModalProps) => {

    const { data: { message, onClose, title } } = props;
    // open By Default
    const [open, setOpen] = useState(true)
    //
    const handleClose = () => {
        setOpen(false);
        onClose()
        props.close();
    };

    const handleCloseAgree = () => {
        setOpen(false);
        onClose(true)
        props.close();
    };
    return <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby={title}
        aria-describedby={title}
    >
        <DialogTitle>
            {title}
        </DialogTitle>
        <DialogContent>
            {message}
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="error">Disagree</Button>
            <Button onClick={handleCloseAgree} color="success" autoFocus>
                Agree
            </Button>
        </DialogActions>
    </Dialog>

}


export const useModal = () => {
    const context = React.useContext(ModalContext)
    if (context === undefined) {
        throw new Error('useModal must be used within a UserProvider')
    }

    return context;
}


export const ModalProvider = (props: any) => {

    const [list, setList] = useState<ModalData[]>([])

    const clearAll = useCallback(() => {
        setList([])
    }, [setList])

    const openModal = useCallback((x: ModalData) => {
        console.log(list)
        setList([...list, x])
    }, [list, setList])

    const closeModal = useCallback((x: ModalData, i: number) => {
        list.splice(i, 1);
        setList([...list])
        console.log("Close list")
        console.log(list)
    }, [list, setList])

    return (
        <ModalContext.Provider value={{ clearAll, openModal }} {...props} >
            {props.children}
            {list.map((x, i) => (<AppModal key={i} data={x} close={() => { closeModal(x, i) }} />))}
        </ModalContext.Provider>
    )
}