import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    useCeateContentItemMutation, useCeateTopicMutation,
    useDeleteContentItemMutation, useDeleteTopicMutation,
    useUpdateContentItemMutation, useUpdateTopicMutation, useUpdateTopicOrderMutation
} from '../../state/services/mathapi';
import { Topic } from '../../state/services/type';
import { EditData } from '../../shared/topic-item/EditDialogML';
import { toast } from 'react-toastify';


export function useTopicActions(setOpen: (x: boolean) => void, topic: Topic | undefined, itemId: string | undefined) {

    let navigate = useNavigate();
    const item = topic?.items.find(x => x.id === itemId);

    const [createTopic, { isLoading: isCreatingTopic, }] = useCeateTopicMutation();
    const [updateTopic, { isLoading: isUpdatingTopic, }] = useUpdateTopicMutation();
    //
    const [createContentItem, { isLoading: isCreatingCItem, }] = useCeateContentItemMutation();
    const [updateContentItem, { isLoading: isUpdatingCItem, }] = useUpdateContentItemMutation();
    //
    const [deleteTopic, { isLoading: isdDeletingTopic, }] = useDeleteTopicMutation();
    const [deleteContentItem, { isLoading: isdDeletingCItem, }] = useDeleteContentItemMutation();
    //
    const [updateTopicOrder, { isLoading: isSavingOrder }] = useUpdateTopicOrderMutation();

    const saveEditData = React.useCallback(async (data: EditData) => {

        toast.info('Saving data!');
        if (data.isCreate) {
            if (data.isTopic) {
                const newTopic = await createTopic({
                    title: data.title,
                    description: data.description || '',
                    content: data.content,
                    category: data.category
                }).unwrap()
                navigate(`/topic/${newTopic.id}`);
                // setOpen(false);
            }
            else {
                await createContentItem({
                    topic_id: topic?.id || '',
                    parent_id: itemId,
                    title: data.title,
                    content: data.content
                }).unwrap()
                setOpen(false);
            }
        } else {

            if (data.isTopic) {
                await updateTopic({
                    id: topic?.id || '',
                    title: data.title,
                    description: data.description || '',
                    content: data.content,
                    category: data.category
                }).unwrap()
                setOpen(false);
            }
            else {
                await updateContentItem({
                    id: itemId || '',
                    topic_id: topic?.id || '',
                    title: data.title,
                    content: data.content
                }).unwrap()
                setOpen(false);
            }

        }
        toast.success('Saved data!')

    }, [topic, itemId, setOpen, updateTopic, createTopic, updateContentItem, createContentItem, navigate]);

    const handleRemove = React.useCallback(async (item_id_del?: string) => {
        if (item_id_del) {
            let parentId: string | undefined = undefined;
            if (item_id_del === item?.id) {
                parentId = item.parent_id;
            }
            if (parentId)
                navigate(`/topic/${topic?.id}/${parentId}`)
            await deleteContentItem(item_id_del)
            toast.success('Deleted success!');
        } else if (topic?.id) {
            navigate(`/my`)
            // delay execution => skip refetch 150 ms => animation duration
            setTimeout(async () => {
                // 
                await deleteTopic(topic.id);
                toast.success('Deleted success!');
            }, 400)
        }

    }, [topic, item, deleteContentItem, deleteTopic, navigate]);

    const togglePublic = React.useCallback(async () => {
        if (topic) {
            const msg = topic.public ? 'Making Private' : 'Making Public'
            toast.info(msg);
            await updateTopic({
                id: topic.id,
                public: !topic.public
            }).unwrap()
            toast.success('Saved data!')
        }
    }, [updateTopic, topic]);

    const isLoading = isCreatingTopic || isUpdatingTopic || isCreatingCItem || isUpdatingCItem || isdDeletingTopic || isdDeletingCItem || isSavingOrder;

    return { isLoading, saveEditData, handleRemove, updateTopicOrder, togglePublic };
}