import { MyCircleSvg, MyPathSvg } from "../ChartSvg";
import { ChartFnShape, ChartFunction, COLOR_BLACK, LinearScaleFn } from "./chart.interface";
import * as React from 'react';


var isNumber = function isNumber(value: any) {
    return typeof value === 'number' && isFinite(value);
}



export function getFnDefaultParams(type: ChartFnShape) {
    const numOfParams = AllFunctions[type].numOfParams;
    let params = Array(numOfParams || 0).fill(2)
    if (type === 'l' || type === 'lz') {
        params = Array(6).fill(1).map((x, i) => i + i % 2 - i % 3);
    }
    return params;
}

export const AllFunctions = {
    //shapes
    l: { labelFn: 'Line', fn: null, isValid: (x: ChartFunction) => x.params.length > 3 && !x.params.map(isNumber).includes(false) && x.type, numOfParams: null },
    lz: { labelFn: 'Closed Line', fn: null, isValid: (x: ChartFunction) => x.params.length > 3 && !x.params.map(isNumber).includes(false) && x.type, numOfParams: null },
    circle: { labelFn: 'Circle', fn: null, isValid: (x: ChartFunction) => x.params.length === 3 && !x.params.map(isNumber).includes(false) && x.type, numOfParams: 3 },
    // functions!
    line: { labelFn: 'f(x)=ax + b', fn: (k: number, l: number) => (x: number) => k * x + l, isValid: (x: ChartFunction) => isNumber(x.params[0]) && isNumber(x.params[1]), numOfParams: 2 },
    quadratic: { labelFn: ' f(x)=ax^2+bx+c', fn: (a: number, b: number, c: number) => (x: number) => a * x * x + b * x + c, isValid: (x: ChartFunction) => isNumber(x.params[0]) && isNumber(x.params[1]) && isNumber(x.params[2]), numOfParams: 3 },
    power: { labelFn: ' f(x)=x^{a} ', fn: (n: number) => (x: number) => Math.pow(x, n), isValid: (x: ChartFunction) => isNumber(x.params[0]), numOfParams: 1 },
    root: { labelFn: 'f(x)=x^\\frac1a=\\sqrt[a]x', fn: (n: number) => (x: number) => (x < 0 && !!(n % 2) ? -1 : x < 0 ? NaN : 1) * Math.pow(Math.abs(x), 1 / n), isValid: (x: ChartFunction) => isNumber(x.params[0]), numOfParams: 1 },
    expo: { labelFn: 'f(x)=a^x', fn: (a: number) => (x: number) => Math.pow(a, x), isValid: (x: ChartFunction) => isNumber(x.params[0]), numOfParams: 1 },
    log: { labelFn: 'f(x)=\\log_a\\left(x\\right)', fn: (a: number) => (x: number) => Math.log10(x) / Math.log10(a), isValid: (x: ChartFunction) => isNumber(x.params[0]), numOfParams: 1 },
    tan: { labelFn: 'f(x)=tan(x)', fn: (x: number) => Math.tan(x), isValid: (x: ChartFunction) => true, numOfParams: 0 },
    atan: { labelFn: 'f(x)=atan(x)', fn: (x: number) => Math.atan(x), isValid: (x: ChartFunction) => true, numOfParams: 0 },
    cotan: { labelFn: 'f(x)=cotan(x)', fn: (x: number) => 1 / Math.tan(x), isValid: (x: ChartFunction) => true, numOfParams: 0 },
    acotan: { labelFn: 'f(x)=acotan(x)', fn: (x: number) => Math.PI / 2 - Math.atan(x), isValid: (x: ChartFunction) => true, numOfParams: 0 },
    sin: { labelFn: 'f(x)=sin(x)', fn: (x: number) => Math.sin(x), isValid: (x: ChartFunction) => true, numOfParams: 0 },
    asin: { labelFn: 'f(x)=asin(x)', fn: (x: number) => Math.asin(x), isValid: (x: ChartFunction) => true, numOfParams: 0 },
    cos: { labelFn: 'f(x)=cos(x)', fn: (x: number) => Math.cos(x), isValid: (x: ChartFunction) => true, numOfParams: 0 },
    acos: { labelFn: 'f(x)=acos(x)', fn: (x: number) => Math.acos(x), isValid: (x: ChartFunction) => true, numOfParams: 0 },
    sinh: { labelFn: 'f(x)=sinh(x)', fn: (x: number) => Math.sinh(x), isValid: (x: ChartFunction) => true, numOfParams: 0 },
    asinh: { labelFn: 'f(x)=asinh(x)', fn: (x: number) => Math.asinh(x), isValid: (x: ChartFunction) => true, numOfParams: 0 },
    cosh: { labelFn: 'f(x)=cosh(x)', fn: (x: number) => Math.cosh(x), isValid: (x: ChartFunction) => true, numOfParams: 0 },
    acosh: { labelFn: 'f(x)=acosh(x)', fn: (x: number) => Math.acosh(x), isValid: (x: ChartFunction) => true, numOfParams: 0 },
    tanh: { labelFn: 'f(x)=tanh(x)', fn: (x: number) => Math.tanh(x), isValid: (x: ChartFunction) => true, numOfParams: 0 },
    atanh: { labelFn: 'f(x)=atanh(x)', fn: (x: number) => Math.atanh(x), isValid: (x: ChartFunction) => true, numOfParams: 0 },
    cotanh: { labelFn: 'f(x)=cotanh(x)', fn: (x: number) => 1 / Math.tanh(x), isValid: (x: ChartFunction) => true, numOfParams: 0 },
    acotanh: { labelFn: 'f(x)=acotanh(x)', fn: (x: number) => Math.atanh(1 / x), isValid: (x: ChartFunction) => true, numOfParams: 0 },
}


function getFunction(fn: ChartFunction) {
    const params = fn.params
    const breakpoints: number[] = [];
    let fnRet: ((x: number) => number) | null = null;
    switch (fn.type) {
        case 'line':
            fnRet = AllFunctions[fn.type].fn(params[0], params[1]);
            break;
        case 'quadratic':
            fnRet = AllFunctions[fn.type].fn(params[0], params[1], params[2]);
            break;
        case 'power':
            const power = params[0]
            fnRet = AllFunctions[fn.type].fn(power);
            if (power < 0) {
                breakpoints.push(0);
            }
            break;
        case 'root':
            const root = params[0]
            fnRet = AllFunctions[fn.type].fn(root);
            if (!(root % 2)) {
                breakpoints.push(0);
            }
            break
        case 'expo':
            const aexp = params[0]
            fnRet = AllFunctions[fn.type].fn(aexp);
            break
        case 'log':
            const aLog = params[0]
            fnRet = AllFunctions[fn.type].fn(aLog);
            break
        case 'sin':
        case 'asin':
        case 'cos':
        case 'acos':
        case 'tan':
        case 'atan':
        case 'cotan':
        case 'acotan':
        case 'sinh':
        case 'asinh':
        case 'cosh':
        case 'acosh':
        case 'tanh':
        case 'atanh':
            fnRet = AllFunctions[fn.type].fn;
            break
        case 'cotanh':
            fnRet = AllFunctions[fn.type].fn;
            breakpoints.push(0);
            break;
        case 'acotanh':
            fnRet = AllFunctions[fn.type].fn;
            breakpoints.push(...[-1, 1]);
            break;
        default:
            fnRet = null;
    }
    return { fn: fnRet, breakpoints }
}


interface FnProps {
    domain: number[];
    codomain: number[];
    xScale: LinearScaleFn;
    yScale: LinearScaleFn;
    func: ChartFunction
}
export function FnPath(props: FnProps) {

    const { domain, codomain, func, yScale, xScale } = props;

    const { fn, breakpoints } = getFunction(func);
    if (!fn) {
        return <FnShape func={func} yScale={yScale} xScale={xScale} ></FnShape>;
    }

    const pointsOfEachDomain: number[][][] = [];

    const [domainMin, domainMax] = func.domain || domain;
    const inDomain = (y: number) => y > domainMin && y < domainMax;


    const maxNumOfLines = breakpoints.length + 1;
    let startPoint = domainMin;
    for (let index = 0; index <= maxNumOfLines; index++) {
        const breakPoint = breakpoints[index];
        if (breakPoint !== undefined) {
            if (inDomain(breakPoint)) {
                // add left side of breakpoint
                const MIN_STEP = 0.0001
                let domainTmp = [startPoint, breakPoint - MIN_STEP];
                const pointsTmp = getFnPoints(domainTmp, codomain, xScale, yScale, fn);
                pointsOfEachDomain.push(pointsTmp);
                startPoint = breakPoint + MIN_STEP;
            }

        } else {
            // last step!!
            let domainTmp = [startPoint, domainMax];
            const pointsTmp = getFnPoints(domainTmp, codomain, xScale, yScale, fn);
            pointsOfEachDomain.push(pointsTmp)
        }
        // push points

    }
    return <>
        {pointsOfEachDomain.map((points, i) => (<FnLine key={i} func={func} points={points}></FnLine>))}
    </>
}



function getFnPoints(domain: number[], codomain: number[], xScale: LinearScaleFn, yScale: LinearScaleFn, fn: (x: number) => number) {
    const [domainMin, domainMax] = domain;
    const [codomainMin, codomainMax] = codomain;
    const inCodomain = (y: number) => y > codomainMin && y < codomainMax;
    //
    const NUM_POINTS = 30;
    const domainDomain = domainMax - domainMin;
    const domainCoDomain = domainMax - domainMin;
    const X_step = domainDomain / NUM_POINTS;
    const Y_step = domainCoDomain / NUM_POINTS;


    const points: number[][] = [];

    let starX = domainMin;
    let tmpXStep = X_step;
    while (starX < domainMax) {
        tmpXStep = X_step;
        const tmpY = fn(starX);
        if (!isNaN(tmpY)) {
            if (inCodomain(tmpY)) {
                points.push([xScale(starX), yScale(fn(starX))]);
            }
            //check Step
            let nextTestY = fn(starX + tmpXStep);
            let deltaY = Math.abs(nextTestY - tmpY);
            if (deltaY > Y_step * 1.5) {
                tmpXStep = tmpXStep / 2;
                nextTestY = fn(starX + tmpXStep);
                deltaY = Math.abs(nextTestY - tmpY);
                if (deltaY > Y_step * 1.5) {
                    tmpXStep = tmpXStep / 2;
                    nextTestY = fn(starX + tmpXStep);
                    deltaY = Math.abs(nextTestY - tmpY);
                    if (deltaY > Y_step * 1.5) {
                        tmpXStep = tmpXStep / 3;
                    }
                }
            }
        }
        starX += tmpXStep;
    }
    return points;
}


interface FnShapeProps {
    func: ChartFunction;
    xScale: LinearScaleFn;
    yScale: LinearScaleFn;
}

function FnShape(props: FnShapeProps) {

    const { func, xScale, yScale } = props;
    if (func.type === 'circle') {
        return <ShapeCircle func={func} yScale={yScale} xScale={xScale} />;
    }
    if (func.type === 'l' || func.type === 'lz') {
        return <ShapeLine func={func} yScale={yScale} xScale={xScale} />;
    }
    return null;

}

interface FnLineProps {
    points: number[][];
    func: ChartFunction
}

function FnLine(props: FnLineProps) {

    const { points, func } = props;
    if (!points.length) {
        return null;
    }

    //
    const P1 = points.shift();

    let brazerC = '';
    let prevP = P1 || [0, 0];
    points.forEach(p => {

        const p1X = prevP[0] + (p[0] - prevP[0]);
        const p1Y = prevP[1] + (p[1] - prevP[1]);

        const middleP = [p1X, p1Y]
        brazerC += `Q ${middleP?.join(' ')} ${p.join(' ')} `
        prevP = p;
    })

    const d = `M ${P1?.join(' ')} ` + brazerC;

    let strokeDasharray: string | undefined = undefined;

    if (func.strokeType === 'dash') {
        strokeDasharray = '5,5';
    }

    // return <g className="chart-point">
    //     {points.map(p => (<circle cx={p[0]} cy={p[1]} r={1} fill={'red'} stroke={'red'} />))}
    // </g>
    return <MyPathSvg fill="transparent" stroke={func.color || COLOR_BLACK} d={d} strokeDasharray={strokeDasharray} />
}

interface ShapeCircleProps {
    func: ChartFunction;
    xScale: LinearScaleFn;
    yScale: LinearScaleFn;
}

function ShapeCircle(props: ShapeCircleProps) {

    const { func, xScale, yScale } = props;
    const r = Math.abs(xScale(0) - xScale(func.params[0]));
    const c = [xScale(func.params[1]), yScale(func.params[2])];
    const stroke = func.color || COLOR_BLACK;
    return <MyCircleSvg cx={c[0]} cy={c[1]} r={r} stroke={stroke} fill={'transparent'} />
}

interface ShapeLineProps {
    func: ChartFunction;
    xScale: LinearScaleFn;
    yScale: LinearScaleFn;
}

function ShapeLine(props: ShapeLineProps) {

    const { func, xScale, yScale } = props;
    const numOfpoints = Math.ceil(func.params.length / 2);
    const closePath = func.type === 'lz';
    const pointsPath = Array(numOfpoints).fill(0).map((x, i) => `${i ? 'L' : 'M'} ${xScale(func.params[i * 2])} ${yScale(func.params[i * 2 + 1])}`);
    if (closePath) pointsPath.push('Z');
    const d = pointsPath.join(' ');
    const stroke = func.color || COLOR_BLACK;
    let strokeDasharray: string | undefined = undefined;

    if (func.strokeType === 'dash') {
        strokeDasharray = '5,5';
    }
    return <MyPathSvg fill="transparent" stroke={stroke} d={d} strokeDasharray={strokeDasharray} fillOpacity={0} />
}
