
import {
    Editor, Transforms,
    Element as SlateElement,
    Path,
    Node as SlateNode,
} from 'slate'
import { CustomEditor } from '../slate.inteface';
import { getSlateVal } from './parse/deserialize';


export function copyTextasHtmlToClipboard(text: any) {
    if (!navigator.clipboard) {
        //   fallbackCopyTextToClipboard(text);
        return;
    }

    var type = "text/html";
    var blob = new Blob([text], { type });
    var data = [new ClipboardItem({ [type]: blob })];
    navigator.clipboard.write(data).then(function () {
        //   console.log('Async: Copying to clipboard was successful!');
    }, function (err) {
        console.error('Async: Could not copy text/html: ', err);
    });
}


export function copyTextToClipboard(text: any) {
    if (!navigator.clipboard) {
        //   fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(function () {
        //   console.log('Async: Copying to clipboard was successful!');
    }, function (err) {
        console.error('Async: Could not copy text: ', err);
    });
}

export const insertHtmlString = (html: string, editor: Editor) => {
    const fragmentText = getSlateVal('<span>&nbsp;</span>');
    const fragment = getSlateVal(html);
    //
    Transforms.insertFragment(editor, fragmentText)// first insert text node!! -> fix path error issue
    //
    Transforms.insertFragment(editor, fragment)
}



export function checkInsideElement(editor: CustomEditor, tag: string) {

    const { selection } = editor;
    if (selection) {
        const [match] = Array.from(
            Editor.nodes(editor, {
                at: Editor.unhangRange(editor, selection),
                match: n =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    n.type === 'pre',
            })
        )
        return !!match;
    }
    return false;

}

export function cursorOnElement(editor: CustomEditor, eTypes: string[]) {
    const [frst] = Array.from(Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && eTypes.includes(n.type),
    }))
    return !!frst
}


/**
 * For math inline - fix 
 * 
 * @param editor 
 */
export function replaceCurrentNode(editor: CustomEditor) {
    const { selection } = editor;
    if (selection) {
        const selectedNodePath = Path.parent(selection.anchor.path)
        const selectedNode = SlateNode.get(editor, selectedNodePath)
        if (SlateElement.isElement(selectedNode) &&
            selectedNode.type === 'mathinline') {
            Transforms.removeNodes(editor, { at: selectedNodePath })
            Transforms.insertNodes(editor, Object.assign({}, selectedNode));
        }
    }

}