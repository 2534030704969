import isHotkey, { isKeyHotkey } from "is-hotkey";
import React, { useCallback } from "react";
import { Editor, Range, Transforms } from "slate";
import { saveCopyPasteEditor } from "../../copyPasteEditorText";
import { MyEditor, TextStyles } from "../slate.inteface";
import { toggleMark } from "../SlateToolbar";
import { checkInsideElement, copyTextToClipboard, cursorOnElement, replaceCurrentNode } from "./helpers";
import { stringifyAll } from "./parse/stringify";


const HOTKEYS: { [key: string]: TextStyles } = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
}


export const useKeyDown = (editor: MyEditor, readOnly: true | undefined) => {

    return useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
        if (!readOnly) {
            for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event as any)) {
                    event.preventDefault()
                    const mark = HOTKEYS[hotkey]
                    toggleMark(editor, mark)
                }
            }
            if (isHotkey('mod+c', event) || isHotkey('mod+x', event)) {
                if (isHotkey('mod+c', event)) {
                    event.preventDefault();
                }
                const fragment = editor.getFragment();
                const html = stringifyAll(fragment);
                const selectedText = editor.selection ? Editor.string(editor, editor.selection) : '';
                saveCopyPasteEditor({
                    html,
                    text: selectedText
                });
                copyTextToClipboard(selectedText);
            }
            else if (isHotkey('enter', event)) {
                const insidePre = checkInsideElement(editor, 'pre');
                if (insidePre && !isHotkey('shift+enter', event)) {
                    event.preventDefault();
                    Editor.insertText(editor, '\n');
                }
                else if (cursorOnElement(editor, ['mathinline'])) {
                    event.preventDefault();
                    replaceCurrentNode(editor);
                    Transforms.move(editor, { distance: 1, unit: 'line' });
                }
            } else {
                const { selection } = editor;
                // Default left/right behavior is unit:'character'.
                // This fails to distinguish between two cursor positions, such as
                // <inline>foo<cursor/></inline> vs <inline>foo</inline><cursor/>.
                // Here we modify the behavior to unit:'offset'.
                // This lets the user step into and out of the inline without stepping over characters.
                // You may wish to customize this further to only use unit:'offset' in specific cases.
                if (selection && Range.isCollapsed(selection)) {
                    const { nativeEvent } = event
                    if (isKeyHotkey('left', nativeEvent)) {
                        event.preventDefault()
                        Transforms.move(editor, { unit: 'offset', reverse: true })
                        return
                    }
                    if (isKeyHotkey('right', nativeEvent)) {
                        event.preventDefault()
                        Transforms.move(editor, { unit: 'offset' })
                        return
                    }
                }
            }

        }
    }, [editor, readOnly]);

}
