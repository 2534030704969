import React from 'react';
// import { CodehHighlightLeaf } from './code/CodeHighlightElement';

export const Leaf = (props: any) => {
    let { attributes, children, leaf } = props;

    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code className={`hljs`}>
            {children}
        </code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    if (leaf.token) {
        children = <span className={`hljs-${leaf.token} token ${leaf.token}`} >
            {children}
        </span>
    }
    // return <span {...attributes}>{children}</span>

    return (
        <span
            // The following is a workaround for a Chromium bug where,
            // if you have an inline at the end of a block,
            // clicking the end of a block puts the cursor inside the inline
            // instead of inside the final {text: ''} node
            // https://github.com/ianstormtaylor/slate/issues/4704#issuecomment-1006696364
            style={{ paddingLeft: leaf.text === '' ? ' 0.1px' : undefined }}
            {...attributes}
        >
            {children}
        </span>)
}