import { ChartFnShape, ChartFunction, COLOR_BLACK, parseColor, SPLITTER_PARAMS, StrokeType } from "../chart.interface";
import { AllFunctions } from "../Functions";


export const parseFn = {

    parseFn(partType: string, value: string): ChartFunction | null {

        const functionBaseParts = partType.split(SPLITTER_PARAMS);
        const paramsValues = value.split(SPLITTER_PARAMS)
        const funname = functionBaseParts.shift() as ChartFnShape;
        let fnObject = AllFunctions[funname];
        if (!fnObject) {
            // no function!
            return null;
        }
        let numOfParams = fnObject?.numOfParams;
        if (numOfParams === null) {
            // fist one is number of points
            numOfParams = parseFloat(paramsValues.shift() || '') * 2;
        }
        const domain: number[] | undefined = functionBaseParts.length ? functionBaseParts.slice(0, 2).map(parseFloat) : undefined
        return {
            type: funname,
            domain,
            ...getLineCommonProps(paramsValues, numOfParams)
        };
    },

    stringifyFn(p: ChartFunction) {
        let params = p.params.join(SPLITTER_PARAMS);
        if (p.type === 'l' || p.type === 'lz') {
            const numOfParams = Math.ceil(p.params.length / 2);
            params = `${numOfParams};${params}`
        }
        const color = p.color || COLOR_BLACK;
        const strokeType = p.strokeType ? `${SPLITTER_PARAMS}${p.strokeType}` : '';

        //fn params!
        const domain = p.domain ? `;${p.domain.join(SPLITTER_PARAMS)}` : ''
        return ` ${p.type}${domain} | ${params}${params.length ? SPLITTER_PARAMS : ''}${color}${strokeType}`;
    }

}


function getLineCommonProps(value: string[], numOfParams: number): Pick<ChartFunction, 'params' | 'color' | 'strokeType'> {

    const totalNumOfParams = numOfParams + 2;// color and stroke
    const lastParamIndex = totalNumOfParams - 1;
    const getVal = (x: string) => x ? x.trim() : undefined;
    const color = parseColor(value[lastParamIndex - 1]);
    return {
        params: value.slice(0, numOfParams).map(parseFloat), // points!!
        color,
        strokeType: getVal(value[lastParamIndex]) as StrokeType,
    }

}