import * as React from "react";
import Mathlive, { MathfieldElement } from "mathlive";
import { Paper } from "@mui/material";
import "mathlive/dist/mathlive-fonts.css";
import "./MathfieldComponent.css";

interface BaseProps {
    onChange?: (latex: string) => void;
    readOnly?: boolean;
    isInline?: boolean;

    /**
     * The raw options of mathlive's makeMathField.
     * */
    mathfieldConfig?: any;

    /**
     * The mathfield object returned by makeMathField.
     */
    mathfieldRef?: (mathfield: Mathlive.Mathfield) => void;
}

interface ControlledProps extends BaseProps {
    latex: string;
    initialLatex?: undefined;
}

interface UncontrolledProps extends BaseProps {
    latex?: undefined;
    initialLatex: string;
}

export type Props = ControlledProps | UncontrolledProps;

/** A react-control that hosts a mathlive-mathfield in it. */
export class MathfieldComponent extends React.Component<Props> {
    private insertElement: HTMLElement | null = null;
    // private readonly combinedConfiguration = combineConfig(this.props);
    private mathfield: MathfieldElement | undefined;


    componentDidUpdate(prevProps: Props) {
        if (!this.mathfield) {
            throw new Error("Component was not correctly initialized.");
        }
        const tmpVal = this.mathfield.value;
        // if (this.props.latex !== prevProps.latex) {
        if (this.props.latex !== tmpVal) {
            this.mathfield.setValue(this.props.latex)
        }
        // minWidth: '300px'
    }

    render() {

        // const stylesEdit: React.CSSProperties = {
        //     border: '1px solid rgba(0, 0, 0, .3)',
        //     borderRadius: '4px',
        //     // minWidth: '50px'
        // }

        // const styles: React.CSSProperties = {
        //     // display: 'inline-block',
        //     // userSelect: 'element',
        //     ...(!this.props.readOnly && stylesEdit)
        // };
        return <Paper elevation={3} sx={{
            display: 'block',
            // overflow: 'hidden',
            // height: 'auto'
        }} ref={(instance) => (this.insertElement = instance)} > </Paper>

    }

    componentDidMount() {
        if (!this.insertElement) {
            throw new Error(
                "React did apparently not mount the insert point correctly."
            );
        } else if (!this.mathfield) {
            const initialValue = this.props.initialLatex ?? this.props.latex;
            const mathfield = new MathfieldElement({
                virtualKeyboards: 'numeric functions symbols roman greek',
                virtualKeyboardMode: 'manual',
                // readOnly: this.props.readOnly,
                // defaultMode: 'text'
            });
            // mathfield.style.display = 'inline';
            this.insertElement.appendChild(mathfield);
            mathfield.value = initialValue;

            if (this.props.mathfieldRef) {
                this.props.mathfieldRef(mathfield);
            }
            mathfield.addEventListener('input', (ev: any) => {
                // `ev.target` is an instance of `MathfieldElement`
                if (this.props?.onChange) {
                    this.props.onChange(ev.target.value);
                }
            });
            if (!this.props.readOnly) {
                setTimeout(() => {
                    mathfield.focus();
                })
            }
            this.mathfield = mathfield;
        }



    }
}