import { Box, Button } from "@mui/material";
import React, { useCallback } from "react";
// import { insertImage, validURL } from "./img";
import ImageIcon from '@mui/icons-material/Image';
import { shrinkImage } from "../img";
// import { insertImage, shrinkImage } from "../img";


export interface ImagePickProps {
    url: string;
    setUrl: (x: string) => void;
}
export const ImagePick = (props: ImagePickProps) => {

    const { setUrl } = props;
    const inputFileRef = React.useRef<HTMLInputElement>(null);
    const onBtnClick = useCallback(() => {
        /*Collecting node-element and performing click*/
        inputFileRef.current?.click();
    }, [inputFileRef]);
    const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
        (e) => {
            if (e.target.files) {
                let imageFile = e.target.files[0];
                var reader = new FileReader();
                reader.onload = function (eReader) {
                    if (eReader.target) {
                        var img = document.createElement("img");
                        img.onload = function (event) {

                            const dataUrl = shrinkImage(img, imageFile)
                            setUrl(dataUrl || '');
                            // clears
                            e.target.value = '';
                        }
                        img.src = eReader.target.result as string;

                    }
                }
                reader.readAsDataURL(imageFile);
            }
        }, [setUrl])

    return (
        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Button onClick={onBtnClick}>
                <input type="file" ref={inputFileRef} accept="image/*" name="image" id="file"
                    onChange={onChange}
                    style={{ display: 'none' }}></input>
                <ImageIcon />
                Pick Image
            </Button>
        </Box>

    )
}
