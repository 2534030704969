import { Box, IconButton, SelectChangeEvent, TextField } from "@mui/material";
import React from "react";
import { COLOR_BLACK, FnPoint } from "../../../../chart/base/chart.interface";
import { MultyInput } from "../../edit/MultyInput";
import DeleteIcon from '@mui/icons-material/Delete';


interface ChartPointItemInputProps {
    value: FnPoint;
    onChange: (data: FnPoint) => void;
    onRemove: () => void;
}


export default function ChartPointItemInput(props: ChartPointItemInputProps) {
    const { value, onChange, onRemove } = props;

    const changePointValue = React.useCallback((point: number[], name: any) => {
        const newstateText = Object.assign({}, value)
        newstateText.position = point;
        onChange(newstateText)
    }, [value, onChange])



    const handleChangeEvent = (event: SelectChangeEvent | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newInnerValue = Object.assign<any, any>({}, value);
        const name = event.target.name;
        newInnerValue[name] = event.target.value;
        onChange(newInnerValue);
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <MultyInput name="position" label={['x', 'y']} value={value.position} onChange={changePointValue}></MultyInput>
            <TextField
                name="color"
                type="color"
                InputProps={{
                    inputProps: {
                        max: 100, min: -100
                    }
                }}
                sx={{ width: '60px', m: '5px' }}
                label={"Color"} value={value.color || COLOR_BLACK} onChange={handleChangeEvent} variant="outlined" />
            <IconButton aria-label="delete"
                onClick={onRemove}>
                <DeleteIcon color="error" />
            </IconButton>
        </Box>

    );
}
