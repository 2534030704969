import * as React from 'react';
// import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
// import TinyMCE from '../shared/tiny/TinyMCE';
import { TransitionProps } from '@mui/material/transitions';
import {
    TextField, Dialog, Button,
    AppBar as MuiAppBar, Toolbar, Typography, MenuItem, styled, DialogTitle, DialogContent, AppBarProps
} from '@mui/material';
import { Box } from '@mui/system';
// import MyEditor from './editor/Editor';
import { SladeMEditor } from '../SlateMEditor/SlateMEditor';
import { BreadcrumbsData } from '../../state/utility';
import { MyBreadCrumbs } from '../breadcrumbs/MyBreadcrumbs';
import { MathMLTitle } from '../math-ml-content/math-ml-content';
import { getTopicCategoryName, TopicCategory } from '../../state/services/type';
import { selectUser } from '../../state/features/auth/authSlice';
import { useAppSelector } from '../../state/hooks';
// const HTML_FUNC_REG = /(\$[^\n$]+?\$)/g;



const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme}) => ({
    background: theme.palette.mode === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[900],
}));

export const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

export interface EditData {
    //
    title: string;
    content: string;
    description?: string;
    category?: TopicCategory;
    //
    isTopic?: boolean;
    isCreate?: boolean;
}

interface EditDialogMLProps {
    saveEditData: (content: EditData) => void;
    cancel: () => void;
    data: EditData;
    breadcrumbs: BreadcrumbsData[];
    open: boolean;
}
export default function EditDialogML(props: EditDialogMLProps) {

    const [tmpTitle, setTmpTitle] = React.useState('');
    const user = useAppSelector(selectUser);

    const [tmpCategory, setTmpcategory] = React.useState(TopicCategory.Calculus);
    const [content, setContent] = React.useState<string>('');
    const [editedContent, setEditedContentContent] = React.useState<string>('');

    const { saveEditData, data, breadcrumbs, open, cancel } = props;

    const invalidLength = !!(user && editedContent.length > user?.tier_chapter_size);

    const handletitleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setTmpTitle(e.target.value);
    };

    const handleCatagoryChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setTmpcategory(parseInt(e.target.value));
    };

    const handleSave = React.useCallback(() => {
            saveEditData(Object.assign(data, {
                content: editedContent,
                title: tmpTitle,
                category: tmpCategory
            }));
    }, [data, tmpTitle, editedContent, tmpCategory, saveEditData]);

    React.useEffect(() => {
        setTmpTitle(data.title);
        setContent(data.content);
        setEditedContentContent(data.content);
        setTmpcategory(data.category || TopicCategory.Calculus);
    }, [setTmpTitle, setContent, setEditedContentContent, setTmpcategory, data]);

    const categories = Object.keys(TopicCategory).map((item) => {
        const catNum = Number(item);
        return !isNaN(catNum) ? catNum : -1;
    }).filter(x => x !== -1);

    return (
        <Dialog
            fullScreen={true}
            open={open}
            onClose={cancel}
            TransitionComponent={Transition}
        >
            <DialogTitle sx={{ p: 0 }}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 0, flex: 1 }} noWrap variant="caption" fontSize={25}>
                            <MathMLTitle content={tmpTitle || 'No Title'} />
                        </Typography>
                        <Button color="error" variant="contained" onClick={cancel}>
                            Cancel
                        </Button>
                        <Button color="success" variant="contained" sx={{ marginLeft: '10px' }} onClick={handleSave}>
                            Save
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box sx={{
                    position: 'absolute',
                    right: '25px', backgroundColor: 'transparent',
                    color: invalidLength ? 'red' : 'green'
                }}>
                    <Typography variant="overline" noWrap gutterBottom >
                        {editedContent.length}/{user?.tier_chapter_size}
                    </Typography>
                </Box>

            </DialogTitle>

            <DialogContent sx={{
                backgroundColor: (theme) => (theme.palette.mode === 'light'
                    ? theme.palette.grey[200]
                    : theme.palette.grey[900]
                ),
                display: "flex",
                p: 0
            }}>
                <Offset />
                <Box sx={{ padding: { sx: '0px 2%', md: '0px 15%' }, flexGrow: 1, position: 'relative', overflow: 'auto' }}>
                    <Box sx={{ padding: '10px' }}>
                        <MyBreadCrumbs data={data.isTopic ? [] : breadcrumbs} newItem={data.isCreate ? tmpTitle : undefined} />
                    </Box>
                    <Box sx={{ m: '10px', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <TextField sx={{ minWidth: '300px', width: '30%' }} label="Title"
                            variant="standard" value={tmpTitle}
                            onChange={handletitleChange}
                            helperText={data.isTopic ? 'Topic Title' : 'Chapter Title'}
                            autoFocus
                        />
                        {
                            data.isTopic && <TextField sx={{ minWidth: '300px', width: '30%', marginLeft: '5px' }}
                                select
                                label="Select Category"
                                value={tmpCategory}
                                onChange={handleCatagoryChange}
                                helperText="Select Topic Category"
                                variant="standard"
                            >
                                {categories.map((c) => (
                                    <MenuItem key={c} value={c}>
                                        {getTopicCategoryName(c)}
                                    </MenuItem>
                                ))}
                            </TextField>
                        }

                    </Box>
                    <SladeMEditor inputValue={content} onChange={setEditedContentContent} ></SladeMEditor>
                </Box>
            </DialogContent>
        </Dialog >
    );
}
