import { ChartText, parseColor, SPLITTER_PARAMS } from "../chart.interface";


const fixText = (x: string) => x
    .replaceAll(SPLITTER_PARAMS, ' ')
    .replaceAll('|', '\\|')
    .replaceAll('$', ' ')
    .replaceAll('"', ' ')
    .replaceAll('>', ' ')
    .replaceAll('<', ' ')

export const parseText = {

    stringifyText: (p: ChartText) => {
        const color = p.color ? `${SPLITTER_PARAMS}${p.color}` : '';
        const text = fixText(p.text);
        const position = p.position.join(SPLITTER_PARAMS)
        return ` text | ${text}${SPLITTER_PARAMS}${position}${color}`;
    },

    parseText: (partType: string, value: string): ChartText | null => {
        switch (partType) {
            case 'text':
                const parts = value.split(SPLITTER_PARAMS)
                const text = (parts[0] || '').trim();
                const position = [parseFloat(parts[1]) || 0, parseFloat(parts[2]) || 0];
                const color = parseColor(parts[3]);
                const transform = undefined//parts[4] ? parts[4].trim() : undefined;
                return {
                    text,
                    position,
                    color,
                    transform
                }
            default:
                return null
        }
    }
}