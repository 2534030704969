import { Link } from "@mui/material";
import React, { memo } from "react";
import { useSelected } from "slate-react"


// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
export const InlineChromiumBugfix = () => (
    <span
        contentEditable={false}
        style={{ fontSize: 0 }}
    >
        ${String.fromCodePoint(160) /* Non-breaking space */}
    </span>
)

export const LinkComponent = memo(({ attributes, children, element, readOnly }: any) => {
    const selected = useSelected()
    let style: React.CSSProperties = { boxShadow: undefined, }
    if (selected) {
        style = { boxShadow: '0 0 0 3px #ddd' };
    }
    return (
        <Link
            {...attributes}
            href={element.url}
            style={style}
            target="_blank"
            rel="noreferrer"
            underline="hover"
        >
            <InlineChromiumBugfix />
            {children}
            <InlineChromiumBugfix />
        </Link >
    )
})