import * as React from 'react';
import { Avatar, Box, Button, Card, CardActions, CardContent, Divider, Typography } from '@mui/material';
import { useAppSelector } from '../../../state/hooks';
import { selectUser } from '../../../state/features/auth/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import { useModal } from '../../../context/modal-context';
import { useDeleteAccountMutation } from '../../../state/services/mathapi';
import { toast } from 'react-toastify';
import { revokeGoogle } from '../../google/google';

export default function UserProfile() {
    const user = useAppSelector(selectUser);
    const [deleteAccount, { isLoading }] = useDeleteAccountMutation();
    const { openModal } = useModal();
    const navigate = useNavigate();

    const usertier = {
        title: 'Free',
        price: '0',
        quotas: [
            `Up to ${user?.tier_topics} documents`,
            `Up to ${user?.tier_chapters} chapters per document`,
            `Up to ${user?.tier_chapter_size} chars per chapter`,
            `No Email support`,
        ]
    };

    const handleDeleteAccount = () => {
        openModal({
            title: 'Delete Account',
            message: <Box>
                <Typography variant='subtitle2'>Are you sure to delete your account?</Typography>
                <Typography variant='caption'>We will delete all of your documents and all account data</Typography>
            </Box>,
            onClose: async (x) => {
                if (x) {
                    toast('Deleting Account!', { type: 'warning' });
                    await deleteAccount();
                    revokeGoogle(user?.email || '');
                    toast('Deleted Account!', { type: 'success' });
                    navigate('/');
                }
            }
        });
    };
    if (user)
        return (

            <Card>
                <CardContent>
                    <Box sx={{ m: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                        <Avatar sx={{ width: 56, height: 56 }} alt={user.name} src={user?.picture} />
                        <Typography variant='h4'>
                            {user.name}
                        </Typography>
                        <Typography variant='caption'>
                            {user.email}
                        </Typography>
                        <Button
                            variant={'outlined'}
                            color="error"
                            onClick={handleDeleteAccount}
                            disabled={isLoading}
                        >
                            Delete Account
                        </Button>
                    </Box>
                    <Divider></Divider>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'baseline',
                            mb: 2, mt: 2, borderRadius: 3,
                            p: 1,

                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[200]
                                    : theme.palette.grey[700],
                        }}
                    >
                        <Typography
                            variant="h6"
                            align="center"
                        >{user.tier_name}</Typography>
                    </Box>
                    <ul>
                        {usertier.quotas.map((line) => (
                            <Typography
                                component="li"
                                variant="subtitle1"
                                align="center"
                                key={line}
                            >
                                {line}
                            </Typography>
                        ))}
                    </ul>
                </CardContent>
                <CardActions>
                    <Button
                        fullWidth
                        variant={'contained'}
                        color="info"
                        component={Link} to="/billing"
                    >
                        Update tier
                    </Button>
                </CardActions>
            </Card>

        );
    return null;

}
