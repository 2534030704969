import * as React from 'react';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import { Box, Button, Divider } from '@mui/material';
import { Topic } from '../../state/services/type';
import { useNavigate } from 'react-router-dom';
import { ChangeOrderFn } from '../topic-item/OrderDialog';
import ContentListItem from './ContentListItem';

interface ContentListProps {
    topic: Topic;
    orderEdit?: ChangeOrderFn;
}
export const ContentList = React.memo((props: ContentListProps) => {

    const { topic, orderEdit } = props;
    const navigate = useNavigate();
    const handleClick = React.useCallback(() => {
        navigate(`/topic/${topic.id}`)
    }, [navigate, topic]);

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    }}>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Button size="large" sx={{ fontSize: 20 }} onClick={handleClick}>
                {topic.title}
            </Button>
            <Divider />
        </Box>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflow: 'auto'
        }}>

            <Collapse in={true} timeout="auto" unmountOnExit>
                <List>
                    {topic.children.map((item) => (
                        <ContentListItem orderEdit={orderEdit} key={item.id} topic={topic} item={item} level={0}></ContentListItem>
                    ))}
                </List>
            </Collapse>
        </Box>
    </Box >
});
