import React, { memo } from "react";
import Prism from 'prismjs'
import 'prismjs/components/prism-python'
import 'prismjs/components/prism-php'
import 'prismjs/components/prism-sql'
import 'prismjs/components/prism-java'
import 'prismjs/components/prism-typescript'
import 'prismjs/components/prism-haskell'
import 'prism-themes/themes/prism-a11y-dark.css';
import {
    Editor, Range,
    Element as SlateElement,
    NodeEntry,
    Node,
    // Text,
} from "slate";
// import './a11y-dark.css' // copied from highlight.js!!
import './code-my.css' 
import 'prism-themes/themes/prism-a11y-dark.css'


Prism.languages.python = Prism.languages.extend('python', {})
Prism.languages.insertBefore('python', 'prolog', {
    comment: { pattern: /##[^\n]*/, alias: 'comment' },
})
Prism.languages.javascript = Prism.languages.extend('javascript', {})
Prism.languages.insertBefore('javascript', 'prolog', {
    comment: { pattern: /\/\/[^\n]*/, alias: 'comment' },
})

Prism.languages.typescript = Prism.languages.extend('typescript', {})
Prism.languages.insertBefore('typescript', 'prolog', {
    comment: { pattern: /\/\/[^\n]*/, alias: 'comment' },
})
Prism.languages.haskell = Prism.languages.extend('haskell', {})
Prism.languages.insertBefore('haskell', 'prolog', {
    comment: { pattern: /\/\/[^\n]*/, alias: 'comment' },
})
Prism.languages.html = Prism.languages.extend('html', {})
Prism.languages.insertBefore('html', 'prolog', {
    comment: { pattern: /<!--[^\n]*-->/, alias: 'comment' },
})

export const CodehHighlightElement = memo(({ attributes, children, element }: any) => {


    return (<pre {...attributes} style={{ padding: '10px', overflow: 'auto' }} className="language-haskell">
        <code>
            {children}
        </code>
    </pre>
    );
});


export const decorate = ([node, path]: NodeEntry<Node>) => {
    const ranges: Range[] = [];

    const isPre = !Editor.isEditor(node) &&
        SlateElement.isElement(node) &&
        node['type'] === 'pre';
    // const isCode = !Editor.isEditor(node) &&
    //     Text.isText(node) &&
    //     node['code'];

    if (isPre) {
        let text = node.children.map(x => x.text).join('');
        //
        const tokens = Prism.tokenize(text, detecLanguage(text));
        let start = 0
        // console.log(text)
        // console.log(tokens)
        for (const token of tokens) {
            const length = getLength(token)
            const end = start + length

            if (typeof token !== 'string') {
                const tokenName: string = 'token';
                ranges.push({
                    [tokenName]: token.type,
                    anchor: { path, offset: start },
                    focus: { path, offset: end },
                })
            }

            start = end
        }

        return ranges
    }
    return ranges;


}

function detecLanguage(text: string) {


    const htmlReg = new RegExp('^<?(\\w+)[^>]*>.*</\\w+>$');

    if (htmlReg.test(text)) {
        return Prism.languages.html;
    }
    return Prism.languages.haskell;
}



const getLength = (token: any) => {
    if (typeof token === 'string') {
        return token.length
    } else if (typeof token.content === 'string') {
        return token.content.length
    } else {
        return token.content.reduce((l: any, t: any) => l + getLength(t), 0)
    }
}


