import {
    MiddlewareAPI,
    isRejectedWithValue,
    Middleware,
} from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import { logout } from '../features/auth/authSlice';

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
        if (isRejectedWithValue(action)) {
            const data = action.payload?.data;

            const errorMSG = data?.errorMessage;
            console.error(errorMSG)
            console.log(action)
            // check auth
            if (action.payload?.status === 401) {
                // silent logout
                // console.log("!!!!")
                api.dispatch(logout());
            } else {
                if (data?.errorsValidation?.length) {
                    const msgs = data?.errorsValidation.map(getValMsg);
                    toast.error(`Validation Error:${msgs}!`);
                } else {
                    toast.error(`API Error:${errorMSG || 'Server Error'}!`);
                }
            }
            const newAction = Object.assign(action, { payload: { errorMSG } })// fix for error!
            return next(newAction);
        }
        return next(action);
    }

function getValMsg(obj: any) {
    console.log(obj)
    return Object.keys(obj).map(key => {
        return `${key}:${obj[key]}`;
    }).join();

}