import {
    Editor
} from 'slate'
import { CustomEditor, InputDialogData, MyEditor } from '../slate.inteface';
import { getCopyPastedEditor } from '../../copyPasteEditorText';
import { withVoidBehavior } from './withVoidBehavior';
import { checkInsideElement, insertHtmlString } from '../core/helpers';



export const withMyEditor = (editor: CustomEditor, setDialogEditData: (x: InputDialogData) => void) => {
    const { insertData, isInline, isVoid } = editor

    editor.isInline = element =>
        ['link', 'mathinline'].includes(element.type) || isInline(element);


    editor.isVoid = element =>
        ['chart', 'image', 'mathblock', 'mathinline'].includes(element.type) || isVoid(element);



    editor.insertData = data => {
        const html = data.getData('text/html');
        // first html
        if (checkInsideElement(editor, 'pre')) {
            // don't insert html in <pre>
            const text = data.getData('text');
            Editor.insertText(editor, text);
            return;
        }
        if (html) {
            try {
                insertHtmlString(html, editor);
                return;
            } catch (e) {
                console.error(e)
                // continue insert text!!
            }
        }
        // editor copy/paste
        const text = data.getData('text');

        const dataCP = getCopyPastedEditor();
        if (dataCP?.text === text) {
            // copy html instead!
            try {
                insertHtmlString(dataCP.html, editor);
                return
            } catch (e) {
                console.error(e)
                // continue insert text!!
            }
        }

        insertData(data);
    }

    const myEditor = editor as MyEditor;

    myEditor.editMath = (x: InputDialogData) => {
        setDialogEditData(x);
    }

    return withVoidBehavior(myEditor)
}


