import * as React from 'react';
import { alpha, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment, InputBase, styled, TextField, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from '../../helpers';



const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.2),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.35),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

export const SearchBtn = () => {
    const [open, setOpen] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const nav = useNavigate();

    const handleClickOpen = React.useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const handleSearchNav = React.useCallback(() => {
        if (search) {
            nav(`/search?q=${search}`);
            setOpen(false)
        } else {
            // nav(`/`);
        }
    }, [nav, search]);

    const handleClose = React.useCallback(() => {
        setOpen(false);
    }, []);

    const handleKeyPress: React.KeyboardEventHandler<HTMLDivElement> = React.useCallback((e) => {
        console.log(e)
        if (e.code === 'Enter') {
            handleSearchNav()
        }
    }, [handleSearchNav]);



    const handleSearch = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearch(value);
    }, [setSearch]);


    return <>
        <Tooltip title="Search..."><Button variant="outlined" onClick={handleClickOpen} sx={{
            borderColor: 'transparent',
            color: '#a3a316',
            '&:hover': {
                borderColor: 'gray',
            },

        }}>
            <SearchIcon />
        </Button></Tooltip>
        <Dialog open={open}
            maxWidth={'xl'}
            // fullWidth={true}
            onClose={handleClose}>
            <DialogTitle>Search Topics</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {/* To subscribe to this website, please enter your email address here. We
                    will send updates occasionally. */}
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="text"
                    // label="Search ..."
                    placeholder="Search…"
                    type="email"
                    fullWidth
                    onChange={handleSearch}
                    onKeyPress={handleKeyPress}
                    variant="standard"
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="error">Cancel</Button>
                <Button onClick={handleSearchNav} color="primary"
                    disabled={!search}
                >Search</Button>
            </DialogActions>
        </Dialog></>
}
export default function SearchInput() {
    const nav = useNavigate();

    const [search, setSearch] = React.useState('');

    const handleSearchNav = React.useCallback((search: string) => {
        if (search) {
            nav(`/search?q=${search}`);
        } else {
            nav(`/`);
        }
    }, [nav]);

    // eslint-disable-next-line
    const debounceSearch = React.useCallback(debounce((q: string) => {
        handleSearchNav(q);
    }, 200), [handleSearchNav])

    const handleSearch = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearch(value);
        debounceSearch(value);
    }, [setSearch, debounceSearch]);



    return (
        <Search sx={{ mr: 5 }} >
            <SearchIconWrapper>
                <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
                placeholder="Search…"
                value={search}
                onChange={handleSearch}
                inputProps={{ 'aria-label': 'search' }}
            />
        </Search>
    );
}

