import * as React from 'react';
import Settings from '@mui/icons-material/Settings';
import ArrowRight from '@mui/icons-material/ArrowRight';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import KeyboardDoubleArrowUpOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowUpOutlined';
import { IconButton, Tooltip } from '@mui/material';


export type BtnsActions = 'up' | 'up-level' | 'down' | 'down-level' | 'settings';
export interface BtnActionItem {
    type: BtnsActions;
    handler: () => void;
    disabled: boolean;
};

interface ContentActionsProps {
    btns: BtnActionItem[];
}

export function ContentActions(props: ContentActionsProps) {

    const btns = props.btns;

    const all_btns = btns.map((btn, i) => {
        const svg_conf = getSVGTrasform(btn.type);
        const icons = getIcons(btn.type);

        // span needed for disabled tooltip on btn
        return <Tooltip key={i} title={btn.type}>
            <span style={{ marginLeft: !i ? '5px' : undefined }}>
                <IconButton
                    size="large"
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        btn.handler()
                        return false;
                    }}
                    disabled={btn.disabled}
                    color='primary'
                    sx={{
                        '& svg': {
                            transition: '0.2s',
                            transform: svg_conf.transform,
                        },
                        '&:hover, &:focus': {
                            bgcolor: 'unset',
                            '& svg:first-of-type': {
                                transform: svg_conf.hover_transform,
                            },
                            '& svg:last-of-type': svg_conf.hover_lastOfType,
                        },
                        '&:after': {
                            content: '""',
                            position: 'absolute',
                            height: '80%',
                            display: 'block',
                            left: 0,
                            width: '1px',
                            bgcolor: 'divider',
                        },
                    }}
                >
                    {icons}
                </IconButton>
            </span>
        </Tooltip >
    });
    return <>{all_btns}</>
}


function getSVGTrasform(t: BtnsActions) {
    switch (t) {
        case 'down':
        case 'down-level':
            return {
                transform: 'translateY(0)',
                hover_transform: 'translateY(-4px)',
                hover_lastOfType: {
                    bottom: 0,
                    opacity: 1
                }
            }
        case 'up':
        case 'up-level':
            return {
                transform: 'translateY(0)',
                hover_transform: 'translateY(4px)',
                hover_lastOfType: {
                    top: 0,
                    opacity: 1
                }
            }

        default:
            return {
                transform: 'translateX(0) rotate(0)',
                hover_transform: 'translateX(-4px) rotate(-20deg)',
                hover_lastOfType: {
                    right: 0,
                    opacity: 1
                }
            };
    }
}

function getIcons(t: BtnsActions) {
    switch (t) {
        case 'down':
            return <>
                <ArrowCircleDownIcon />
                <ArrowDropDown sx={{ position: 'absolute', bottom: 4, opacity: 0 }} />
            </>
        case 'down-level':
            return <>
                <KeyboardDoubleArrowDownOutlinedIcon />
                <ArrowDropDown sx={{ position: 'absolute', bottom: 4, opacity: 0 }} />
            </>
        case 'up':
            return <>
                <ArrowCircleUpIcon />
                <ArrowDropUp sx={{ position: 'absolute', top: 4, opacity: 0 }} />
            </>
        case 'up-level':
            return <>
                <KeyboardDoubleArrowUpOutlinedIcon />
                <ArrowDropUp sx={{ position: 'absolute', top: 4, opacity: 0 }} />
            </>

        default:
            // settings            
            return <>
                <Settings />
                <ArrowRight sx={{ position: 'absolute', right: 4, opacity: 0 }} />
            </>
    }
}