import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useAppSelector } from '../../state/hooks';
import { selectUser } from '../../state/features/auth/authSlice';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { useApplyTierMutation } from '../../state/services/mathapi';
import { toast } from 'react-toastify';

const tiers = [
    {
        title: 'Free',
        price: '0',
        quotas: [
            '20 documents',
            '20 chapters/doc',
            '5000 chars/chapter',
            'No Email support',
        ],
        buttonText: 'Sign up for free',
        buttonVariant: 'outlined',
    },
    {
        title: 'Pro',
        price: '0',
        quotas: [
            '100 documents',
            '50 chapters/doc',
            '40000 chars/chapter',
            'No Email support',
        ],
        buttonText: 'Get started',
        buttonVariant: 'contained',
    },
    {
        title: 'Enterprise',
        price: '0',
        quotas: [
            '10000 documents',
            '200 chapters/doc',
            '10000 chars/chapter',
            'Email support',
        ],
        buttonText: 'Contact us',
        buttonVariant: 'outlined',
    },
];

export function MyTiers() {
    const user = useAppSelector(selectUser);

    const [open, setOpen] = React.useState(false);
    const [tier, setTier] = React.useState('');
    const [msg, setMsg] = React.useState('');
    const [applyTier, { isLoading }] = useApplyTierMutation();
  
    const handleClickOpen = (tier: string) => {
        setTier(tier);
        setOpen(true);
    };

    const handleApplyClose = async () => {
        toast.info(`Sending request`);
        //
        await applyTier({ tier, msg });
        toast.success(`Applied`);
        //
        handleClose();
    };

    const handleClose = () => {
        setOpen(false);
        setMsg('');
    };


    
    const usertier = user?.tier_name.toLowerCase();
    const appliedtierName = user?.tier_apply && user.tier_apply.split(' ')[0];

    return (
        <Container maxWidth="md" sx={{ mt: 1 }}>
            <Grid container spacing={5} alignItems="flex-end">
                {tiers.map((tier) => (
                    // Enterprise card is full width at sm breakpoint
                    <Grid
                        item
                        key={tier.title}
                        xs={12}
                        sm={6}
                        md={4}
                    >
                        <Card>
                            <CardHeader
                                title={tier.title}
                                subheader={tier.title.toLowerCase() === usertier && 'Active'}
                                titleTypographyProps={{ align: 'center' }}
                                action={tier.title.toLowerCase() === usertier ? <StarIcon /> : null}
                                subheaderTypographyProps={{
                                    align: 'center',
                                }}
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'light'
                                            ? theme.palette.grey[200]
                                            : theme.palette.grey[700],
                                }}
                            />
                            <CardContent>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'baseline',
                                        mb: 2,
                                    }}
                                >
                                    <Typography component="h2" variant="h3" color="text.primary">
                                        ${tier.price}
                                    </Typography>
                                    <Typography variant="h6" color="text.secondary">
                                        /mo
                                    </Typography>
                                </Box>
                                <ul>
                                    {tier.quotas.map((line) => (
                                        <Typography
                                            component="li"
                                            variant="subtitle1"
                                            align="center"
                                            key={line}
                                        >
                                            {line}
                                        </Typography>
                                    ))}
                                </ul>
                            </CardContent>
                            <CardActions>
                                <Button
                                    fullWidth
                                    disabled={tier.title.toLowerCase() === usertier || (appliedtierName?.toLowerCase() === tier.title.toLowerCase())}
                                    variant={tier.title.toLowerCase() === usertier ? 'contained' : 'contained'}
                                    onClick={() => handleClickOpen(tier.title)}
                                >
                                    {tier.title.toLowerCase() === usertier ? 'Active' : (appliedtierName?.toLowerCase() === tier.title.toLowerCase()) ? 'Pending' : 'Apply'}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Update Plan - {tier}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To update plan we need more info about your requirements.
                    </DialogContentText>

                    <TextField
                        autoFocus
                        margin="dense"
                        label="Request"
                        placeholder="Please write reason for update"
                        type="text"
                        fullWidth
                        variant="standard"
                        multiline
                        value={msg}
                        disabled={isLoading}
                        onChange={(e) => { setMsg(e.target.value) }}
                    />
                </DialogContent>
                <DialogActions>
                    {isLoading && <CircularProgress />}
                    <Button onClick={handleClose} disabled={isLoading} color="error" >Cancel</Button>
                    <Button onClick={handleApplyClose} disabled={isLoading || !msg} color="primary">Apply</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}
