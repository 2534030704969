import { Accordion, AccordionSummary, Button, Card, CardContent, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ContentItem as ItemData, getTopicCategoryName, Topic } from '../../state/services/type';
import ContentItem from './ContentItem';
import { EditData } from './EditDialogML';
import { MathMLTitle } from '../math-ml-content/math-ml-content';
import { BreadcrumbsData, Util } from '../../state/utility';
import { useSelector } from 'react-redux';
import { selectUser } from '../../state/features/auth/authSlice';
import { useModal } from '../../context/modal-context';
import EditBtn, { EditBtnType } from './EditBtn';
import { MyBreadCrumbs } from '../breadcrumbs/MyBreadcrumbs';
import { SladeMEditor } from '../SlateMEditor/SlateMEditor';
import { appLinkStyles } from '../helpers';


interface TopicItemProps {
    topic: Topic;
    item: ItemData | undefined;
    handleEdit: (x: EditData) => void;
    handleOrder: () => void;
    handlePublic: () => void;
    remove: (item_id?: string) => void;
}
function TopicItem(props: TopicItemProps) {

    const { topic, item, handleEdit, handleOrder, remove, handlePublic } = props;

    const { openModal } = useModal();
    const user = useSelector(selectUser);

    const isEditable = user?.id === topic.user_id || user?.role === 'ADMINISTRATOR';

    const [breadCRB, setBreadCRB] = useState<BreadcrumbsData[]>([]);


    const items = item ? item.children : topic.children || [];

    const initContent: string = item ? item.content : topic.content;
    const initCTitle: string = item ? item.title : topic.title;
    const initCategory = item ? undefined : topic.category;
    const isTopic = !item;

    const handleAction = React.useCallback((type: EditBtnType) => {
        switch (type) {
            case 'edit':
                handleEdit({
                    isTopic: isTopic,
                    content: initContent,
                    title: initCTitle,
                    category: initCategory
                });
                break;
            case 'order':
                handleOrder();
                break;
            case 'delete':
                openModal({
                    title: 'Delete content',
                    message: `Are you sure to delete this content?`,
                    onClose: (x) => {
                        if (x)
                            remove(item?.id);
                    }
                });
                break;
            case 'public':
                handlePublic();
                break;
            case 'new_chapter':
                handleEdit({
                    isCreate: true,
                    content: '',
                    title: 'New Chapter'
                });
                break;
            default:
                break;
        }

    }, [handleEdit, isTopic, initCTitle, initContent, initCategory, remove, item, openModal, handleOrder, handlePublic]);


    const handleClickCreate = React.useCallback(() => {
        handleEdit({
            isCreate: true,
            content: '',
            title: 'New Chapter'
        });
    }, [handleEdit]);

    useEffect(() => {
        if (topic) {
            setBreadCRB(Util.getBreadcrumbs(topic, item));
        }
    }, [setBreadCRB, topic, item])


    const prefix = Util.getPrefixDisplay(topic, item);

    return (
        <>

            <Box
                sx={{
                    width: '100%',
                    position: 'sticky'
                }}
            >
                <MyBreadCrumbs data={breadCRB} link={true} />
            </Box>


            <Card sx={{ minWidth: 275 }}>
                <CardContent>
                    <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                        <Typography sx={{ flex: '1', ...appLinkStyles, mb: 0 }} variant="h5" component="div">
                            {prefix} &nbsp;
                            <MathMLTitle content={initCTitle}></MathMLTitle>
                        </Typography>
                        <EditBtn onChange={handleAction} topic={topic} item={item} />
                    </Box>

                    <Divider variant="fullWidth" textAlign='right' >
                        <Typography variant="caption">
                            {`${getTopicCategoryName(topic.category)}`}
                        </Typography>
                    </Divider>

                    <SladeMEditor inputValue={initContent} readOnly={true}></SladeMEditor>
                </CardContent>
            </Card>

            <AllItems topic={topic} items={items}></AllItems>

            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={false} sx={{ display: isEditable ? 'block' : 'none' }}>
                <AccordionSummary
                    expandIcon={null}
                    aria-controls="panel1a-content"
                >
                    <Box sx={{
                        display: 'flex',
                        justifyContent: "center",
                        width: '100%'
                    }}
                    >
                        <Button size="small" variant="outlined" onClick={handleClickCreate} >Add NEW CHAPTER</Button>
                    </Box>
                </AccordionSummary>
            </Accordion >
        </>
    );
}

interface AllItemsProps {
    items: ItemData[];
    topic: Topic;

}


const AllItems = React.memo((props: AllItemsProps) => {
    const { items, topic } = props;
    let navigate = useNavigate();
    return <>
        {items?.map((x, i) => (<ContentItem key={`${x.id}`} item={x} topic={topic} onClickView={() => {
            navigate(`/topic/${topic.id}/${x.id}`);
        }}></ContentItem>))}
    </>
});
export default TopicItem;

