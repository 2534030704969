import React, { useEffect } from "react";
import { ChartElement, CustomElement, ElementActions } from "../slate.inteface";

import { parseFnProps, stringifyChart } from "../../chart/base/parseRaw";
import { ChartMainProps } from "../../chart/base/chart.interface";
import { BaseChartElement } from "../../math-ml-content/math-ml-content";
import { ChartEditInput } from "./edit/ChartEditInput";


interface MathElementEditProps {
    action: ElementActions;
    editElement: CustomElement | null;
    onChange: (data: CustomElement) => void;
}
const isChart = (mathElement: CustomElement | null) => mathElement?.type === 'chart';

const initData: ChartMainProps = {
    domain: [100, 100],
    codomain: [100, 100],
    fns: [],
    points: [],
    text: []
}
export default function ChartElementEdit(props: MathElementEditProps) {
    const { editElement, onChange } = props;
    const [chartData, setChartData] = React.useState<ChartMainProps>(initData);


    useEffect(() => {
        const cElement = (editElement as ChartElement);
        if (isChart(cElement)) {
            const chartProps = parseFnProps(cElement.data) || initData
            setChartData(chartProps)

        }
    }, [editElement, setChartData]);


    const setNewChartData = React.useCallback((newVal: ChartMainProps) => {
        const newstate = Object.assign({}, newVal);
        const newdata: CustomElement = { type: 'chart', data: stringifyChart(newstate), children: [{ text: '' }] };
        setChartData(newVal)
        onChange(newdata);
    }, [onChange, setChartData]);


    if (!isChart(editElement)) {
        return null;
    }

    return (<>
        <div>
            {/* <div>
                <pre>{(editElement as ChartElement).data}</pre>
                <pre>{stringifyChart(chartData)}</pre>
            </div> */}
            <div style={stylesWrapper}>
                <div style={{ width: '40%' }}>
                    <BaseChartElement
                        width="90%"
                        data={chartData}
                    />
                </div>
                <div style={{ background: '#e9eef7', overflow: 'auto', flexGrow: 1 }}>
                    <ChartEditInput value={chartData} onChange={setNewChartData}></ChartEditInput>
                </div>
            </div>
        </div>
    </>
    );
}
const stylesWrapper: React.CSSProperties = {
    border: '1px solid rgb(224 224 224)',
    display: 'flex',
    borderRadius: '5px'
}

