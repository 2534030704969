import {
  ChartFunction, ChartMainProps, ChartText, FnPoint,
  FUNC_KEY, FUNC_REG, HTML_FUNC_REG, SPLITTER, SPLITTER_PARAMS
} from "./chart.interface";

import { parseText } from "./parse.ts/parseText";
import { parsePoint } from "./parse.ts/parsePoint";
import { parseFn } from "./parse.ts/parseFn";



export function getHTMLParts(content: string) {
  return content.split(HTML_FUNC_REG);
}

const stryngifyPart = (x: string[]) => x.length ? ` | ${x.join(' | ')}` : ''
export const stringifyChart = (x: ChartMainProps) => {
  const domains = [...x.domain, ...x.codomain].join(SPLITTER_PARAMS);
  const points = stryngifyPart(x.points.map(parsePoint.stringifyPoint));
  const text = stryngifyPart(x.text.map(parseText.stringifyText));
  const fns = stryngifyPart(x.fns.map(parseFn.stringifyFn));

  return `{{ func | ${domains} ${points} ${text} ${fns} }}`;
}


export function parseFnProps(content: string): ChartMainProps | null {

  if (content.match(FUNC_REG)) {
    const parsetHtml = decodeHTMLEntities(content.slice(2, content.length - 2));
    const parts = parsetHtml.split(SPLITTER).map(x => x.replace(/\\\|/g, '|'));

    const partType = parts[0].trim();

    if (partType === FUNC_KEY) {
      const fnDomainCodomain = parts[1].trim().split(SPLITTER_PARAMS);
      if (fnDomainCodomain.length === 4) {
        const domain = [parseFloat(fnDomainCodomain[0]), parseFloat(fnDomainCodomain[1])];
        const codomain = [parseFloat(fnDomainCodomain[2]), parseFloat(fnDomainCodomain[3])];
        //
        const fns: ChartFunction[] = [];
        const points: FnPoint[] = [];
        const text: ChartText[] = [];

        let nextPartIndex = 2;
        let nextPart = parts[nextPartIndex];
        while (nextPart) {
          nextPart = nextPart.trim();
          const nextvalue = parts[nextPartIndex + 1];
          if (nextvalue) {
            const fn = parseFn.parseFn(nextPart, nextvalue);
            const point = parsePoint.parsePoint(nextPart, nextvalue);
            const textTmp = parseText.parseText(nextPart, nextvalue);
            if (fn) {
              fns.push(fn);
            } else if (point) {
              points.push(point)
            } else if (textTmp) {
              text.push(textTmp)
            }
          }
          nextPartIndex += 2;
          nextPart = parts[nextPartIndex];
        }
        return {
          fns,
          codomain,
          domain,
          points,
          text
        }
      }
    }
  }
  return null;
}


function decodeHTMLEntities(text: string) {
  var entities = [
    ['amp', '&'],
    ['apos', '\''],
    ['#x27', '\''],
    ['#x2F', '/'],
    ['#39', '\''],
    ['#47', '/'],
    ['lt', '<'],
    ['gt', '>'],
    ['nbsp', ' '],
    ['quot', '"'],
    ['rsquo', `'`],
    ['ldquo', `'`]
  ];

  for (var i = 0, max = entities.length; i < max; ++i)
    text = text.replace(new RegExp('&' + entities[i][0] + ';', 'g'), entities[i][1]);

  return text;
}
