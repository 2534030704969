import { jsx } from 'slate-hyperscript'
import { ELEMENT_TAGS, TEXT_TAGS } from './elements';
import {
    Descendant,
    Text as SlateText,
} from 'slate'
import { ParagraphElement } from '../../slate.inteface';

const deserialize = (el: Node): any => {
    if (el.nodeType === 3) {
        return el.textContent
    } else if (el.nodeType !== 1) {
        return null
    } else if (el.nodeName === 'BR') {
        return '\n'
    }

    if (['MATH', 'MATHB', 'MI', 'FCHART'].includes(el.nodeName)) {
        const attrs = ELEMENT_TAGS[el.nodeName](el)
        return jsx('element', attrs, [{ text: '' }]);
    }

    const { nodeName } = el
    let parent = el



    const skipPRE = nodeName === 'PRE' && el.childNodes[0] && el.childNodes[0].nodeName === 'CODE';
    if (skipPRE
    ) {
        parent = el.childNodes[0]
    }
    let children = Array.from(parent.childNodes)
        .map(deserialize)
        .flat()

    if (nodeName === 'P') {
        //check if any children have "void" type! return void type
        const childVoid: any = children.find(x => x.type === 'chart' || x.type === 'mathblock');
        if (childVoid) {
            return childVoid;
        }
    }

    if (children.length === 0) {
        children = [{ text: '' }]
    }
    if (el.nodeName === 'BODY') {
        return jsx('fragment', {}, children)
    }

    if (ELEMENT_TAGS[nodeName]) {
        const attrs = ELEMENT_TAGS[nodeName](el)
        return jsx('element', attrs, children);
    }

    if (TEXT_TAGS[nodeName]) {
        const attrs = TEXT_TAGS[nodeName](el)
        return children.map(child => {
            // fix for math inline
            if (child.type === 'mathinline' || child.type === 'chart') {
                return child;
            } else {
                return jsx('text', attrs, child)
            }
        })
    }
    return children;


}



function fixHtml(html: String) {
    // console.log(html)
    let replacedText = html
        .replaceAll(/<h\d+?>(.*?)<\/h\d+?>/g, '<p>$1</p>')
    //math
    // .replaceAll(/\$\$\$([^\n$]+?)\$\$\$/g, '<mathblock tex="$1"></mathblock>')
    // .replaceAll(/\$([^\n$]+?)\$/g, '<mathinline tex="$1"></mathinline>')
    //chart
    // .replaceAll(/({{[ X]*func.*?}})/g, '<chart data="$1"></chart>')
    // console.log("After:")
    // console.log(replacedText)
    return replacedText
}

export function getSlateVal(v: string, normalizeForRoot = false) {
    const parsed = new DOMParser().parseFromString(fixHtml(v), 'text/html');
    const retVal: Descendant[] = deserialize(parsed.body);
    if (normalizeForRoot) {
        const normalizedData: Descendant[] = []
        // check toor nodes cant be text!
        for (let index = 0; index < retVal.length; index++) {
            const element: Descendant = retVal[index];

            if (SlateText.isText(element)) {
                const text = (element.text || '').trim();
                if (text) {
                    // if text wrap to paragraph
                    const newPElement: ParagraphElement = {
                        type: 'paragraph',
                        children: [element]
                    }
                    normalizedData.push(newPElement)
                }
            } else {
                normalizedData.push(element)

            }
        }
        return normalizedData;
    }
    return retVal;
}