import { ToggleButton, Tooltip } from "@mui/material";
import React from "react";
import { useSlate } from "slate-react";
import { MyEditor } from "../slate.inteface";
import { insertLink, isLinkActive, unwrapLink } from "./link";
import LinkRounded from '@mui/icons-material/LinkRounded';
import LinkOffRounded from '@mui/icons-material/LinkOffRounded';



export const LinkButton = (props: any) => {
    const editor = useSlate() as MyEditor;

    return (<>
        <Tooltip title="Link">
            <ToggleButton
                value={'link'}
                aria-label={'link'}
                selected={isLinkActive(editor)}
                onMouseDown={event => {
                    event.preventDefault()
                    //
                    const url = window.prompt('Enter the URL of the link:')
                    if (!url) return
                    insertLink(editor, url)
                }}
            >
                <LinkRounded />
            </ToggleButton>
        </Tooltip>
    </>

    )
}

export const LinkOffButton = (props: any) => {
    const editor = useSlate() as MyEditor;

    return (<>
        <Tooltip title="Unlink">
            <ToggleButton
                value={'link'}
                aria-label={'link'}
                selected={isLinkActive(editor)}
                onMouseDown={event => {
                    if (isLinkActive(editor)) {
                        unwrapLink(editor)
                    }
                }}
            >
                <LinkOffRounded />
            </ToggleButton>
        </Tooltip>
    </>

    )
}
