import React from 'react';
import { Box, Divider, Link } from '@mui/material';
import AppLogo from '../layout/parts/AppLogo';
import { Link as RouterLink } from 'react-router-dom';

interface MyFooterProps {
}

export const Footer: React.FC<MyFooterProps> = (props) => {

    return <Box sx={{
        pt: 4,
        pb: 4,
    }}>
        <Divider></Divider>

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
                <AppLogo height="30px" />
            </Box>
            <Box sx={{ m: 1 }}>
                <Link sx={{ m: 2, display: { xs: 'none', md: 'inline-block' } }} component={RouterLink} underline="hover" to="/terms-of-use">Terms</Link>
                <Link sx={{ m: 2 }} component={RouterLink} underline="hover" to="/privacy-notice">Privacy</Link>
            </Box>
        </Box>
    </Box >
}