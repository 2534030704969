import { CreateOutlined, ShareRounded, SearchTwoTone, DeviceHub, Favorite } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Container, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../../shared/footer/Footer';
import { selectUser } from '../../state/features/auth/authSlice';
import { useAppSelector } from '../../state/hooks';
function About() {
  const user = useAppSelector(selectUser);

  const green = '#508b41';
  const cards = [
    {
      title: 'Easy To Create',
      desc: 'Easy to type equations, add charts and write rich hierarchical documents.',
      icon: CreateOutlined,
      icon_color: green
    },
    {
      title: 'Easy To Share',
      desc: 'You can copy and paste your documents and share with your friends.',
      icon: ShareRounded,
      icon_color: green
    },
    {
      title: 'Easy To Search',
      desc: 'You can search documents by categories, chapters and subchapters.',
      icon: SearchTwoTone,
      icon_color: green
    },
    {
      title: 'Use Anywhere',
      desc: 'You can view your documents anywhere, quickly and easy on your mobile device.',
      icon: DeviceHub,
      icon_color: green
    }
  ]
  return (
    <Container sx={{}}>
      <Box
        sx={{
          // bgcolor: 'background.paper',
          pt: 8,
          pb: 6,
        }}
      >
        <Container maxWidth="sm">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            About Us
          </Typography>
          <Typography variant="h5" align="center" color="text.secondary" paragraph>
            OLogz is designed for anyone who wants to easily create, edit, modify, search and share technically demanding hierarchical documents.
          </Typography>
          {/* <Typography variant="caption" align="center" color="text.secondary" paragraph>
            This app is still in test phase.If you want accoun contact us on <strong>mathlog@gmail.com</strong>
          </Typography> */}

        </Container>

        <Container maxWidth="sm" sx={{ mt: 5, display: 'flex', justifyContent: 'center' }}>
          {!user && <Button variant='contained' color="info" component={Link} to="/login" startIcon={<Favorite fontSize='inherit'></Favorite>}>Start for FREE</Button>}
        </Container>
      </Box>
      <Grid container spacing={4}>
        {cards.map(c => (
          <Grid key={c.title} item xs={12} sm={6} md={3}>
            <Card
              sx={{ height: '100%', display: 'flex', flexDirection: 'column', textAlign: 'center' }}
            >
              <CardContent>
                <c.icon sx={{ fontSize: 60, color: c.icon_color }} />
                <Box sx={{ m: 1 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }} component="div">
                    {c.title}
                  </Typography>
                </Box>
                <Divider></Divider>
                <Box sx={{ m: 1 }}>
                  <Typography variant="body2">
                    {c.desc}
                  </Typography>
                </Box>

              </CardContent>
            </Card>
          </Grid>

        ))}

      </Grid>
      <Footer />
    </Container >
  );
}

export default About;
