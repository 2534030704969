import { Box, Button, IconButton } from "@mui/material";
import React from "react";
import { MultyInput } from "../MultyInput";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';



interface ParamsItemInputProps {
    value: number[];
    onChange: (x: number[]) => void;
}


export const PointsInput = (props: ParamsItemInputProps) => {

    const { value, onChange } = props;

    const tmpNumOfPoints = Math.ceil(value.length / 2);


    const changeValue = React.useCallback((point: number[], name: any) => {
        const newstate = value.map(x => x);
        const startIndexNum = parseFloat(name) * 2;
        newstate[startIndexNum] = point[0];
        newstate[startIndexNum + 1] = point[1];
        onChange(newstate);
    }, [value, onChange])

    const addPoint = React.useCallback(() => {
        const newstate = value.map(x => x);
        newstate.push(...[NaN, NaN])
        onChange(newstate);
    }, [value, onChange])

    const removePoint = React.useCallback((p: number) => {
        const newstate = value.map(x => x);
        newstate.splice(p * 2, 2)
        onChange(newstate);
    }, [value, onChange])

    const points = Array(tmpNumOfPoints).fill(1).map((x, i) => (
        <Box key={`${i}`} sx={{ display: 'flex', alignItems: 'center' }}>
            <MultyInput name={`${i}`} label={['x' + i, 'y' + i]} value={[value[i * 2], value[i * 2 + 1]]} onChange={changeValue}></MultyInput>
            <IconButton aria-label="delete"
                disabled={tmpNumOfPoints <= 2}
                onClick={() => { removePoint(i) }}
                color="error">
                <DeleteIcon color="inherit" />
            </IconButton>
        </Box>
    ))

    return <Box >

        {points}
        <Box sx={{ m: '5px' }}>
            <Button variant="outlined" onClick={addPoint} startIcon={
                <AddIcon color={value.length < 3 ? 'error' : 'primary'} />}>
                Add
            </Button>
        </Box>

    </Box>
}

