import React, { useEffect, useRef } from 'react';
// import { MathJax, MathJax3Config, MathJaxContext } from 'better-react-mathjax';
import { Chart } from '../chart/Chart'
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { getHTMLParts, parseFnProps } from '../chart/base/parseRaw';
import { ChartMainProps } from '../chart/base/chart.interface';
import { AutoRenderOptions, renderMathInElement } from "mathlive";


const confMath: AutoRenderOptions = {
  // Elements with a class of "instruction" or "source will be skipped
  // ignoreClass: 'instruction|source',
  TeX: {
    delimiters: {
      // Allow math formulas surround by $...$ or \(...\)
      // to be rendered as textstyle content.
      inline: [
        ['$', '$'],
        ['\\(', '\\)'],
      ],
      display: [
        ["$$$", "$$$"],
        ["\\[", "\\]"]
      ],
    },
  },
}

interface TopicItemProps {
  content: string;
}
export const MathMLContent = React.memo((props: TopicItemProps) => {

  const { content } = props;
  return (<>
    {getHTMLParts(content).map((x, i) => (<ContentPart key={i} content={x}></ContentPart>))}
  </>

  );
});


export const MathMLTitle = React.memo((props: TopicItemProps) => {
  const spanElement = useRef(null);
  const { content } = props;

  useEffect(() => {
    if (spanElement?.current) {
      renderMathInElement(spanElement.current, confMath)
    }
  }, [spanElement, content]);
  if (content.match(/\$([^\n$]+?)\$/g)) {
    return <span ref={spanElement}>{content}</span>
  } else {
    return <span >{content}</span>;
  }

});

interface ContentPartProps {
  content: string
}
function ContentPart(props: ContentPartProps) {
  const divElement = useRef(null);

  const { content } = props;

  useEffect(() => {
    if (divElement?.current) {
      renderMathInElement(divElement.current, confMath)
    }
  }, [divElement, content]);

  const chartProps = parseFnProps(content);
  if (chartProps) {
    return <BaseChartElement data={chartProps}></BaseChartElement>

  }
  return <div ref={divElement} dangerouslySetInnerHTML={{ __html: content }}></div>// problem with encoding latex!! e.g < > => solved with editor read-only
}

interface BaseChartProps {
  data: ChartMainProps,
  width?: string
}
export function BaseChartElement(props: BaseChartProps) {
  const theme = useTheme();
  const isMD = useMediaQuery(theme.breakpoints.up('md'));
  const width = props.width || '50%';
  return <Box sx={{ display: 'flex', justifyContent: 'center' }}>
    <Chart width={isMD ? width : '95%'} {...props.data}></Chart>
  </Box>
}

export default TopicItemProps;
