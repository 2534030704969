import { Box, Button } from "@mui/material";
import React from "react";
import { ChartMainProps, ChartText, COLOR_BLACK, FnPoint } from "../../../chart/base/chart.interface";
import { MultyInput } from "../edit/MultyInput";
import ChartTextItemInput from "./TextInput/TextItemInput";
import ChartPointItemInput from "./TextInput/PointItemInput";
//
import AddIcon from '@mui/icons-material/Add';

interface ChartDetailsProps {
    value: ChartMainProps;
    onChange: (data: ChartMainProps) => void;
}


export default function ChartDetailsInput(props: ChartDetailsProps) {
    const { value, onChange } = props;


    const changeValue = React.useCallback((domain: number[], name: string) => {
        const newstate = Object.assign({}, value);
        newstate[name as 'domain' | 'codomain'] = domain;
        onChange(newstate);
    }, [value, onChange])

    const changeTextValue = React.useCallback((index: number, textObj: ChartText) => {
        const newstate = Object.assign({}, value);
        newstate.text[index] = textObj;
        onChange(newstate);
    }, [value, onChange])

    const changePointValue = React.useCallback((index: number, textObj: FnPoint) => {
        const newstate = Object.assign({}, value);
        newstate.points[index] = textObj;
        onChange(newstate);
    }, [value, onChange])

    const removeValue = React.useCallback((index: number, name: 'points' | 'text') => {
        const newstate = Object.assign({}, value);
        newstate[name].splice(index, 1);
        onChange(newstate);
    }, [value, onChange]);

    const addTextValue = React.useCallback(() => {
        const newstate = Object.assign({}, value);
        newstate.text.push({
            position: [0, 0],
            text: 'New Text',
            color: COLOR_BLACK,
            fontFamily: 'Helvetica'
        })
        onChange(newstate);
    }, [value, onChange])

    const addPointValue = React.useCallback(() => {
        const newstate = Object.assign({}, value);
        newstate.points.push({
            position: [0, 0],
            color: COLOR_BLACK
        })
        onChange(newstate);
    }, [value, onChange])


    const partsStyle = { width: '50%', border: '1px solid gray', borderRadius: '5px', margin: '2px' }
    return (
        <>
            <Box>
                <MultyInput name="domain" label={['x-min', 'x-max']} value={value.domain} onChange={changeValue}></MultyInput>
                <MultyInput name="codomain" label={['y-min', 'y-max']} value={value.codomain} onChange={changeValue}></MultyInput>
            </Box>
            <Box sx={{ display: 'flex' }}>
                <Box sx={partsStyle}>
                    {value.text.map((x, i) => (<ChartTextItemInput key={i} value={x} onChange={(v) => changeTextValue(i, v)} onRemove={() => removeValue(i, 'text')}></ChartTextItemInput>))}
                    <Box sx={{ m: '5px' }}>
                        <Button variant="outlined" onClick={addTextValue} startIcon={
                            <AddIcon />}>
                            Add Text
                        </Button>
                    </Box>
                </Box>
                <Box sx={partsStyle}>
                    {value.points.map((x, i) => (<ChartPointItemInput key={i} value={x} onChange={(v) => changePointValue(i, v)} onRemove={() => removeValue(i, 'points')}></ChartPointItemInput>))}
                    <Box sx={{ m: '5px' }}>
                        <Button variant="outlined" onClick={addPointValue} startIcon={
                            <AddIcon />}>
                            Add Point
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>

    );
}