
export interface TickItem {
    value: number;
    label: string;
}


export type LinearScaleFn = (x: number) => number;
export type FnType = (x: number) => number;
export type ChartFnShape = 'line' | 'quadratic' | 'power' | 'expo' | 'log' | 'root' | // alebraic
    'sin' | 'asin' | 'cos' | 'acos' | 'tan' | 'atan' | 'cotan' | 'acotan' | // trigonometrix
    'sinh' | 'asinh' | 'cosh' | 'acosh' | 'tanh' | 'atanh' | 'cotanh' | 'acotanh' | // hiperbolic
    'circle' | 'l' | 'lz';  // shapes
export type StrokeType = 'line' | 'dash';


export interface ChartMainProps {
    fns: ChartFunction[];
    points: FnPoint[];
    text: ChartText[];
    codomain: number[];
    domain: number[];
}

export interface FnPoint {
    position: number[];
    color?: string;
}


export interface ChartText {
    text: string;
    position: number[];
    color?: string;
    fontFamily?: string;
    transform?: string;
}


export interface ChartFunction {
    domain?: number[];
    type: ChartFnShape;
    params: number[]
    color?: string;
    strokeType?: StrokeType;
}


export const SVG_CONSTS = {
    WIDTH: 400,
    HEIGHT: 400,

    PL: 10,
    PR: 10,
    PT: 10,
    PB: 10,
    TICK_W: 5,
    TICKS_NUM: 4
}

export const COLOR_BLACK = '#000000'
export const COLOR_GRAY = '#808080'


export const parseColor = (x: string) => {
    const val = x && x.trim();
    return val && val.startsWith('#') ? val : COLOR_BLACK;
}
export const HTML_FUNC_REG = /<\w+?>({{.*?}})<\/\w+?>/g;
export const FUNC_REG = /{{.*?}}/g;
export const FUNC_KEY = 'func';
export const SPLITTER = /[^\\]\|/;
export const SPLITTER_PARAMS = ';';

export function formatTick(x: number) {
    return x.toFixed(1);
}

export function scaleLinear(domain: number[], range: number[]): LinearScaleFn {

    const [domainMin, domainMax] = domain;
    const [rangeMin, rangeMax] = range;

    const domainDomain = domainMax - domainMin;
    const domainRange = rangeMax - rangeMin;

    const coef = domainDomain !== 0 ? (domainRange / domainDomain) : 0;

    return (dn: number) => (dn - domainMin) * coef + rangeMin;

}



export function ticksCalc(domain: number[]): TickItem[] {
    const [domainMin, domainMax] = domain;
    const domainDomain = domainMax - domainMin;
    const tick_distance = domainDomain / SVG_CONSTS.TICKS_NUM;


    if (domainMin < 0 && domainMax > 0) {
        const ticks: TickItem[] = [];
        //
        let positiveNext = tick_distance;
        let negativeNext = -tick_distance;
        let added = true
        while (ticks.length < SVG_CONSTS.TICKS_NUM && added) {
            added = false;
            if (positiveNext < domainMax) {
                ticks.push({
                    value: positiveNext,
                    label: formatTick(positiveNext)
                });
                positiveNext += tick_distance;
                added = true;
            }
            if (negativeNext > domainMin) {
                ticks.push({
                    value: negativeNext,
                    label: formatTick(negativeNext)
                });
                negativeNext -= tick_distance;
                added = true
            }
        }
        return ticks;
    } else {
        return Array(SVG_CONSTS.TICKS_NUM).fill(1).map((_, i) => {
            const value = domainMin + i * tick_distance;
            return { value, label: `${formatTick(value)}` };
        });
    }



}