import * as React from 'react';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import {
    Dialog, Button,
    DialogTitle, DialogContent, DialogActions, useMediaQuery, useTheme
} from '@mui/material';
import { ContentItem, OrderData, Topic } from '../../state/services/type';
import { Util } from '../../state/utility';
import { ContentList } from '../content-list/ContentList';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export type ChangeOrderFn = (item: ContentItem, parent: ContentItem | undefined, type: ChangeOrderType) => void;

export type ChangeOrderType = 'up' | 'up-level' | 'down' | 'down-level';

interface OrderDialogProps {
    saveOrderData: (content: OrderData) => void;
    cancel: () => void;
    topic: Topic | undefined;
    open: boolean;
}
export default function OrderDialog(props: OrderDialogProps) {

    const [topicEdit, setTopicEdit] = React.useState<Topic>();
    const { saveOrderData, topic, open, cancel } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleSave = React.useCallback(() => {
        if (topicEdit)
            saveOrderData({
                items: topicEdit?.items.map(x => ({ id: x.id, parent_id: x.parent_id, order: x.order })),
                id: topicEdit.id
            });
    }, [topicEdit, saveOrderData]);

    React.useEffect(() => {
        if (topic && open) {
            // on each open set new edit!!
            const tmpTopic = Object.assign({}, topic);
            tmpTopic.items = Util.deepCopy(topic.items);
            tmpTopic.children = Util.getTopicChildren(tmpTopic.items);
            setOrderToIntegers(tmpTopic.children);
            setTopicEdit(tmpTopic);
        }
    }, [topic, open]);


    const orderChange: ChangeOrderFn = React.useCallback((item: ContentItem, parent: ContentItem | undefined, type) => {
        if (topicEdit) {
            switch (type) {
                case 'down':
                    item.order += 1.1;
                    break;
                case 'up':
                    item.order -= 1.1;
                    break;
                case 'up-level':
                    if (parent) {
                        item.order = parent.order - 0.1;
                        item.parent_id = parent.parent_id;
                    }
                    break;
                case 'down-level':
                    const allParentChildren = parent?.children || topicEdit.children;
                    const targetItemIndex = allParentChildren.indexOf(item) + 1;
                    const targetItem = allParentChildren[targetItemIndex];
                    if (targetItem) {
                        item.parent_id = targetItem.id;
                        item.order = 0.1;// first
                    }
                    break;
                default:
                    break;
            }
            // get new order after order change!
            topicEdit.children = Util.getTopicChildren(topicEdit.items);
            //
            setOrderToIntegers(topicEdit.children);
            setTopicEdit(Object.assign({}, topicEdit));


        }
        //
    }, [topicEdit]);

    const list = topicEdit ? <ContentList topic={topicEdit} orderEdit={orderChange}></ContentList> : null;
    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={cancel}
                TransitionComponent={Transition}
                maxWidth={'md'}
                fullWidth={true}
            >
                <DialogTitle id="scroll-dialog-title">Edit Topic Order</DialogTitle>
                <DialogContent dividers={true}>
                    {list}
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancel} color="error">Cancel</Button>
                    <Button onClick={handleSave} color="success">Save Order</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


function setOrderToIntegers(items: ContentItem[]) {
    items.forEach((element, i) => {
        element.order = i + 1;
        setOrderToIntegers(element.children);
    });
}