import React, { memo, useEffect, useRef, useState } from "react";
import { IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
//
import EditIcon from '@mui/icons-material/Edit';
import { ReactEditor, useFocused, useSelected, useSlateStatic } from "slate-react";
import { Transforms } from "slate";
import { MyEditor } from "../slate.inteface";
import { renderMathInElement } from "mathlive";
import { encodeHTMLEntities } from "../../helpers";



export const MathElement = memo(({ attributes, children, element, readOnly }: any) => {


    const isInline = element.type === 'mathinline';
    const editor = useSlateStatic() as MyEditor;
    const path = ReactEditor.findPath(editor, element);
    const [showDelete, setShowDelete] = useState(false);
    const selected = useSelected()
    const focused = useFocused();

    const [hover, setHover] = React.useState(false);

    const spanElement = useRef<HTMLSpanElement>(null);

    const onMouseEnter = React.useCallback(() => {
        if (!readOnly) {
            setHover(true)
        }

    }, [setHover, readOnly]);
    const onMouseLeave = React.useCallback(() => {
        if (!readOnly) {
            setHover(false)
        }
        setHover(false)
    }, [setHover, readOnly]);

    const edit = React.useCallback(() => {
        editor.editMath({
            op: 'update',
            element: element,
            path
        })
    }, [editor, element, path]);

    const styles: React.CSSProperties = {
        border: '1px solid gray',
        borderRadius: '3px',
        position: 'absolute',
        display: hover ? 'flex' : 'none',
        background: hover ? '#e9e4e4bd' : undefined,
        zIndex: 1000,
        height: '100%',
        width: '100%',
        justifyContent: 'space-around',
        justifyItems: 'baseline',
        minWidth: '20px'
    }


    useEffect(() => {
        if (spanElement?.current) {
            spanElement.current.innerHTML = '';
            const spanNew = document.createElement('span');
            const startTag = isInline ? '\\(' : '$$$'
            const endTag = isInline ? '\\)' : '$$$'
            spanNew.innerHTML = encodeHTMLEntities(`${startTag}${element.tex}${endTag}`);
            renderMathInElement(spanNew, {
                // Elements with a class of "instruction" or "source will be skipped
                // ignoreClass: 'instruction|source',
                TeX: {
                    delimiters: {
                        // Allow math formulas surround by $...$ or \(...\)
                        // to be rendered as textstyle content.
                        inline: [
                            ['$', '$'],
                            ['\\(', '\\)'],
                        ],
                        display: [
                            ['$$$', '$$$'],
                        ],
                    },

                }
            })
            spanElement.current.appendChild(spanNew);
            const showDelete = spanNew.offsetWidth > 100;
            setShowDelete(showDelete);
        }
    }, [element, spanElement, isInline, setShowDelete])


    const iconsBlock = <span style={styles}>
        <IconButton aria-label="edit" size="small" onClick={edit}>
            <EditIcon fontSize="inherit" />
        </IconButton>
        {showDelete && <IconButton aria-label="delete" size="small" onClick={() => Transforms.removeNodes(editor, { at: path })} color="info">
            <DeleteIcon fontSize="inherit" color="error" />
        </IconButton>}
    </span>

    const stylesWrapper: React.CSSProperties = {
        display: isInline ? 'inline-block' : 'block',
        margin: isInline ? undefined : 'auto',
        width: isInline ? undefined : '100%',
        position: 'relative',
        // userSelect: 'element',
        boxShadow: selected && focused ? '0 0 0 3px #B4D5FF' : 'none'
    }

    const stylesSubWrapper: React.CSSProperties = {
        display: isInline ? undefined : 'flex',
        padding: isInline ? undefined : '15px',
        alignItems: 'center',
        justifyContent: isInline ? undefined : 'center'
    }

    return (<>
        <span {...attributes} style={stylesWrapper}>
            {children}
            <span
                contentEditable={false}
                style={stylesSubWrapper}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}>
                {iconsBlock}
                <span ref={spanElement}>
                </span>
            </span>
        </span>
    </>
    );


});