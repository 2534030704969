import {
    Descendant
} from 'slate'
import { ELEMENT_TAGS } from './elements';



export function stringifyAll(elements: Descendant[]): string {
    return elements.map(stringifyElement).join('');
}

function stringifyElement(desc: Descendant): string {

    const el = desc as any;

    if (el.type === 'link') {
        return `<a href="${el.url}">${stringifyAll(el.children)}</a>`
    } else if (el.type === 'image') {
        return `<img src="${el.url}"/>`
    } else if (el.type) {

        const mappingsElement = Object.keys(ELEMENT_TAGS).filter(x => x !== 'math').reduce((obj, key) => {
            const type = ELEMENT_TAGS[key]().type;
            obj[type] = key.toLowerCase();
            return obj;
        }, {} as any)
        const htmlTab = mappingsElement[el.type];
        //
        let innerText = stringifyAll(el.children);
        if (['chart', 'mathblock', 'mathinline'].includes(el.type)) {
            innerText = el.tex || el.data;
        }
        return `<${htmlTab}>${innerText}</${htmlTab}>`
    } else {

        const allTextAttributes: any = {
            code: 'code',
            strikethrough: 'del',
            italic: 'i',
            bold: 'strong',
            underline: 'u',
        }
        const textKeys = Object.keys(allTextAttributes);

        //
        const htmlTabs = textKeys.filter(k => el[k]).map((k) => allTextAttributes[k]);
        //
        const startTags = htmlTabs.map(tag => `<${tag}>`).join('');
        const endTags = htmlTabs.reverse().map(tag => `</${tag}>`).join('');
        return `${startTags}${el.text}${endTags}`;
    }
}

