import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import { Footer } from '../../shared/footer/Footer';
import { MyTiers } from './Tiers';

export default function Billing() {


  return (
    <Container sx={{}}>
      <Box
        sx={{
          // bgcolor: 'background.paper',
          pt: 8,
          pb: 6,
        }}
      >
        <Container maxWidth="sm">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Plans
          </Typography>
          <Typography variant="h5" align="center" color="text.secondary" paragraph>
            If you want more resources you can apply for more.
          </Typography>
          <Typography variant="caption" align="center" color="text.secondary" paragraph>
            Your request will be processed as soon as possible
          </Typography>
        </Container>

        <MyTiers></MyTiers>
      </Box>
      <Footer />
    </Container >
  );
}
