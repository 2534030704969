import { Box, Button, TextField } from "@mui/material";
import React, { useCallback } from "react";
import ImageIcon from '@mui/icons-material/Image';


export interface SvgPickProps {
    svg: string;
    setSvg: (x: string) => void;
}
export const SvgPick = (props: SvgPickProps) => {

    const { svg, setSvg } = props;

    const onChangeSvg = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value;
        setSvg(val);
    }, [setSvg])



    const inputFileRef = React.useRef<HTMLInputElement>(null);
    const onBtnClick = useCallback(() => {
        /*Collecting node-element and performing click*/
        inputFileRef.current?.click();
    }, [inputFileRef]);
    const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
        (e) => {
            if (e.target.files) {
                let imageFile = e.target.files[0];
                var reader = new FileReader();
                reader.onload = function (eReader) {
                    if (eReader.target) {
                        const val = eReader.target.result as string
                        setSvg(val);
                    }
                }
                reader.readAsText(imageFile);
            }
        }, [setSvg])

    return (
        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>
            <Button onClick={onBtnClick}>
                <input type="file" ref={inputFileRef} accept="image/svg,.svg" name="image" id="file"
                    onChange={onChange}
                    style={{ display: 'none' }}></input>
                <ImageIcon />
                Pick Svg
            </Button>
            <Box>
                <TextField
                    sx={{
                        width: '100%'
                    }}
                    placeholder="Paste SVG here"
                    multiline
                    rows={4}
                    variant="filled"
                    value={svg}
                    onChange={onChangeSvg}
                />
            </Box>
        </Box>

    )
}
