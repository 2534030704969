import { Alert, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  useGetTopicQuery,
} from '../../state/services/mathapi';
import { OrderData, Topic, ContentItem } from '../../state/services/type';
import EditDialogML, { EditData } from '../../shared/topic-item/EditDialogML';
import OrderDialog from '../../shared/topic-item/OrderDialog';
import TopicItem from '../../shared/topic-item/TopicItem';
import { useTopicActions } from './hooks';
import { Util } from '../../state/utility';
import { Footer } from '../../shared/footer/Footer';
import { Loading } from '../../shared/loading/Loading';

function TopicView() {

  let { topicId, itemId } = useParams();
  let navigate = useNavigate();
  //
  const isCreate = topicId === 'new';
  //
  //
  const [open, setOpen] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);
  const [editData, setEditdata] = useState<EditData>({
    content: '',
    title: '',
    description: '',
    isCreate: isCreate,
    isTopic: isCreate
  });
  //
  const { data, error, isLoading: isGetTopic } = useGetTopicQuery(topicId, {
    skip: !topicId || isCreate,
  });
  const tmpItem = data?.items.find(x => x.id === itemId);
  //
  const {
    isLoading: isTopicActionLoading,
    saveEditData, handleRemove,
    updateTopicOrder,
    togglePublic } = useTopicActions(setOpen, data, itemId);

  const isLoading = isGetTopic || isTopicActionLoading;

  const handleEdit = React.useCallback((data: EditData) => {
    setEditdata(data);
    setOpen(true);
  }, []);



  const handleEditOrder = React.useCallback(() => {
    setOpenOrder(true);
  }, []);

  const saveOrder = React.useCallback((data: OrderData) => {
    setOpenOrder(false);
    updateTopicOrder(data);
  }, [updateTopicOrder]);

  const cancel = React.useCallback(() => {
    if (editData.isCreate && editData.isTopic) {
      navigate(`/my`)
    } else {
      setOpen(false);
    }
  }, [editData, navigate]);

  const cancelOrder = React.useCallback(() => {
    setOpenOrder(false);
  }, []);

  useEffect(() => {
    setOpen(isCreate);
  }, [isCreate])

  const errorShow = error && !data && !isLoading ? <Alert severity="error" elevation={6} variant="filled" >{(error as any).errorMSG || 'Server Error!'}</Alert> : null;

  const loadingSpinner = isLoading && !isCreate ? < Loading /> : null;


  const breadcrumbsData = React.useMemo(() => data ? Util.getBreadcrumbs(data, tmpItem) : [], [data, tmpItem]);
  return (
    <Box>
      {loadingSpinner}
      {errorShow}
      {data && <>
        <ContentPure topic={data} item={tmpItem} handleEdit={handleEdit} handleEditOrder={handleEditOrder} remove={handleRemove} handlePublic={togglePublic}></ContentPure>
        <OrderDialog topic={data} open={openOrder} cancel={cancelOrder} saveOrderData={saveOrder}></OrderDialog>
      </>}
      <EditDialogML open={open} cancel={cancel} data={editData} saveEditData={saveEditData} breadcrumbs={breadcrumbsData}></EditDialogML>
      <Footer />
    </Box>
  );
}


interface ContentProps {
  topic?: Topic;
  item?: ContentItem;
  handleEdit: (data: EditData) => void;
  handleEditOrder: () => void;
  handlePublic: () => void;
  remove: (x?: string) => void;
}

const ContentPure = React.memo((props: ContentProps) => {
  const { handleEdit, handleEditOrder, topic, item, remove, handlePublic } = props;
  if (topic) {
    return <TopicItem topic={topic} item={item} handleEdit={handleEdit} handleOrder={handleEditOrder} remove={remove} handlePublic={handlePublic}></TopicItem >
  }
  return null;
});



export default TopicView;
