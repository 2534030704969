import React from "react";
import { IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
//
import EditIcon from '@mui/icons-material/Edit';
import { ReactEditor, useFocused, useSelected, useSlateStatic } from "slate-react";
import { Transforms } from "slate";
import { MyEditor } from "../slate.inteface";
import { parseFnProps } from "../../chart/base/parseRaw";
import { BaseChartElement } from "../../math-ml-content/math-ml-content";




export const ChartElement = ({ attributes, children, element, readOnly }: any) => {

    const isInline = element.type === 'chartinline';// NOT
    const editor = useSlateStatic() as MyEditor;
    const path = ReactEditor.findPath(editor, element)
    const selected = useSelected()
    const focused = useFocused();

    const [hover, setHover] = React.useState(false);



    const onMouseEnter = React.useCallback(() => {
        if (!readOnly) {
            setHover(true)
        }
    }, [setHover, readOnly]);
    const onMouseLeave = React.useCallback(() => {
        if (!readOnly) {
            setHover(false)
        }
    }, [setHover, readOnly]);

    const edit = React.useCallback(() => {
        editor.editMath({
            op: 'update',
            element: element,
            path
        })
    }, [editor, element, path]);

    const styles: React.CSSProperties = {
        border: '1px solid gray',
        borderRadius: '3px',
        position: 'absolute',
        display: hover ? 'flex' : 'none',
        background: hover ? '#e9e4e4bd' : undefined,
        zIndex: 1000,
        height: '100%',
        width: '100%',
        justifyContent: 'space-around',
        alignItems: 'center',
        minWidth: '70px',
    }


    const iconsBlock = <span style={styles}>
        <IconButton aria-label="edit" size="large" onClick={edit}>
            <EditIcon fontSize="inherit" />
        </IconButton>
        <IconButton aria-label="delete" size="large" onClick={() => Transforms.removeNodes(editor, { at: path })} color="info">
            <DeleteIcon fontSize="inherit" color="error" />
        </IconButton>
    </span>

    const stylesWrapper: React.CSSProperties = {
        display: isInline ? 'inline-block' : 'block',
        padding: isInline ? undefined : '0% 0%',
        width: isInline ? undefined : '100%',
        position: 'relative',
        userSelect: 'element',
        boxShadow: selected && focused ? '0 0 0 3px #B4D5FF' : 'none'
    }

    const stylesSubWrapper: React.CSSProperties = {
        position: 'relative',
        display: isInline ? undefined : 'block',
    }
    const chartProps = parseFnProps(element.data);
    let chart = <span> Error Chart Data! </span>

    if (chartProps) {
        chart = <BaseChartElement
            data={chartProps}
        />
    }


    return (<>
        <span {...attributes} style={stylesWrapper}>
            {children}
            <span
                contentEditable={false}
                style={stylesSubWrapper}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}>
                {iconsBlock}
                {chart}
            </span>
        </span>
    </>
    );


};