import { Box, Container } from '@mui/material';
import React from 'react';
import { Footer } from '../../shared/footer/Footer';
import { MyText } from './MyText';
export default function TermsOfUse() {
  return (
    <Container sx={{}}>
      <Box
        sx={{
          pt: 8,
          pb: 6,
        }}
      >
        <MyText t="t1">
          Terms of service
        </MyText>

        <MyText t="t3">
          Ologz Terms of Service (“Agreement”)
        </MyText>

        <MyText>
          Last modified on May 23, 2022.
        </MyText>

        <MyText>Please read these Terms of Service (“Agreement”, “Terms of Service”) carefully before using
          www.ologz.com (“the Site”) operated by ologz (“us”, “we”, or “our”). This Agreement sets forth
          the legally binding terms and conditions for your use of the Site at www.ologz.com.</MyText>
        <MyText>By accessing or using the Site in any manner, including, but not limited to, visiting or browsing the
          Site or contributing content or other materials to the Site, you agree to be bound by these Terms of
          Service. Capitalized terms are defined in this Agreement.</MyText>
        <MyText><strong>Intellectual Property</strong><br />
          The Site and its original content, features and functionality are owned by ologz and are protected
          by international copyright, trademark, patent, trade secret and other intellectual property or
          proprietary rights laws.</MyText>
        <MyText><strong>Termination</strong><br />
          We may terminate your access to the Site, without cause or notice, which may result in the forfeiture
          and destruction of all information associated with you. All provisions of this Agreement that by their
          nature should survive termination shall survive termination, including, without limitation, ownership
          provisions, warranty disclaimers, indemnity, and limitations of liability.</MyText>
        <MyText><strong>Links To Other Sites</strong><br />
          Our Site may contain links to third-party sites that are not owned or controlled by ologz.</MyText>
        <MyText>ologz has no control over, and assumes no responsibility for, the content, privacy policies, or
          practices of any third party sites or services. We strongly advise you to read the terms and conditions
          and privacy policy of any third-party site that you visit.</MyText>
        <MyText><strong>Governing Law</strong><br/>
          This Agreement (and any further rules, polices, or guidelines incorporated by reference) shall be
          governed and construed in accordance with the laws of , without giving effect to any principles of
          conflicts of law.</MyText>
        <MyText><strong>Changes To This Agreement</strong><br />
          We reserve the right, at our sole discretion, to modify or replace these Terms of Service by posting the
          updated terms on the Site. Your continued use of the Site after any such changes constitutes your
          acceptance of the new Terms of Service.</MyText>
        <MyText>Please review this Agreement periodically for changes. If you do not agree to any of this Agreement or
          any changes to this Agreement, do not use, access or continue to access the Site or discontinue any use
          of the Site immediately.</MyText>
        <MyText><strong>Contact Us</strong><br />
          If you have any questions about this Agreement, please contact us to ologs.group@gmail.com.</MyText>
      </Box>
      <Footer />
    </Container >
  );
}
