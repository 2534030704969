
import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getTopicCategoryName, Topic } from '../../state/services/type';
import { CardSvg } from '../imgs/CardSvg';
import { Pagination } from './Pagination';
import {
    Button, Card, CardActionArea, CardContent, Container,
    Divider, Grid, Stack, Typography, Box
} from '@mui/material';
import { Footer } from '../footer/Footer';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import { Loading } from '../loading/Loading';

//
interface TopicsListProps {
    my?: boolean;


}
function TopicsList(props: TopicsListProps) {

    const { my } = props;
    const [topics, setTopics] = useState<Topic[]>([])
    const [isLoading, setLoading] = useState<boolean>(false)
    const [lastAnimIndex, setLastAnimIndex] = useState(0);

    const loadTopics = useCallback((data: Topic[], error?: any, loading?: boolean) => {
        setTopics((oldTopics) => {
            setLastAnimIndex(oldTopics.length)
            return data;
        });
        setLoading(loading || false);
    }, [setLastAnimIndex, setTopics])
    const nav = useNavigate();

    const goToTopic = useCallback((topic: Topic) => {
        nav(`/topic/${topic.id}`);
    }, [nav])

    return (
        <Container sx={{}}>
            <Box
                sx={{
                    pt: 8,
                    pb: 6,
                }}
            >
                <Container maxWidth="sm">
                    <Typography
                        component="h1"
                        variant="h2"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        {my ? 'My Topics' : ' Topics'}
                    </Typography>
                    <Typography variant="h5" align="center" color="text.secondary" paragraph>
                        {my ? 'Create your own notes, learn something new!' : `Learning something new, need simple way to create notes, easy to find, search, copy and share?
                        Find Topic that you like, copy it, create new chapters and more.`}
                    </Typography>


                    {my &&
                        <Stack
                            sx={{ pt: 4 }}
                            direction="row"
                            spacing={2}
                            justifyContent="center"
                        >
                            <Button variant="contained" size="large" component={Link as any} to={`/topic/new`} startIcon={<BorderColorTwoToneIcon fontSize='inherit'></BorderColorTwoToneIcon>}>Create New Document</Button>
                        </Stack>
                    }



                </Container>

            </Box>

            {isLoading ? <Loading /> : null}


            <Grid container spacing={4}>
                {topics.map((topic, i) => (
                    <Grid item key={`${topic.id}${i}`} xs={12} sm={6} md={4}>
                        <Card
                            style={{ animation: `0.3s fadeIn forwards`, animationDelay: `${Math.abs(i - lastAnimIndex) / 10}s`, opacity: 0 }}
                            sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                        >
                            <CardActionArea sx={{
                                flexGrow: 1, justifyContent: 'flex-start',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'normal'
                            }} onClick={() => goToTopic(topic)}>

                                <CardSvg category={topic.category} my={my} />
                                <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            {topic.title}
                                        </Typography>

                                        <Typography>
                                            {topic.description}
                                        </Typography>
                                    </Box>
                                    <Box sx={{
                                        justifyContent: 'flex-end',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <Divider variant='fullWidth'></Divider>
                                        <Typography variant="caption" sx={{ textAlign: 'right', mt: '10px' }}>
                                            {`${getTopicCategoryName(topic.category)}`}
                                        </Typography>
                                    </Box>
                                </CardContent>
                                {/* <CardActions>
                                    <Button size="small" component={Link as any} to={`/topic/${topic.id}`}>Edit</Button>
                                </CardActions> */}
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Pagination my={my || false} onLoad={loadTopics}></Pagination>
            <Footer />
        </Container >
    );
}


export default TopicsList;
