import React from "react";
import { MathfieldComponent } from '../../MathfieldComponent/MathfieldComponent';
import { Box, ToggleButtonGroup, ToggleButton, IconButton } from "@mui/material";
import { CustomElement, ElementActions, MathElement, MathElementType } from "../slate.inteface";

import HelpIcon from '@mui/icons-material/Help'
// import BlockIcon from '@mui/icons-material/Block'


interface MathElementEditProps {
    action: ElementActions;
    editElement: CustomElement | null;
    onChange: (data: CustomElement) => void;
}
const isMaht = (mathElement: CustomElement | null) => mathElement?.type === 'mathblock' || mathElement?.type === 'mathinline'
export default function MathElementEdit(props: MathElementEditProps) {
    const { editElement, onChange } = props;
    const [mathEditContent, setMathEditContent] = React.useState('');
    const [type, setType] = React.useState<MathElementType>('mathinline');


    const handleEditContent = React.useCallback((newVal: string) => {
        setMathEditContent(newVal)
        const newdata: CustomElement = { type, tex: newVal, children: [{ text: '' }] };
        onChange(newdata)
    }, [onChange, setMathEditContent, type])

    const handleType = React.useCallback((event: React.MouseEvent<HTMLElement>, newType: any) => {
        if (newType !== null) {
            setType(newType)
            const newdata: CustomElement = { type: newType, tex: mathEditContent, children: [{ text: '' }] };
            onChange(newdata)
        }
    }, [mathEditContent, setType, onChange])

    React.useEffect(() => {
        const mathElement = (editElement as MathElement);
        if (isMaht(mathElement)) {
            setMathEditContent(mathElement.tex?.replaceAll('$', '') || '');
            setType(mathElement.type)
        }
    }, [editElement, setMathEditContent, setType]);

    if (!isMaht(editElement)) {
        return null;
    }

    return (<>
        <Box sx={{ margin: '30px 0px' }}>
            <Box sx={{ margin: '30px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <ToggleButtonGroup
                        value={type}
                        exclusive
                        onChange={handleType}
                        aria-label="text alignment"
                    >
                        <ToggleButton value="mathinline" aria-label="inline">
                            Inline
                        </ToggleButton>
                        <ToggleButton value="mathblock" aria-label="block">
                            Block
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <IconButton size="small" href="https://oeis.org/wiki/List_of_LaTeX_mathematical_symbols" target="_blank" ><HelpIcon /></IconButton>
                </Box>
            </Box>
            <Box sx={{ m: 4 }}>
                <MathfieldComponent
                    latex={mathEditContent}
                    onChange={handleEditContent}
                />
            </Box>
        </Box>
    </>
    );
}
