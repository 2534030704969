
const COPY_KEY_TEXT = 'copy/paste/editor/text';

interface CopyPasteEditor {
    text: string,
    html: string
}
export const saveCopyPasteEditor = (data: CopyPasteEditor) => {
    localStorage.setItem(COPY_KEY_TEXT, JSON.stringify(data));
}
export const getCopyPastedEditor = (): CopyPasteEditor | null => {
    const stringData = localStorage.getItem(COPY_KEY_TEXT);
    if (stringData) {
        return JSON.parse(stringData) as CopyPasteEditor;
    }
    return null;
}

