
// xx[.d].ts
declare global {
    var google: any;
    var googleBtnRendered: boolean
}
export function initGoogle(cb: any) {
    google.accounts.id.initialize({
        client_id: "672652463131-ggpuh3q0mjk2qv2ss18nna496hdse8sf.apps.googleusercontent.com",
        callback: cb
    });
    // google.accounts.id.prompt(); // also display the One Tap dialog
}


export function revokeGoogle(email: string) {
    google.accounts.id.initialize({
        client_id: "672652463131-ggpuh3q0mjk2qv2ss18nna496hdse8sf.apps.googleusercontent.com",
        callback: () => { }
    });
    google.accounts.id.revoke(email, (done: any) => {
        console.log('consent revoked');
    });
}