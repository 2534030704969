import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Tab, Tabs, ToggleButton, Tooltip, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSlate } from "slate-react";
import { MyEditor } from "../slate.inteface";
import { insertImage } from "./img";
import ImageIcon from '@mui/icons-material/Image';
import { TransitionProps } from "@mui/material/transitions";
import { ImagePick } from "./img-type/ImagePick";
import { SvgPick } from "./img-type/SvgPick";


type TabValue = "image" | "svg";
const URL_PREFIX = 'data:image/svg+xml;base64,'

const svgToBase64 = (svg: string) => svg && `${URL_PREFIX}${window.btoa(svg)}`;

export const InsertImageButton = (props: any) => {

    const editor = useSlate() as MyEditor;

    // const editor = useSlate() as MyEditor;
    const [open, setOpen] = React.useState(false);
    const [tab, setTab] = React.useState<TabValue>('image');

    const [imageUrl, setImageUrl] = useState<string>('');
    const [image, setImage] = useState<string>('');
    const [svg, setSvg] = useState<string>('');
    //
    const handleClickOpen = useCallback(() => {
        setOpen(true);
    }, [])

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [])

    const handleSvgChange = useCallback((svg: string) => {
        setSvg(svg);
        setImageUrl(svgToBase64(svg));
    }, [setSvg, setImageUrl])

    const handleImageChange = useCallback((img: string) => {
        setImage(img);
        setImageUrl(img);
    }, [setImageUrl, setImage])

    const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: TabValue) => {
        setTab(newValue);
        setImageUrl(newValue === 'image' ? image : svgToBase64(svg));
    }, [setTab, setImageUrl, image, svg])


    useEffect(() => {
        if (!open) {
            setImageUrl('')
            setImage('')
            setSvg('')
            setTab('image')
        }
    }, [open, setImageUrl, setImage, setSvg, setTab])

    const handleInsert = useCallback(() => {
        insertImage(editor, imageUrl);
        setOpen(false);
    }, [editor, imageUrl])
    return (<>
        <Tooltip title="Image">
            <ToggleButton
                value={'image'}
                aria-label={'image'}
                selected={false}
                onClick={handleClickOpen}
            >
                <ImageIcon />
            </ToggleButton>
        </Tooltip>
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            fullWidth={true}
            maxWidth="md"
        >
            <DialogTitle >
                <Box sx={{ width: '100%', display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                    <Typography variant="h5">Insert Image</Typography>
                    <Typography>
                        Size: {imageUrl?.length}
                    </Typography>
                </Box>

            </DialogTitle>
            <DialogContent>
                <Box >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
                        <Tabs value={tab} onChange={handleChangeTab} aria-label="basic tabs example" centered>
                            <Tab label="Image" value="image" />
                            <Tab label="Svg" value="svg" />
                        </Tabs>
                    </Box>
                </Box>
                <Box >
                    <Box style={{ display: tab === 'image' ? undefined : 'none' }}>
                        <ImagePick url={image} setUrl={handleImageChange}></ImagePick>
                    </Box>
                    <Box style={{ display: tab === 'svg' ? undefined : 'none' }}>
                        <SvgPick svg={svg} setSvg={handleSvgChange}></SvgPick>
                    </Box>
                </Box>
                <Box sx={{ minHeight: '400px' }}>
                    <img
                        src={imageUrl} alt="Preview"
                        style={{
                            display: imageUrl ? 'block' : 'none',
                            width: '100%',
                            height: 'auto',
                            margin: '10px auto',
                            boxShadow: '0 0 0 3px #B4D5FF'
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="error">Cancel</Button>
                <Button onClick={handleInsert} color="success" disabled={!imageUrl}>Insert {tab}</Button>
            </DialogActions>
        </Dialog>
    </>

    )
}



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});