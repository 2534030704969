import { createTheme } from '@mui/material';


export const AppBarTextColor = '#1b6474';
export const theme = createTheme({
  // typography: {
  //   fontFamily: 'math'
  // },
  palette: {
    // primary: {
    //   main: '#2c3f5a',
    // },
    // secondary: {
    //   main: '#edf2ff',
    // },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        style: {
          // background: 'linear-gradient(#464a54, #072a5e)',
          color:  AppBarTextColor
        }
      }
    }
  }
});

