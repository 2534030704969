import { useAppSelector } from '../../../state/hooks';
import { selectUser } from '../../../state/features/auth/authSlice';
import { Home, Info, Security, AccountBox, Logout, Login } from '@mui/icons-material/';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';
import { useMemo } from 'react';

export interface MyNavigationItem {
    key: 'home' | 'my' | 'about' | 'profile' | 'logout' | 'login';
    name: string;
    route: string;
    icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
}
export const nav: { [key: string]: MyNavigationItem } = {
    home: { key: "home", name: 'Home', route: '/', icon: Home },
    my: { key: "my", name: 'My Topics', route: '/my', icon: Security },
    about: { key: "about", name: 'About', route: '/about', icon: Info },
    profile: { key: "profile", name: 'Profile', route: '/profile', icon: AccountBox },
    logout: { key: "logout", name: 'Logout', route: '/', icon: Logout },
    login: { key: "login", name: 'Sign In', route: '/login', icon: Login },
}

export const useMyNavigation = () => {
    const user = useAppSelector(selectUser);
    return useMemo(() => {
        if (user) {
            return [nav.home, nav.my, nav.about]
        }
        return [nav.home, nav.about]
    }, [user]);

}

export const useUserNavigation = () => {
    const user = useAppSelector(selectUser);
    return useMemo(() => {
        if (user) {
            return [nav.profile, nav.logout]
        }
        return [nav.login]
    }, [user]);
}
