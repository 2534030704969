import { Box, Button, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { ChartFunction, ChartMainProps } from "../../../chart/base/chart.interface";
import DeleteIcon from '@mui/icons-material/Delete';

import { ChartShapeLabel, FunctionItemInput } from './FuncItemInput/FuncItemInput';
import { AllFunctions, getFnDefaultParams } from "../../../chart/base/Functions";
import { MyAccordion, MyAccordionDetails, MyAccordionSummary } from "../accordian/MyAccordian";
import ChartDetailsInput from './ChartDetailsInput'


interface ChartEditInputProps {
    value: ChartMainProps;
    onChange: (x: ChartMainProps) => void;
}

const isValid = (x: ChartFunction[]) => !x.map(x => AllFunctions[x.type].isValid(x)).includes(false);
export const ChartEditInput = (props: ChartEditInputProps) => {

    const { value, onChange } = props;
    const [innerFnValue, setInnerFnValue] = React.useState<ChartFunction[]>([]);

    const [expanded, setExpanded] = React.useState<string | null>('details');

    const handleChangeExpanded =
        (panel: string) => (event: any, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : null);
        };

    const onValueFnChange = React.useCallback((fnChartFunction: ChartFunction, i: number) => {
        const newFnVal = innerFnValue.map(x => x);
        newFnVal[i] = fnChartFunction;
        setInnerFnValue(newFnVal);
        //
        setExpanded(`${i}`);
        if (isValid(newFnVal)) {
            const newVal = Object.assign({}, value);
            newVal.fns = newFnVal;
            onChange(newVal);
        }
    }, [innerFnValue, value, setInnerFnValue, onChange, setExpanded]);

    const handleClickCreate = useCallback(() => {
        const newFnVal = innerFnValue.map(x => x);
        const newFn: ChartFunction = {
            type: 'lz',
            params: getFnDefaultParams('lz')
        }
        newFnVal.push(newFn)
        setInnerFnValue(newFnVal);
        if (isValid(newFnVal)) {
            const newVal = Object.assign({}, value);
            newVal.fns = newFnVal;
            onChange(newVal);
        }
        setExpanded(`${newFnVal.length - 1}`);// current index!
        //
    }, [innerFnValue, value, setInnerFnValue, onChange, setExpanded]);

    const handleClickDelete = useCallback((i: number) => {

        const newFnVal = innerFnValue.map(x => x);
        newFnVal.splice(i, 1);
        setInnerFnValue(newFnVal)
        if (isValid(newFnVal)) {
            const newVal = Object.assign({}, value);
            newVal.fns = newFnVal;
            onChange(newVal);
        }
    }, [innerFnValue, value, setInnerFnValue, onChange]);

    useEffect(() => {
        setInnerFnValue(value.fns);
    }, [value, setInnerFnValue])



    return <>
        <MyAccordion
            expanded={expanded === 'details'} onChange={handleChangeExpanded("details")}
        >
            <MyAccordionSummary aria-controls="panel2d-content" id="panel2d-header">

                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h6" >
                        Chart Details
                    </Typography>
                </Box>
            </MyAccordionSummary>
            <MyAccordionDetails>
                <ChartDetailsInput value={value} onChange={onChange}></ChartDetailsInput>
            </MyAccordionDetails>
        </MyAccordion>
        {innerFnValue.map((f, i) => (<MyAccordion key={f.type + i}
            expanded={expanded === `${i}`} onChange={handleChangeExpanded(`${i}`)}
        >
            <MyAccordionSummary aria-controls="panel2d-content" id="panel2d-header">

                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <ChartShapeLabel type={f.type}></ChartShapeLabel>
                    <Typography color={AllFunctions[f.type].isValid(f) ? undefined : "red"}>
                        {!AllFunctions[f.type].isValid(f) ? ' (Validation Error)' : ''}
                    </Typography>
                    <Button variant="outlined" size="small" color="error" onClick={(e) => {
                        e.stopPropagation();
                        handleClickDelete(i)
                    }} startIcon={<DeleteIcon fontSize="inherit" />}>
                        DELETE
                    </Button>
                </Box>
            </MyAccordionSummary>
            <MyAccordionDetails>
                <FunctionItemInput value={f} onChange={(x) => onValueFnChange(x, i)}></FunctionItemInput>
            </MyAccordionDetails>
        </MyAccordion>
        ))}
        <MyAccordion TransitionProps={{ unmountOnExit: true }} expanded={false}>
            <MyAccordionSummary
                expandIcon={null}
                aria-controls="add-chart-fn"
            >

                <Box sx={{
                    display: 'flex',
                    justifyContent: "center",
                    width: '100%'
                }}
                >
                    <Button size="small" variant="outlined" onClick={handleClickCreate} >Add Object</Button>
                </Box>
            </MyAccordionSummary>
        </MyAccordion >
    </>
}
