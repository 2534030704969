import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Box, TextField } from "@mui/material";
import React, { memo } from "react";
import { ChartFunction, ChartFnShape, StrokeType } from "../../../../chart/base/chart.interface";
import { AllFunctions, getFnDefaultParams } from "../../../../chart/base/Functions";
import { MathMLTitle } from "../../../../math-ml-content/math-ml-content";
import { MultyInput } from "../MultyInput";
import { PointsInput } from "./PointsInput";



interface FunctionItemInputProps {
    value: ChartFunction;
    onChange: (x: ChartFunction) => void;
}





export function ChartShapeLabel({ type }: { type: ChartFnShape }) {
    const label = AllFunctions[type].labelFn;
    if (!['l', 'circle', 'lz'].includes(type)) {
        return <MathMLTitle content={`$${label}$`} />;
    } else {
        return <>{label}</>;
    }
}


const allShapes = Object.keys(AllFunctions).map(x => (x as ChartFnShape));

const allStrokeTypes: StrokeType[] = ['line', 'dash'];

var isNumber = function isNumber(value: any) {
    return typeof value === 'number' && isFinite(value);
}
const isValidDomainWithNull = (x: number[]) => !x.map(isNumber).includes(false) || !x.map(isNumber).includes(true);

export const FunctionItemInput = memo((props: FunctionItemInputProps) => {

    const { value, onChange } = props;


    const handleChangeEvent = (event: SelectChangeEvent | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newInnerValue = Object.assign<any, any>({}, value);
        const name = event.target.name;
        const eventValue = event.target.value;
        newInnerValue[name] = eventValue;
        if (name === 'type') {
            newInnerValue.params = getFnDefaultParams(eventValue as ChartFnShape);
        }
        onChange(newInnerValue);
    };

    const changeMultyValue = React.useCallback((domain: number[], name: any) => {
        const newstate = Object.assign({}, value) as any;
        if (domain.filter(x => isNumber(x)).length) {
            newstate[name] = domain;
        } else {
            // no value!
            newstate[name] = undefined;
        }
        onChange(newstate);
    }, [value, onChange])

    const changeParamsValue = React.useCallback((params: number[]) => {
        const newstate = Object.assign({}, value);
        newstate.params = params;
        onChange(newstate);
    }, [value, onChange])





    const hideDomainFor: ChartFnShape[] = ["l", "lz", "circle"];

    let paramsComp = <></>;
    const numOfParams = AllFunctions[value.type].numOfParams;
    if (["l", "lz"].includes(value.type)) {
        // points insert!
        paramsComp = <PointsInput value={value.params} onChange={changeParamsValue}></PointsInput>
    } else if (numOfParams) {
        let allParams = ['a', 'b', 'c', 'd'];
        if (value.type === 'circle') {
            allParams = ['r', 'cx', 'cy'];
        }
        allParams.splice(numOfParams)
        paramsComp = <MultyInput name="params" label={allParams} value={value.params || getFnDefaultParams(value.type)} onChange={changeMultyValue}></MultyInput>
    }

    return <Box >
        <Box sx={{ display: 'flex' }}>
            <FormControl sx={{ width: '200px', m: '5px' }}>
                <InputLabel>Shape Type</InputLabel>
                <Select
                    value={value.type}
                    name={'type'}
                    label="Shape Type"
                    onChange={handleChangeEvent}
                >
                    {allShapes.map(x => (<MenuItem key={x} value={x}><ChartShapeLabel type={x}></ChartShapeLabel></MenuItem>))}
                </Select>
            </FormControl>

            <FormControl sx={{ width: '200px', m: '5px' }}>
                <InputLabel>Shape Type</InputLabel>
                <Select
                    value={value.strokeType || allStrokeTypes[0]}
                    name={'strokeType'}
                    label="Stroke Type"
                    onChange={handleChangeEvent}
                >
                    {allStrokeTypes.map(x => (<MenuItem key={x} value={x}>{x}</MenuItem>))}
                </Select>
            </FormControl>
            {!hideDomainFor.includes(value.type) && <MultyInput name="domain" label={['x-min', 'x-max']} value={value?.domain || [NaN, NaN]} onChange={changeMultyValue} validator={isValidDomainWithNull}></MultyInput>}
            <TextField
                name="color"
                type="color"
                InputProps={{
                    inputProps: {
                        max: 100, min: -100
                    }
                }}
                sx={{ width: '100px', m: '5px' }}
                label={"Color"} value={value.color || ''} onChange={handleChangeEvent} variant="outlined" />
        </Box>
        <Box>
            {paramsComp}
        </Box>

    </Box>
});

