import React from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbsData, Util } from '../../state/utility';
import { Breadcrumbs, Chip, Typography } from '@mui/material';
// import { MathMLTitle } from '../math-ml-content/math-ml-content';
import { appLinkStyles } from '../helpers';

interface MyBreadCrumbsProps {
    data: BreadcrumbsData[];
    link?: true,
    newItem?: string
}
export const MyBreadCrumbs = (props: MyBreadCrumbsProps) => {
    const { data, link } = props;
    //
    const chips = React.useMemo(() => getChips(data, link), [data, link]);
    return <Breadcrumbs aria-label="breadcrumbs">
        {chips}
    </Breadcrumbs>

}

function getChips(data: BreadcrumbsData[], link?: true) {
    if (link) {
        return data.map(bc => (<Chip key={bc.link} sx={{ mb: 1, mt: 1, ...appLinkStyles }} label={<BCLabel b={bc} />} component={Link} to={bc.link} clickable={true} />));
    } else {
        return data.map((x, i) => (<Chip key={i} sx={{ mb: 1 }} label={<BCLabel b={x} />} />));
    }
}


const BCLabel = React.memo(function ({ b }: { b: BreadcrumbsData }) {

    // return <MathMLTitle content={content} />
    const prefix = Util.getPrefixDisplay(b.topic, b.item);
    return <Typography sx={{ maxWidth: { xs: '80px', md: '250px' } }} noWrap>{prefix}{b.title}</Typography>
});