import * as React from 'react';
import Box from '@mui/material/Box';
import { svgLogo } from '../../imgs/svgs';

interface AppLogoProps {
    height?: string;
}
export default function AppLogo(props: AppLogoProps) {

    const { height } = props;
    return <Box sx={{
        // height: '64px',
        display:'inline-block'
    }}>
        <div style={{
            padding: '5px',
            height: height || '50px',
            margin: '5px',
            display: 'inline-block',
            // borderRadius: '10px',
            // border: '2px solid #a79432'
        }} dangerouslySetInnerHTML={{ __html: svgLogo }}>
        </div>
    </Box>

}