import { styled, Typography } from '@mui/material';
import React from 'react';

export interface MyTextProps {
    t?: 't1' | 't2' | 't3' | 't4' | 'p';
}
export const MyText = (props: React.PropsWithChildren<MyTextProps>) => {

    const { children, t } = props;
    if (t === 't1') {
        return <Typography
            component="h1"
            variant="h2"
            align="left"
            color="text.primary"
            gutterBottom
        > {children} </Typography>
    } else if (t === 't2') {
        return <Typography
            component="h4"
            variant="h4"
            align="left"
            color="text.primary"
            gutterBottom
        > {children} </Typography>
    } else if (t === 't3') {
        return <Typography variant="h5" align="left" color="text.secondary" paragraph >
            {children} </Typography>
    } else if (t === 't4') {
        return <Typography variant="h6" align="left" color="text.secondary" paragraph >
            {children} </Typography>
    } else {
        return <Typography variant="inherit" align="left" color="text.secondary" paragraph >
            {children} </Typography>
    }
}


export const MyOl = styled('ol')(({ theme }) => ({
    color: theme.palette.text.secondary
}));


export const MyUl = styled('ul')(({ theme }) => ({
    color: theme.palette.text.secondary
}));
