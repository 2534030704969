import {
    Editor,
    Element as SlateElement,
    Transforms,
} from "slate"
import { ImageElement } from "../slate.inteface"


export function validURL(str: string) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}



export const isImgActive = (editor: Editor) => {
    const [link] = Array.from(Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'image',
    }))
    return !!link
}

export const insertImage = (editor: Editor, url: string) => {
    const image: ImageElement = { type: 'image', url, children: [{ text: '' }] }
    Transforms.insertNodes(editor, image)
}

export function shrinkImage(img: HTMLImageElement, imageFile: File) {
    var MAX_WIDTH = 300;
    var MAX_HEIGHT = 300;

    var width = img.width;
    var height = img.height;

    // Change the resizing logic
    if (width > height) {
        if (width > MAX_WIDTH) {
            height = height * (MAX_WIDTH / width);
            width = MAX_WIDTH;
        }
    } else {
        if (height > MAX_HEIGHT) {
            width = width * (MAX_HEIGHT / height);
            height = MAX_HEIGHT;
        }
    }
    // Dynamically create a canvas element
    var canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    // var canvas = document.getElementById("canvas");
    var ctx = canvas.getContext("2d");
    if (ctx) {
        // Actual resizing
        ctx.drawImage(img, 0, 0, width, height);

        // Show resized image in preview element
        var dataurl = canvas.toDataURL(imageFile.type);
        return dataurl;
    }
    return null;

}
