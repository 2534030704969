
const COPY_KEY = 'copy/paste/data';
export interface CopyPasteData {
    src_topic_id: String;
    src_item_id?: string;
    name: string;
    time: number;
}
export const copyPaste = (name: string, src_topic_id: string, src_item_id: string | undefined) => {

    const tmpData = getCopyPasted();
    const contains = tmpData.find(x => x.src_item_id === src_item_id && x.src_topic_id === src_topic_id);
    if (!contains) {
        tmpData.push({
            src_topic_id,
            src_item_id,
            name,
            time: Date.now()
        })
        localStorage.setItem(COPY_KEY, JSON.stringify(tmpData));
    }

}
export const removeCopyPaste = (val: CopyPasteData) => {
    const tmpData = getCopyPasted();
    const newData = tmpData.filter(x => !(x.src_item_id === val.src_item_id && x.src_topic_id === val.src_topic_id));
    localStorage.setItem(COPY_KEY, JSON.stringify(newData));
}
export const getCopyPasted = (): CopyPasteData[] => {
    const NUM_SEC = 60 * 60;//60 mins
    return JSON.parse(localStorage.getItem(COPY_KEY) || '[]')
        .filter((x: CopyPasteData) => (Date.now() - x.time) / 1000 < NUM_SEC);
}

