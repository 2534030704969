import React from 'react';
import { IconButton } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface MyExpandBtnProps {
    onClick?: (e: React.MouseEvent) => void;
    expanded?: boolean;
}


const btnStyles: React.CSSProperties = {
    border: '1px solid #b3b3b3',
    borderRadius: 2
}
export const MyExpandBtn = (props: MyExpandBtnProps) => {

    const { onClick, expanded } = props;
    return <IconButton onClick={onClick} sx={{ ...btnStyles }} size="small" aria-label="expand">
        {expanded ? <ExpandLess /> : <ExpandMore />}
    </IconButton>
}