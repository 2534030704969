


import React from 'react';
import {
    Editor,
    Element as SlateElement,
    Node,
    Transforms
} from 'slate';
import { useSlate } from 'slate-react';

// material
import { ToggleButton, ToggleButtonGroup, styled, Tooltip } from '@mui/material';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CodeIcon from '@mui/icons-material/Code';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';
import { CustomEditor, CustomElement, MyEditor, TextStyles } from './slate.inteface';
import { svgFn } from '../imgs/svgs';
import { LinkButton, LinkOffButton } from './link/LinkToolbar';
import { InsertImageButton } from './img/ImgToolbar';
import { stringifyAll } from './core/parse/stringify';
import { copyTextToClipboard } from './core/helpers';


export type ToolbarItems = {
    inline: ToolbarInlineItems[],
    block: ToolbarBlockItems[],
    // common: ToolbarCommpnItems[],
}
export type ToolbarInlineItems = TextStyles | 'link' | 'link-off';
export type ToolbarBlockItems = | 'pre' | 'block-quote' | 'list-item' | 'numbered-list' | 'numbered-list' | 'left' | 'right' | 'center' | 'justify';
// export type ToolbarCommpnItems = 'copy' | 'undo' | 'redo';


const defaultItems: ToolbarItems = {
    inline: ['bold', 'italic', 'underline', 'code', 'link', 'link-off'],
    block: ['list-item', 'numbered-list', 'block-quote', 'pre'],
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']


const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,
        '&.Mui-disabled': {
            border: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));


export const toggleMark = (editor: CustomEditor, format: TextStyles) => {
    const isActive = isMarkActive(editor, format)
    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

const isMarkActive = (editor: CustomEditor, format: TextStyles) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
}



const isBlockActive = (editor: CustomEditor, format: ToolbarBlockItems | TextStyles, blockType: 'align' | 'type' = 'type') => {
    const { selection } = editor
    if (!selection) return false

    if (format === 'list-item') {
        //check  if numbered!!
        const [match] = Array.from(
            Editor.nodes(editor, {
                at: Editor.unhangRange(editor, selection),
                match: n =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    n[blockType] === 'numbered-list',
            })
        )
        if (!!match) {
            return false // its numbered list!!
        }
    }
    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n[blockType] === format,
        })
    )

    return !!match
}

interface ToolbarProps {
    items?: ToolbarItems;
}
export const SlateMToolbar = (props: ToolbarProps) => {

    let { items } = props;

    items = items || defaultItems;
    const inlineItems = items.inline;
    const blockItems = items.block;
    return (<>
        <StyledToggleButtonGroup
            aria-label="Text formatting"
            sx={{
                backgroundColor: (theme) => (theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900]
                ),
                marginRight: '20px',
            }}

        >
            {inlineItems.map(x => (<MarkButton key={x} format={x} />))}
            <CopyButton />
        </StyledToggleButtonGroup>

        <StyledToggleButtonGroup
            aria-label="Text formatting"
            sx={{
                backgroundColor: (theme) => (theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900]
                ),
                marginRight: '20px',
            }}
        >
            <MathButton />
            <ChartButton />
            <InsertImageButton />
            {blockItems.map(x => (<BlockButton key={x} format={x} />))}


        </StyledToggleButtonGroup>
    </>
    )
}


const MarkButton = ({ format }: { format: ToolbarInlineItems }) => {
    const editor = useSlate() as CustomEditor;

    let icon = <></>
    switch (format) {
        case 'bold':
            icon = <FormatBoldIcon />;
            break;
        case 'italic':
            icon = <FormatItalicIcon />;
            break;
        case 'underline':
            icon = <FormatUnderlinedIcon />;
            break;
        case 'link':
            return <LinkButton></LinkButton>
        case 'link-off':
            return <LinkOffButton></LinkOffButton>
        default:
            icon = <CodeIcon />;
    }
    return (
        <Tooltip title={`${format}`}>
            <ToggleButton
                value={format}
                aria-label={format}
                selected={isMarkActive(editor, format)}
                disabled={isBlockActive(
                    editor,
                    'pre',
                    'type'
                )}
                onMouseDown={event => {
                    event.preventDefault()
                    toggleMark(editor, format)
                }}
            >
                {icon}
            </ToggleButton>
        </Tooltip >
    )
}

const BlockButton = ({ format }: { format: ToolbarBlockItems }) => {
    const editor = useSlate() as CustomEditor;
    let icon = <></>
    switch (format) {
        case 'block-quote':
            icon = <FormatQuoteIcon />
            break;
        case 'list-item':
            icon = <FormatListBulletedIcon />;
            break;
        case 'numbered-list':
            icon = <FormatListNumberedIcon />;
            break;
        case 'left':
            icon = <FormatAlignLeftIcon />;
            break;
        case 'center':
            icon = <FormatAlignCenterIcon />;
            break;
        case 'right':
            icon = <FormatAlignRightIcon />;
            break;
        case 'justify':
            icon = <FormatAlignJustifyIcon />;
            break;
        case 'pre':
            icon = <DeveloperModeIcon />;
            break;

    }
    let tooltip = `${format}`;
    if (format === 'pre') {
        tooltip = "Block of Code"
    }
    return (
        <Tooltip title={tooltip}>
            <ToggleButton
                value={format}
                aria-label={format}
                selected={isBlockActive(
                    editor,
                    format,
                    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
                )}
                onMouseDown={event => {
                    event.preventDefault()
                    toggleBlock(editor, format)
                }}
            >
                {icon}
            </ToggleButton>
        </Tooltip>
    )
}


const MathButton = (props: any) => {
    const editor = useSlate() as MyEditor;

    return (<>
        <Tooltip title="Insert Math">
            <ToggleButton
                value={'mmathinlineath'}
                aria-label={'mathinline'}
                selected={isMath(editor)}
                onMouseDown={event => {
                    event.preventDefault()
                    //
                    const fragment = editor.getFragment();
                    const selectionText = fragment.map(n => Node.string(n)).join('\n');
                    let selectionMath = '';//
                    if (editor.selection) {
                        const math_nodes = Array.from(Editor.nodes(editor, {
                            at: Editor.unhangRange(editor, editor.selection),
                            match: n =>
                                !Editor.isEditor(n) &&
                                SlateElement.isElement(n) &&
                                n['type'] === 'mathinline',
                        }));
                        selectionMath = math_nodes.map((n: any) => n[0]).map(x => x.tex || '').join('\n');
                    }

                    const newdata: CustomElement = { type: 'mathinline', tex: selectionMath || selectionText || '', children: [{ text: '' }] };

                    editor.editMath({
                        op: 'insert',
                        element: newdata
                    });
                }}
            >
                <div style={{ height: '25px', width: '25px', color: '#000000' }} dangerouslySetInnerHTML={{ __html: svgFn }}>
                </div>
            </ToggleButton>
        </Tooltip>
    </>

    )
}


const ChartButton = (props: any) => {
    const editor = useSlate() as MyEditor;

    return (<>
        <Tooltip title="Insert Chart">
            <ToggleButton
                value={'chart'}
                aria-label={'chart'}
                onMouseDown={event => {
                    event.preventDefault()
                    //
                    const newdata: CustomElement = { type: 'chart', data: '{{ func | -10;10;-10;10 }}', children: [{ text: '' }] };
                    editor.editMath({
                        op: 'insert',
                        element: newdata
                    });
                }}
            >
                <PhotoAlbumIcon />
            </ToggleButton>
        </Tooltip>
    </>

    )
}



const CopyButton = (props: any) => {
    const editor = useSlate() as MyEditor;

    return (<>
        <Tooltip sx={{ ml: 2 }} title="Copy As Text To Clipboard">
            <ToggleButton
                value={'chart'}
                aria-label={'chart'}
                onMouseDown={event => {
                    event.preventDefault()
                    copyTextToClipboard(stringifyAll(editor.getFragment()));
                }}
            >
                <ContentCopyIcon />
            </ToggleButton>
        </Tooltip>
    </>
    )
}


const toggleBlock = (editor: CustomEditor, format: any) => {
    const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            LIST_TYPES.includes(n.type) &&
            !TEXT_ALIGN_TYPES.includes(format),
        split: true,
    })
    let newProperties: Partial<SlateElement>
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            align: isActive ? undefined : format,
        }
    } else {
        newProperties = {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        }
    }
    Transforms.setNodes<SlateElement>(editor, newProperties)

    if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}


const isMath = (editor: CustomEditor) => {
    const { selection } = editor
    if (!selection) return false
    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n['type'] === 'mathinline',
        })
    )

    return !!match
}
