import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
// import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { useLoginMutation } from '../../state/services/mathapi';
import { Alert, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoginRequest } from '../../state/services/type';
import { useDispatch } from 'react-redux';
import { login as loginState } from '../../state/features/auth/authSlice';

export default function PwdLogin() {

  const [login, { isLoading, isError }] = useLoginMutation()
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    const dataLogin: LoginRequest = {
      email: String(data.get('email')),
      password: data.get('password')?.toString() || ''
    };
    const response = await login(dataLogin).unwrap();
    dispatch(loginState(response.data));
    //
    navigate('/my')
  };

  const SignInBtn = isLoading ? <Box sx={{ display: 'flex', m: 2, justifyContent: 'center' }}> <CircularProgress /></Box> : <Button
    type="submit"
    fullWidth
    variant="contained"
    sx={{ mt: 3, mb: 2 }}

  >
    Sign In
  </Button>;
  const errorShow = isError ? <Alert severity="error" elevation={6} variant="filled" >Username and password doesn't match!</Alert> : null;

  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in (Limited)
      </Typography>
      <Typography variant="caption" sx={{ mb: 4, color: "#8b053d" }}>*Limited number of accounts</Typography>

      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
        />
        {errorShow}
        {SignInBtn}

        {/* <Grid container>
          <Grid item xs>
            <Link href="#" variant="body2">
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid> */}
      </Box>
    </>
  );
}