import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import { Divider, Typography } from '@mui/material';
import { ContentItem, Topic } from '../../state/services/type';
import { useNavigate, useParams } from 'react-router-dom';
import { Util } from '../../state/utility';
import { ChangeOrderFn } from '../topic-item/OrderDialog';
import { BtnActionItem, ContentActions } from './ContentActions';
import { MathMLTitle } from '../math-ml-content/math-ml-content';
import { appLinkStyles } from '../helpers';
import { MyExpandBtn } from '../btns/ExpandBtn';


const ListTypo = styled(Typography)(({ theme }) => ({
    ...theme.typography.button,
    color: theme.palette.mode === 'light' ? theme.palette.primary.dark : theme.palette.primary.dark,
    // backgroundColor: theme.palette.background.paper,
    textTransform: 'none',
    padding: theme.spacing(1),
}));

interface ContentListItemProps {
    topic: Topic;
    item: ContentItem;
    level: number;
    parent?: ContentItem;
    orderEdit?: ChangeOrderFn;
}

const ContentListItem = React.memo((props: ContentListItemProps) => {

    const { item, parent, topic, level, orderEdit } = props;
    let { itemId } = useParams();

    // check if viewed item is in current tree 
    const isInTree = React.useMemo(() => isParentOrItem(itemId, item.children, item), [itemId, item]);

    const [open, setOpen] = React.useState(false);
    const [btns, setBtns] = React.useState<BtnActionItem[]>([]);
    const navigate = useNavigate();

    const currentItemRef = React.useRef<HTMLDivElement>(null);

    const handleClick = React.useCallback(() => {
        if (!orderEdit) {
            navigate(`/topic/${topic.id}/${item.id}`)
        }
        setOpen(true)
    }, [item, navigate, orderEdit, setOpen, topic]);

    const handleClickExpand = React.useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        if (item.children.length)
            setOpen(!open);
    }, [open, setOpen, item]);

    const children = item.children;
    const hasChildren = !!item.children.length;

    const prefix = Util.getPrefixDisplay(topic, item);

    const title = `${prefix} ${item.title}`;


    // expand and scroll to item!
    React.useEffect(() => {
        if (isInTree.isChild || isInTree.isCurrent)
            setOpen(isInTree.isChild || isInTree.isCurrent);
        //
        if (currentItemRef.current && isInTree.isCurrent) {
            setTimeout(() => {
                currentItemRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
            }, 100)

            //console.log(`Scrolling into view ${item.id}`)
        }
    }, [currentItemRef, isInTree, setOpen])

    React.useEffect(() => {
        if (orderEdit) {
            let isLast = false;
            const isFirst = item.order < 1.1;
            if (parent) {
                isLast = parent.children[parent.children.length - 1].id === item.id;
            } else {

                isLast = topic.children[topic.children.length - 1].id === item.id;

            }
            const allParentChildren = parent?.children || topic.children;
            const targetItemIndex = allParentChildren.indexOf(item) + 1;
            const targetItem = allParentChildren[targetItemIndex];

            const btnsTmp: BtnActionItem[] = [
                {
                    disabled: !parent,
                    type: 'up-level',
                    handler: () => {
                        if (orderEdit)
                            orderEdit(item, parent, 'up-level')
                    }
                },
                {
                    disabled: isFirst,
                    type: 'up',
                    handler: () => {
                        if (orderEdit)
                            orderEdit(item, parent, 'up');
                    }
                }, {
                    disabled: isLast,
                    type: 'down',
                    handler: () => {
                        if (orderEdit)
                            orderEdit(item, parent, 'down');
                    }
                },
                {
                    disabled: !targetItem,
                    type: 'down-level',
                    handler: () => {
                        if (orderEdit)
                            orderEdit(item, parent, 'down-level');
                    }
                }];
            setBtns(btnsTmp)
        }
    }, [setBtns, orderEdit, parent, topic, item, item.order]);

    return (<>
        <ListItemButton ref={currentItemRef} selected={item.id === itemId} onClick={handleClick} sx={{ pl: (level + 1) * 2 }}>
            <ListItemText>
                <ListTypo sx={{
                    fontWeight: item.id === itemId ? 'bold' : null,
                    ...appLinkStyles
                }} >
                    <MathMLTitle content={title} />
                </ListTypo>
            </ListItemText>

            {hasChildren && <MyExpandBtn onClick={handleClickExpand} expanded={open} />}
            <ContentActions btns={btns} ></ContentActions>
        </ListItemButton>
        <Divider />
        <Collapse in={open && !!children?.length} timeout="auto">
            <List component="div">
                {children?.map(x => (<ContentListItem orderEdit={orderEdit} parent={item} key={x.id} item={x} topic={topic} level={level + 1}></ContentListItem>))}
            </List>
        </Collapse>
    </>
    );
})


function isParentOrItem(itemId: string | undefined, children: ContentItem[], currentItem: ContentItem) {

    const retVal = {
        isCurrent: currentItem.id === itemId,
        isChild: isChild(itemId, children)
    }
    return retVal
}

function isChild(itemId: string | undefined, children: ContentItem[]): boolean {
    let retVal = false;
    if (itemId) {
        for (let index = 0; index < children.length; index++) {
            const element = children[index];
            retVal = element.id === itemId;
            if (retVal) {
                break;
            }
            retVal = isChild(itemId, element.children);
            if (retVal) {
                break;
            }
        }
    }

    return retVal;
}

export default ContentListItem