import { FnPoint, parseColor, SPLITTER_PARAMS } from "../chart.interface";


export const parsePoint = {
    stringifyPoint(p: FnPoint) {
        const color = p.color ? `${SPLITTER_PARAMS}${p.color}` : '';
        const position = p.position.join(SPLITTER_PARAMS)
        return ` point | ${position}${color} `;
    },

    parsePoint(partType: string, value: string): FnPoint | null {
        switch (partType) {
            case 'point':
                const parts = value.split(SPLITTER_PARAMS)
                const position = [parseFloat(parts[0]) || 0, parseFloat(parts[1]) || 0];
                const color = parseColor(parts[2]);
                return {
                    position,
                    color
                }
            default:
                return null;
        }
    }
}